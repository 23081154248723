import Box from "@mui/material/Box";

import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import ProfileForm from "../../components/profileForm";
import ProfileInfo from "../../components/profileInfo";

const DesktopView = ({
  state,
  handleSidebar,
  onLogout,
  container,
  navigate,
  connect,
  handleDialogClose,
  handleAgentConnect,
  handleAvatar,
  hanleData,
  height,
  hanleDataSound,
  loading,
  currentPreview,
}) => {
  return (
    <>
      <Box style={{ display: "flex", overflow: "hidden" }}>
        <Navbar
          isMobile={false}
          page={"profile"}
          currentChat={state.currentChat}
          isLoading={loading}
          isOpenSideMenu={state.isOpen}
          handleDrawerToggle={handleSidebar}
        />

        <Box
          component="nav"
          sx={{
            width: { md: state.isOpen ? 240 : 80 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folder"
        >
          <Sidebar
            typeConnection={""}
            drawerwidth={state.isOpen ? 240 : 80}
            handleDrawerToggle={handleSidebar}
            container={container}
            isOpenSideMenu={state.isOpen}
            onHandlePage={(pathname) => navigate(pathname)}
            onLogout={onLogout}
            page={"profile"}
            user={state.user}
            openDialogClose={handleDialogClose}
            groups={state.groups}
            abilities={state.abilities}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            currentChat={state.currentChat}
            queue={state.queue}
            chatList={state.chatList}
            avatar={state?.user?.avatar}
            isLoading={state.isLoading}
            height={height}
          />
        </Box>
        <Box
          style={{
            height: "calc(100vh)",
            width: `calc(100% - ${state.isOpen ? 240 : 80}px)`,
            backgroundColor: "#F4F4F4",
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
          }}
        >
          <ProfileInfo
            state={state}
            handleAvatar={handleAvatar}
            hanleDataSound={hanleDataSound}
            loading={loading}
            currentPreview={currentPreview}
          />
          <Box
            style={{
              width: "70%",
              marginTop: 80,
              backgroundColor: "#FFFFFF",
              padding: 43,
            }}
          >
            <ProfileForm state={state} hanleData={hanleData} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DesktopView;
