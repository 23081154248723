import { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DesktopView from "../views/history/desktop";
import MobileView from "../views/history/mobile";

import { AgentContext } from "../context";
import service from "../service";
import DialogClose from "../components/dialogClose";
import { getCanalIcon } from "../utils";

const History = ({ isMobile, width }) => {
  const {
    state,
    socket,
    handleSidebar,
    onLogout,
    handleDialogClose,
    connect,
    setSearch,
    setFilterHistory,
    setFilterChecked,
    setHistory,
    handleDialogDetails,
    handleDetails,
    handleDetailsScreen,
    setAgents,
  } = useContext(AgentContext);
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const navigate = useNavigate();
  ///States for control table
  const [page, setPage] = useState(0);
  const [isInit, setIsInit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [startDate, setStartDate] = useState([null, null]);
  const [endDate, setEndDate] = useState([null, null]);
  const [totalChats, setTotalChats] = useState(0);
  const [isLoadingPageTransition, setIsLoadingPageTransition] = useState(false);
  const [searchByUsername, setSearchByUsername] = useState(false);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const { chatList, currentChat } = state;
  const [loadingRows, setLoadingRows] = useState(false);

  const handleAgentConnect = (event) => {
    if (event) {
      socket?.connect();
    } else {
      socket?.disconnect();
    }
    localStorage.setItem("connect", event);
  };

  const handleToggle = async (value) => {
    setIsLoadingPageTransition(true);
    try {
      const currentIndex = state.filterChecked.indexOf(value);

      if (currentIndex === -1) {
        state.filterChecked.push(value);
      } else {
        state.filterChecked.splice(currentIndex, 1);
      }
      setFilterChecked(state.filterChecked);
      const historial = await service.getHistorial(
        state.user.company,
        rowsPerPage,
        page,
        state.filterChecked,
        startDate,
        endDate,
        state.search
      );
      setHistory(historial.chats);
      setPage(0);
      setTotalChats(historial.totalChats);
      setIsLoadingPageTransition(false);
    } catch (err) {
      setIsLoadingPageTransition(false);
      console.log("🚀 ~ file: history.js ~ line 37 ~ handleToggle ~ err", err);
    }
  };

  const handleChangePage = async (event, newPage) => {
    try {
      setIsLoadingPageTransition(true);
      setPage(newPage);

      const historial = await service.getHistorial(
        state.user.company,
        rowsPerPage,
        newPage + 1,
        state.filterChecked,
        startDate,
        endDate,
        state.search
      );
      setHistory(historial.chats);
      setTotalChats(historial.totalChats);
      setIsLoadingPageTransition(false);
    } catch (err) {
      setIsLoadingPageTransition(true);
      console.log(
        "🚀 ~ file: history.js ~ line 75 ~ handleChangePage ~ err",
        err
      );
      setIsLoadingPageTransition(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = async () => {
    try {
      // setLoading(false);
      setIsLoadingPageTransition(true);

      const historial = await service.getHistorial(
        state.user.company,
        rowsPerPage,
        page,
        state.filterChecked,
        startDate,
        endDate,
        state.search
      );
      setHistory(historial.chats);
      setTotalChats(historial.totalChats);
      setIsLoadingPageTransition(false);
    } catch (err) {
      setIsLoadingPageTransition(false);
      console.log("🚀 ~ file: history.js ~ line 98 ~ handleSearch ~ err", err);
    }
  };
  const handleRowsPerPage = async () => {
    try {
      setIsLoadingPageTransition(true);

      if (rowsPerPage === -1) {
        const historial = await service.getHistorial(
          state.user.company,
          rowsPerPage,
          page,
          state.filterChecked,
          startDate,
          endDate,
          state.search
        );
        setHistory(historial.chats);
        setTotalChats(historial.totalChats);
      } else {
        const historial = await service.getHistorial(
          state.user.company,
          rowsPerPage,
          page,
          state.filterChecked,
          startDate,
          endDate,
          state.search
        );
        setHistory(historial.chats);
        setTotalChats(historial.totalChats);
      }
      setIsLoadingPageTransition(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: history.js ~ line 129 ~ handleRowsPerpage ~ err",
        err
      );
    }
  };

  const handleFilterDateButton = async () => {
    try {
      const today = new Date();
      setIsLoadingPageTransition(true);
      if (state.filterHistory === "day") {
        const historial = await service.getHistorial(
          state.user.company,
          rowsPerPage,
          page,
          state.filterChecked,
          today,
          today,
          state.search
        );
        setHistory(historial.chats);
        setTotalChats(historial.totalChats);
        setStartDate(today);
        setEndDate(today);
      } else if (state.filterHistory === "week") {
        const firstDay = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        const lastDay = new Date(
          today.setDate(today.getDate() - today.getDay() + 6)
        );

        const historial = await service.getHistorial(
          state.user.company,
          rowsPerPage,
          page,
          state.filterChecked,
          firstDay,
          lastDay,
          state.search
        );
        setHistory(historial.chats);
        setTotalChats(historial.totalChats);
        setStartDate(firstDay);
        setEndDate(lastDay);
      } else if (state.filterHistory === "month") {
        var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const historial = await service.getHistorial(
          state.user.company,
          rowsPerPage,
          page,
          state.filterChecked,
          firstDay,
          lastDay,
          state.search
        );
        setHistory(historial.chats);
        setTotalChats(historial.totalChats);
        setStartDate(firstDay);
        setEndDate(lastDay);
      } else {
        const currentYear = new Date().getFullYear();
        const firstDay = new Date(currentYear, 0, 1);
        const lastDay = new Date(currentYear, 11, 31);
        const historial = await service.getHistorial(
          state.user.company,
          rowsPerPage,
          page,
          state.filterChecked,
          firstDay,
          lastDay,
          state.search
        );
        setHistory(historial.chats);
        setTotalChats(historial.totalChats);
        setStartDate(firstDay);
        setEndDate(lastDay);
      }

      setIsLoadingPageTransition(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: history.js ~ line 167 ~ handlestate.filterHistory ~ err",
        err
      );
    }
  };

  const getDetails = async (row) => {
    handleDialogDetails(true);
    await Promise.all([
      service.getChatDetails(state.user.company, row._id),
      service.getChatDetailsMessages(state.user.company, row._id),
      service.getFilesHistorial(state.user.company, row._id),
    ])
      .then(([details, messages, currentFiles]) => {
        messages.map(
          (message) =>
            (message.conversation = message.conversation.filter(
              (conversation) =>
                conversation.eventType === "msg" ||
                (conversation.eventType === "sysmsg" &&
                  conversation.action === "chat_transfer")
            ))
        );

        currentFiles.map(
          (message) =>
            (message.conversation = message.conversation.filter(
              (conversation) => conversation.eventType === "msg"
            ))
        );

        handleDetails({
          messages,
          files: currentFiles,
          details,
        });
      })
      .catch((err) => {
        console.log("🚀 ~ file: history.js ~ line 279 ~ getDetails ~ err", err);
        setLoading(false);
      });
  };

  const init = async () => {
    const agents =
      state && state.user ? await service.getAllAgents(state.user.company) : [];
    const agentFilterData = agents.map((agent) => ({
      id: agent._id,
      fullName: `${agent.firstname} ${agent.lastname}`,
      avatar: agent.avatar ? agent.avatar : getCanalIcon("web"),
    }));
    setAgents(agentFilterData);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  async function getHistorial() {
    // setIsLoadingPageTransition(true);
    setLoadingRows(true);

    const historial = await service.getHistorial(
      state.user.company,
      rowsPerPage,
      page,
      state.filterChecked,
      startDate,
      endDate
    );
    setHistory(historial.chats);
    setTotalChats(historial.totalChats);
    // setIsLoadingPageTransition(false);
    setLoadingRows(false);
  }
  useEffect(() => {
    getHistorial();

    return () => localStorage.removeItem("channels");
  }, []);
  useEffect(() => {
    if (state.search !== null) handleSearch();
  }, [searchByUsername]);

  useEffect(() => {
    if (isInit) {
      handleRowsPerPage();
    } else {
      setIsInit(true);
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (state.filterHistory !== "") handleFilterDateButton();
  }, [state.filterHistory]);

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!connect) socket?.connect();
  }, []);

  useEffect(() => {
    const find = chatList?.find((item) => item.count > 0);

    if (find) service.handleNotifyFavicon();
    else service.handleFavicon();
  }, [chatList, currentChat]);

  return (
    <>
      {state.user ? (
        isMobile ? (
          <MobileView
            state={state}
            handleSidebar={handleSidebar}
            onLogout={onLogout}
            container={container}
            navigate={navigate}
            handleDialogClose={handleDialogClose}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            setSearch={setSearch}
            setFilterHistory={setFilterHistory}
            handleToggle={handleToggle}
            isLoadingPageTransition={isLoadingPageTransition}
            setSearchByUsername={setSearchByUsername}
            searchByUsername={searchByUsername}
            totalChats={totalChats}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            handleDialogDetails={handleDialogDetails}
            getDetails={getDetails}
            handleDetails={handleDetails}
            handleDetailsScreen={handleDetailsScreen}
            height={height}
            loading={loading}
            width={width}
            loadingRows={loadingRows}
          />
        ) : (
          <DesktopView
            state={state}
            handleSidebar={handleSidebar}
            onLogout={onLogout}
            container={container}
            navigate={navigate}
            handleDialogClose={handleDialogClose}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            setSearch={setSearch}
            setFilterHistory={setFilterHistory}
            handleToggle={handleToggle}
            isLoadingPageTransition={isLoadingPageTransition}
            setSearchByUsername={setSearchByUsername}
            searchByUsername={searchByUsername}
            totalChats={totalChats}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            handleDialogDetails={handleDialogDetails}
            getDetails={getDetails}
            handleDetails={handleDetails}
            handleDetailsScreen={handleDetailsScreen}
            height={height}
            loading={loading}
            loadingRows={loadingRows}
          />
        )
      ) : (
        <Navigate to="/" />
      )}
      <DialogClose
        open={state.isOpenDialog}
        setOpen={handleDialogClose}
        onClose={() => {
          handleDialogClose(false);
        }}
        onAccept={onLogout}
      />
    </>
  );
};

export default History;
