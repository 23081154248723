import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { TicketContext } from "../../context";
import "./custom-select.scss";

export const CustomSelect = ({
  options = [],
  changeValue = () => {},
  style = {},
  customState,
}) => {
  const [state, setState] = useState({
    open: false,
    icon: " ",
    text: "Seleccione",
    value: "",
  });

  function clicked() {
    setState({ ...state, open: !state.open });
  }

  const selectOption = (event, option) => {
    event.stopPropagation();
    setState({
      open: !state.open,
      icon: option.icon,
      text: option.text,
      value: option.value,
    });
    changeValue(option.value);
  };

  useEffect(() => {
    if (customState) {
      setState({
        ...state,
        icon: customState.icon,
        text: customState.text,
        value: customState.value,
      });
    }
  }, [customState]);

  return (
    <>
      <div
        style={style}
        className={`custom-select ${state.open && "custom-select--open"} `}
        onClick={clicked}
      >
        <div className="custom-select-option__selected">
          <span className="custom-select-option__icon">{state.icon}</span>
          <span className="custom-select-option__text">{state.text}</span>
        </div>
        <div
          className={`custom-select-option__wrapper 
              ${state.open && "custom-select-option__wrapper--active"} `}
        >
          {options.map((option, index) => (
            <div
              className="custom-select-option"
              key={index}
              onClick={($event) => selectOption($event, option)}
            >
              <span className="custom-select-option__icon">{option.icon}</span>
              <span className="custom-select-option__text">{option.text}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
