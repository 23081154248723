import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export const RoutesWithNotFound = ({ children }) => {
  return (
    <Routes>
      {children}
      <Route path="*" element={<Navigate replace to={`/`} />}></Route>
    </Routes>
  );
};
