import { useContext } from "react";
import { TicketContext } from "../../context";
import TicketActions from "../ticket-actions/ticket-actions";
import { TicketContactInfo } from "../ticket-contact-info/ticket-contact-info";
import "./ticket-contact.scss";

export const TicketContact = () => {
  const { ticket } = useContext(TicketContext);
  return (
    <>
      <div className="ticket-contact">
        <TicketContactInfo />
        <TicketActions />
      </div>
    </>
  );
};
