import "./contactForm.scss";
import { useContext, useEffect, useState } from "react";
import { vlFormContext, VlFormProvider } from "./contextForm";
import { contactSrc } from "../../services/contact.service";

const phoneCodes = ["58", "57", "56", "1", "52", "55"];
const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ContactFormNew = ({
  generalState,
  getCurrentChat,
  getMoreMessages,
}) => {
  return (
    <VlFormProvider
      generalState={generalState}
      getCurrentChat={getCurrentChat}
      getMoreMessages={getMoreMessages}
    >
      <VlDialog></VlDialog>
      <ContactViewForm />
    </VlFormProvider>
  );
};

const ContactViewForm = () => {
  const { contact } = useContext(vlFormContext);

  return (
    <div className="contact-form-new">
      <div className="contact-form-new__header">
        <h2 className="contact-form-new__header__label">
          Información del contacto
        </h2>
      </div>
      <div className="contact-form-new__body">
        {Object.keys(contact).length ? <FormCurrent /> : <Identifier />}
      </div>
    </div>
  );
};

const VlDialog = () => {
  const { dialogConfig, setDialogConfig } = useContext(vlFormContext);

  return (
    <div className={`vl-overlay ${dialogConfig?.isActive && "active"}`}>
      <dialog
        className="vl-dialog"
        onClick={($event) => {
          $event.stopPropagation();
        }}
        open={true}
      >
        <header className="vl-dialog__header">
          <h2 className="vl-dialog__header__title">{dialogConfig.title}</h2>
          <button
            className="vl-dialog__header__btnClose"
            onClick={() => {
              dialogConfig.onClose(false);
              setDialogConfig({ ...dialogConfig, isActive: false });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </header>
        <section className="vl-dialog__body">
          {dialogConfig?.informations.map((info, index) => {
            return (
              <p
                key={index}
                className={`vl-dialog__body__text ${info.important && "w500"}`}
              >
                {info.msg}
              </p>
            );
          })}
        </section>
        <footer className="vl-dialog__actions">
          <button
            className="vl-dialog__actions__btnCancel"
            onClick={() => {
              dialogConfig.onClose(false);
              setDialogConfig({ ...dialogConfig, isActive: false });
            }}
          >
            Cancelar
          </button>
          <button
            className="vl-dialog__actions__btnSuccess"
            onClick={() => {
              dialogConfig.onClose(true);
              setDialogConfig({ ...dialogConfig, isActive: false });
            }}
          >
            Confirmar
          </button>
        </footer>
      </dialog>
    </div>
  );
};

const Identifier = () => {
  const { isVerify } = useContext(vlFormContext);
  return isVerify ? <BoxInfo /> : <IdentifierForm />;
};

const IdentifierForm = () => {
  const {
    setIsVerify,
    formContact,
    showLoading,
    hideLoading,
    setRefContact,
    setExist,
    setOldDataForm,
  } = useContext(vlFormContext);
  const email = formContact.watch("email");
  const number = formContact.watch("number");
  const code = formContact.watch("code");

  const sendForm = async () => {
    try {
      showLoading();
      const requestCheckExist = await contactSrc.checkExist({
        email,
        number,
        code,
      });

      if (requestCheckExist?.data?.contact) {
        setRefContact(requestCheckExist?.data?.contact);
        setOldDataForm(formContact.getValues());
        setExist(true);
      } else {
        setExist(false);
      }

      setIsVerify(true);
    } catch (error) {
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="IdentifierBox">
      <p className="IdentifierBox__msj">
        Este contacto no se logro identificar, por favor, solicita el correo o
        número de teléfono con el código de país para intentar identificar el
        contacto o registrarlo manualmente.
      </p>
      <form className="IdentifierBox__form">
        <ul className="IdentifierBox__form__list">
          <li>
            <FormGroup
              label="Correo"
              keyForm="email"
              config={{
                pattern: {
                  value: regexEmail,
                  message: "Formato incorrecto",
                },
              }}
            />
          </li>
          <li>
            <FormGroup
              label="Teléfono"
              type="phone"
              keyForm={"number"}
              keyFormAlt={"code"}
              config={{
                maxLength: {
                  value: 12,
                  message: "La longitud máxima es de 12 caracteres",
                },
                minLength: {
                  value: 8,
                  message: "La longitud mínima es de 8 caracteres",
                },
              }}
            />
          </li>
        </ul>

        <div className="IdentifierBox__form__actions">
          <button
            className="IdentifierBox__form__actions__btn"
            type="button"
            onClick={sendForm}
            disabled={
              !formContact.formState.isValid ||
              (number.length > 0 && number.length < 8 && number.length > 12) ||
              (email.length > 0 && !regexEmail.test(email)) ||
              (number.length === 0 && email.length === 0)
            }
          >
            Verificar
          </button>
        </div>
      </form>
    </div>
  );
};

const BoxInfo = () => {
  const {
    exist,
    refContact,
    setRefContact,
    setContact,
    formContact,
    setIsVerify,
    showLoading,
    hideLoading,
    currentChatRef,
    updateChatList,
    updateAllReferences,
    setExist,
  } = useContext(vlFormContext);
  const { handleSubmit } = formContact;
  let msg = "";

  if (exist) {
    msg = `Existe un contacto que coincide con los datos proporcionados. Por favor, verifica que este es el contacto correcto. Si no es así, haz clic en el botón "Volver" e ingresa otros datos.`;
  } else {
    msg =
      "Es la primera vez que un contacto con los datos indicados Interactúa  con la compañía, por favor, indica su nombre y apellido para completar el registro de este contacto.";
  }

  const sendForm = async () => {
    try {
      showLoading();
      const payload = {
        chatId: currentChatRef?._id,
      };

      if (exist) {
        const { _id } = refContact;
        await contactSrc.joinThisChatToContact(_id, currentChatRef?._id);
        await updateAllReferences();
        setContact(refContact);
      } else {
        const { email, number, code, firstName, lastName } =
          formContact.getValues();

        if (email) payload.email = email.trim();
        if (number) {
          payload.phone = [
            {
              number: number.trim(),
              code: code,
              principal: true,
            },
          ];
        }
        if (firstName) payload.firstName = firstName.trim();
        if (lastName) payload.lastName = lastName.trim();
        const requestAddContact = await contactSrc.addContact(payload);
        await updateAllReferences();
        setContact(requestAddContact.data.contact);
        console.log({ requestAddContact });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      hideLoading();
    }
  };
  const backToVerify = () => {
    setRefContact({});
    setIsVerify(false);
  };

  return (
    <div className="BoxInfo">
      <p className="BoxInfo__msj">{msg}</p>
      <form
        className="BoxInfo__form"
        onSubmit={handleSubmit(() => {
          sendForm();
        })}
      >
        <ul className="BoxInfo__form__list">
          <li>
            <FormGroup
              label="Nombre"
              keyForm="firstName"
              config={{
                required: {
                  value: true,
                  message: "Este campo es obligatorio",
                },
                minLength: {
                  value: 2,
                  message: "La longitud mínima es de 2 caracteres",
                },
                maxLength: {
                  value: 80,
                  message: "La longitud máxima es de 80 caracteres",
                },
                readonly: exist,
              }}
            />
          </li>
          <li>
            <FormGroup
              label="Apellido"
              keyForm="lastName"
              config={{
                minLength: {
                  value: 2,
                  message: "La longitud mínima es de 2 caracteres",
                },
                maxLength: {
                  value: 80,
                  message: "La longitud máxima es de 80 caracteres",
                },
                readonly: exist,
              }}
            />
          </li>

          {exist && (
            <>
              <li>
                <FormGroup
                  label="Correo"
                  keyForm="email"
                  config={{
                    pattern: {
                      value: regexEmail,
                      message: "Formato incorrecto",
                    },
                    readonly: exist,
                  }}
                />
              </li>
              <li>
                <FormGroup
                  label="Teléfono"
                  type="phone"
                  keyForm={"number"}
                  keyFormAlt={"code"}
                  config={{
                    maxLength: {
                      value: 12,
                      message: "La longitud máxima es de 12 caracteres",
                    },
                    minLength: {
                      value: 8,
                      message: "La longitud mínima es de 8 caracteres",
                    },
                    readonly: exist,
                  }}
                  configAlt={{
                    readonly: exist,
                  }}
                />
              </li>
            </>
          )}
        </ul>
        <div className="BoxInfo__form__actions">
          <button
            className="BoxInfo__form__actions__btn"
            type="submit"
            disabled={!formContact.formState.isValid}
          >
            Continuar
          </button>
          <button
            className="BoxInfo__form__actions__btn alt"
            onClick={backToVerify}
          >
            Volver
          </button>
        </div>
      </form>
    </div>
  );
};
const FormCurrent = () => {
  const {
    otherChats,
    contact,
    currentChatRef,
    formContact,
    searchContactUpdate,
    showModalCloseOthersChats,
  } = useContext(vlFormContext);
  const { handleSubmit } = formContact;

  const firstName = formContact.watch("firstName");
  const lastName = formContact.watch("lastName");
  const email = formContact.watch("email");
  const number = formContact.watch("number");
  const code = formContact.watch("code");

  const whatsapp = formContact.watch("whatsapp");
  const facebook = formContact.watch("facebook");
  const instagram = formContact.watch("instagram");

  const currentFirstName = contact?.firstName ?? "";
  const currentLastName = contact?.lastName ?? "";
  const currentEmail = contact?.email ?? "";
  const currentNumber = contact?.phone?.[0]?.number ?? "";
  const currentCode = contact?.phone?.[0]?.code ?? "58";

  return (
    <div className="formCurrent">
      {otherChats.length > 0 && (
        <div className="AlertAtention">
          <div className="AlertAtention__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
          </div>
          <div className="AlertAtention__info">
            <p className="AlertAtention__info__text">
              Este contacto posee más de una atención en curso.
            </p>
            <button
              className="AlertAtention__info__btnAction"
              onClick={() => showModalCloseOthersChats()}
            >
              Combinar
            </button>
          </div>
        </div>
      )}
      <form
        className="formCurrent__form"
        onSubmit={handleSubmit(() => {
          if (
            !formContact.formState.isValid ||
            (firstName === currentFirstName &&
              lastName === currentLastName &&
              email === currentEmail &&
              number === currentNumber &&
              code === currentCode)
          )
            return;
          searchContactUpdate();
        })}
      >
        <ul className="formCurrent__form__list">
          <li>
            <FormGroup
              label="Nombre"
              keyForm="firstName"
              config={{
                required: {
                  value: true,
                  message: "Este campo es obligatorio",
                },
                minLength: {
                  value: 2,
                  message: "La longitud mínima es de 2 caracteres",
                },
                maxLength: {
                  value: 80,
                  message: "La longitud máxima es de 80 caracteres",
                },
              }}
            />
          </li>
          <li>
            <FormGroup
              label="Apellido"
              keyForm="lastName"
              config={{
                minLength: {
                  value: 2,
                  message: "La longitud mínima es de 2 caracteres",
                },
                maxLength: {
                  value: 80,
                  message: "La longitud máxima es de 80 caracteres",
                },
              }}
            />
          </li>
          <li>
            <FormGroup
              label="Correo"
              keyForm="email"
              config={{
                pattern: {
                  value: regexEmail,
                  message: "Formato incorrecto",
                },
              }}
            />
          </li>
          <li>
            <FormGroup
              label="Teléfono"
              type="phone"
              keyForm={"number"}
              keyFormAlt={"code"}
              config={{
                maxLength: {
                  value: 12,
                  message: "La longitud máxima es de 12 caracteres",
                },
                minLength: {
                  value: 8,
                  message: "La longitud mínima es de 8 caracteres",
                },
                readonly: currentChatRef.channel === "whatsapp",
              }}
              configAlt={{
                readonly: currentChatRef.channel === "whatsapp",
              }}
            />
          </li>
          {whatsapp && (
            <li>
              <FormGroup
                label="WhatsApp"
                keyForm="whatsapp"
                config={{
                  readonly: true,
                }}
              />
            </li>
          )}
          {facebook && (
            <li>
              <FormGroup
                label="Facebook"
                keyForm="facebook"
                config={{
                  readonly: true,
                }}
              />
            </li>
          )}
          {instagram && (
            <li>
              <FormGroup
                label="Instagram"
                keyForm="instagram"
                config={{
                  readonly: true,
                }}
              />
            </li>
          )}
        </ul>
        <div className="formCurrent__actions">
          <button
            className="formCurrent__actions__btn"
            type="submit"
            disabled={
              !formContact.formState.isValid ||
              (firstName === currentFirstName &&
                lastName === currentLastName &&
                email === currentEmail &&
                number === currentNumber &&
                code === currentCode) ||
              (number.length > 0 && number.length < 8 && number.length > 12) ||
              (email.length > 0 && !regexEmail.test(email)) ||
              (number.length === 0 && email.length === 0)
            }
          >
            Actualizar
          </button>
        </div>
      </form>
    </div>
  );
};

const FormGroup = ({
  label,
  placeholder,
  type = "general",
  keyForm,
  config = {},
  keyFormAlt,
  configAlt = {},
}) => {
  const { formContact } = useContext(vlFormContext);
  const {
    register,
    formState: { errors },
    trigger,
  } = formContact;

  return (
    <div className="formGroup">
      {label && <label className="formGroup__label">{label}</label>}
      <div className="formGroup__inputs">
        {type === "general" && (
          <input
            className="formGroup__inputs__general"
            type="text"
            maxLength={config?.maxLength?.value ?? undefined}
            minLength={config?.minLength?.value ?? undefined}
            placeholder={placeholder ?? label}
            {...register(keyForm, config)}
            onBlur={() => trigger(keyForm)}
            onInput={() => trigger(keyForm)}
            disabled={config?.readonly ?? false}
          />
        )}

        {type === "phone" && (
          <>
            <div className="formGroup__inputs__wrapperSelect">
              <select
                className="formGroup__inputs__phoneCode"
                {...register(keyFormAlt, configAlt)}
                disabled={configAlt?.readonly ?? false}
              >
                {phoneCodes.map((code) => (
                  <option key={code} value={code}>
                    +{code}
                  </option>
                ))}
              </select>
              <svg
                className="formGroup__inputs__phoneCode__icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>

            <input
              className="formGroup__inputs__phoneNumber"
              type="text"
              placeholder={placeholder ?? label}
              maxLength={config?.maxLength?.value ?? undefined}
              minLength={config?.minLength?.value ?? undefined}
              {...register(keyForm, config)}
              onBlur={() => trigger(keyForm)}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                trigger(keyForm);
              }}
              disabled={config?.readonly ?? false}
            />
          </>
        )}
      </div>
      {<p className="formGroup__error">{errors[keyForm]?.message}</p>}
    </div>
  );
};
