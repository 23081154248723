import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

function DialogClose({ open, setOpen, onAccept, isClose = true, onClose }) {
  return isClose ? (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div>Cerrar sesión</div>
        <IconButton style={{ marginLeft: "auto" }} onClick={() => onClose()}>
          <CloseIcon
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: "100%",
              color: "#FFFFFF",
              padding: "4px",
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "8px", marginBottom: "12px" }}>
        ¿Está seguro que desea salir?
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          paddingBottom: "18px",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Button
          style={{
            borderRadius: "25.5px",
            backgroundColor: "#EA345F",
            color: "white",
          }}
          fullWidth
          // sx={{ width: { xs: "250px", sm: "350px" } }}
          variant="contained"
          onClick={() => {
            onClose();
            onAccept();
          }}
        >
          Si, deseo salir
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div>Aviso!</div>
        <IconButton style={{ marginLeft: "auto" }} onClick={() => onClose()}>
          <CloseIcon
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: "100%",
              color: "#FFFFFF",
              padding: "4px",
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "8px", marginBottom: "12px" }}>
        <Box style={{ fontSize: 14, fontWeight: "bold" }}>
          ¿Estás seguro de que deseas hacer esto?
        </Box>
        <br />
        <Box style={{ fontSize: 14 }}>
          Al aceptar, estarás agregando esta conversación al contacto
          seleccionado. Esto implica que asumiremos que se trata de la misma
          persona y se sustituirá el contacto actual por el previamente
          seleccionado.
        </Box>
        <br />
        <Box style={{ fontSize: 14 }}>
          Queremos asegurarnos de mantener toda la información relevante
          asociada a un único contacto. Por favor, confirma tu decisión antes de
          continuar. ¡Gracias!
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          paddingBottom: "18px",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Button
          style={{
            borderRadius: "25.5px",
            backgroundColor: "#EA345F",
            color: "white",
          }}
          fullWidth
          // sx={{ width: { xs: "250px", sm: "350px" } }}
          variant="contained"
          onClick={() => {
            onClose();
            onAccept();
          }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogClose;
