import { useContext } from "react";
import { AgentContext } from "../context";

import DateObject from "react-date-object";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import service from "../service";

const GeneralInfo = ({ details, isLoading }) => {
  const { state, setConfigDialog } = useContext(AgentContext);
  return details ? (
    <Grid container spacing={1} style={{ padding: 24}}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Fecha"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              new DateObject(new Date()).format("DD/MM/YYYY")
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Canal"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              service.getChannelName(details)
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Transferencia"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : details.transferredBy ? (
              "SI"
            ) : (
              "NO"
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Tipo de atención"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              state?.abilities?.find(
                (ability) => ability._id === details.ability
              )?.name
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 24 }}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Fecha y hora de inicio"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              new DateObject(new Date(details.createdAt)).format(
                "DD/MM/YYYY hh:mm:ss"
              )
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 24 }}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Fecha y hora de cierre"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              new DateObject(new Date(details.endedAt)).format(
                "DD/MM/YYYY hh:mm:ss"
              )
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 24 }}>
        <Box>
          <Box style={{ color: "#535353" }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              "Grupo"
            )}
          </Box>
          <Box style={{ marginTop: 12 }}>
            {isLoading ? (
              <Skeleton variant="text" width={130} height={30} />
            ) : (
              state?.groups?.find((group) => group._id === details.group)?.name
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default GeneralInfo;
