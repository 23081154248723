export const types = {
  sign_in: "[Sign in agent]",
  logout: "[Sign out agent]",
  handle_sidebar: "[Handle sidebar]",
  handle_dialog_close: "[Handle dialog close]",
  get_groups: "[Get groups]",
  get_abilities: "[Get abilities]",
  get_current_chat: "[Get current chat]",
  set_loading: "[Set loading]",
  set_chat_list: "[Set chat list]",
  get_queue: "[Get queue]",
  set_config_dialog: "[Set config dialog]",
  set_snackbar: "[Set snackbar]",
  set_messages: "[Set messages]",
  get_agents: "[Get agents]",
  set_tab_active: "[Set Tab Active]",
  set_current_files: "[Set current files]",
  set_current_abilities: "[Set current abilities]",
  set_preview_images: "[Set preview images]",
  set_messages_images: "[Set messages images]",
  set_selected_image: "[Set selected image]",
  set_search: "[Set search]",
  set_filter_history: "[Set filter history]",
  set_checked_filter: "[Set checked filter]",
  get_history: "[Get history]",
  handle_dialog_details: "[Handle dialog details]",
  handle_dialog_screen: "[Handle dialog screen]",
};
