import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { chatContext } from "../../context/chatContext";
import { contactSrc } from "../../services/contact.service";
import { AgentContext } from "../../context";

export const vlFormContext = createContext();
export const VlFormProvider = ({
  children,
  generalState,
  getCurrentChat,
  getMoreMessages,
}) => {
  const { updateChatList } = useContext(chatContext);
  const { socket, setSnackbar } = useContext(AgentContext);
  const [contact, setContact] = useState(
    generalState?.currentChat?.contactV3 ?? {}
  );
  const [isVerify, setIsVerify] = useState(false);
  const [exist, setExist] = useState(false);
  const [otherChats, setOtherChats] = useState([]);
  const [refContact, setRefContact] = useState({});
  const [oldDataForm, setOldDataForm] = useState({});
  const [currentChatRef, setCurrentChatRef] = useState(
    generalState?.currentChat ?? {}
  );

  const [dialogConfig, setDialogConfig] = useState({
    isActive: false,
    title: "",
    informations: [],
    onClose: () => {},
  });

  useEffect(() => {
    checkChatsActives();
    setIsVerify(false);
  }, [contact]);

  useEffect(() => {
    const {
      email = "",
      phone = [],
      firstName = "",
      lastName = "",
      social = {},
    } = refContact;
    const { number = "", code = "58" } = phone[0] ?? {};
    formContact.setValue("email", email);
    formContact.setValue("number", number);
    formContact.setValue("code", code);
    formContact.setValue("firstName", firstName);
    formContact.setValue("lastName", lastName);

    formContact.trigger("email");
    formContact.trigger("number");
    formContact.trigger("code");
    formContact.trigger("firstName");
    formContact.trigger("lastName");

    const { whatsapp, facebook, instagram } = social;

    if (whatsapp) {
      formContact.setValue("whatsapp", whatsapp.username ?? "");
    }

    if (facebook) {
      formContact.setValue("facebook", facebook.username ?? "");
    }

    if (instagram) {
      formContact.setValue("instagram", instagram.username ?? "");
    }
  }, [refContact]);

  useEffect(() => {
    setContact(generalState?.currentChat?.contactV3 ?? {});
    setCurrentChatRef(generalState?.currentChat ?? {});
    const {
      email = "",
      phone = [],
      firstName = "",
      lastName = "",
      social = {},
    } = generalState?.currentChat?.contactV3 ?? {};
    const { number = "", code = "58" } = phone[0] ?? {};
    formContact.setValue("email", email);
    formContact.setValue("number", number);
    formContact.setValue("code", code);
    formContact.setValue("firstName", firstName);
    formContact.setValue("lastName", lastName);

    formContact.trigger("email");
    formContact.trigger("number");
    formContact.trigger("code");
    formContact.trigger("firstName");
    formContact.trigger("lastName");

    const { whatsapp, facebook, instagram } = social;

    if (whatsapp) {
      formContact.setValue("whatsapp", whatsapp.username ?? "");
    }

    if (facebook) {
      formContact.setValue("facebook", facebook.username ?? "");
    }

    if (instagram) {
      formContact.setValue("instagram", instagram.username ?? "");
    }
  }, [generalState.currentChat]);

  const updateAllReferences = async () => {
    await updateChatList();
    await getCurrentChat(
      generalState?.currentChat?._id,
      generalState?.currentChat
    );
  };

  const checkChatsActives = async () => {
    try {
      const { data } = await contactSrc.checkChatContact({
        contactId: contact?._id,
        currentChatId: currentChatRef?._id,
      });

      setOtherChats(data.chats ?? []);
    } catch (error) {}
  };

  const formContact = useForm({
    mode: "all",
    defaultValues: {
      email: contact?.email ?? "",
      number: contact?.phone?.length ? contact?.phone[0]?.number ?? "" : "",
      code: contact?.phone?.length ? contact?.phone[0]?.code ?? "58" : "58",
      firstName: contact?.firstName ?? "",
      lastName: contact?.lastName ?? "",
      whatsapp: contact?.social?.whatsapp?.username ?? "",
      facebook: contact?.social?.facebook?.username ?? "",
      instagram: contact?.social?.instagram?.username ?? "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isShowModalMerge, setIsShowModalMerge] = useState(false);

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  const showModalMerge = (contacts, payload) => {
    setDialogConfig({
      isActive: true,
      title: "Coincidencia de contactos",
      informations: [
        {
          msg: "Se ha encontrado un contacto con los mismos datos",
          important: true,
        },
        {
          msg: "Existe un registro de este contacto. Al continuar, la información se actualizará.",
        },
        {
          msg: "¿Deseas continuar y reemplazar los datos existentes?",
        },
      ],
      onClose: (confirm) => {
        if (!confirm) return;
        mergeContacts(payload, true);
      },
    });
  };
  const showModalCloseOthersChats = () => {
    setDialogConfig({
      isActive: true,
      title: "¡Advertencia!",
      informations: [
        {
          msg: "Este contacto tiene varias atenciones abiertas en curso.",
          important: true,
        },
        {
          msg: "Al continuar, se cerrarán automáticamente las otras atenciones y se unirán en un solo chat.",
        },
        {
          msg: "¿Deseas continuar?",
        },
      ],
      onClose: (confirm) => {
        if (!confirm) return;
        localStorage.setItem(
          "othersChats",
          JSON.stringify(otherChats.map((chat) => chat?._id))
        );
        closeOtherAttentions();
      },
    });
  };

  const searchContactUpdate = async () => {
    try {
      showLoading();

      const { email, number, code, firstName, lastName } =
        formContact.getValues();

      const payload = {
        _id: contact._id,
      };

      if (email) payload.email = email.trim();
      if (number) {
        payload.number = number.trim();
        payload.code = code;
      }
      if (firstName) payload.firstName = firstName.trim();
      if (lastName) payload.lastName = lastName.trim();
      payload.social = contact.social ?? {
        whatsapp: {
          username: "",
          id: "",
        },
        facebook: {
          username: "",
          id: "",
        },
        instagram: {
          username: "",
          id: "",
        },
      };

      const requestUpdate = await contactSrc.searchContact(payload);
      const { contact: newContact, contacts = [] } = requestUpdate.data;

      if (contacts.length > 0) {
        const payload = {
          contactsId: [contact._id],
        };

        if (email) payload.email = email.trim();
        if (number) {
          payload.number = number.trim();
          payload.code = code;
        }
        if (firstName) payload.firstName = firstName.trim();
        if (lastName) payload.lastName = lastName.trim();

        payload.social = contact.social ?? {
          whatsapp: {
            username: "",
            id: "",
          },
          facebook: {
            username: "",
            id: "",
          },
          instagram: {
            username: "",
            id: "",
          },
        };

        for (let i = 0; i < contacts.length; i++) {
          const refContact = contacts[i];
          payload.contactsId.push(refContact._id);

          if (!payload.email && refContact.email) {
            payload.email = refContact.email;
          }

          if (!payload.number && refContact.phone.length > 0) {
            payload.number = refContact.phone[0].number;
            payload.code = refContact.phone[0].code;
          }

          if (!payload.firstName && refContact.firstName) {
            payload.firstName = refContact.firstName;
          }

          if (!payload.lastName && refContact.lastName) {
            payload.lastName = refContact.lastName;
          }

          if (!payload.social.whatsapp.id && refContact.social.whatsapp.id) {
            payload.social.whatsapp = {
              username: refContact.social.whatsapp.username,
              id: refContact.social.whatsapp.id,
            };
          }

          if (!payload.social.facebook.id && refContact.social.facebook.id) {
            payload.social.facebook = {
              username: refContact.social.facebook.username,
              id: refContact.social.facebook.id,
            };
          }

          if (!payload?.social.instagram.id && refContact.social.instagram.id) {
            payload.social.instagram = {
              username: refContact.social.instagram.username,
              id: refContact.social.instagram.id,
            };
          }
        }

        // contacts;
        // payload;

        // await mergeContacts(payload);
        showModalMerge(contacts, payload);
      } else {
        setContact(newContact);
        await updateAllReferences();
        setSnackbar({
          open: true,
          message: `Contacto actualizado con éxito`,
          severity: "info",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading();
    }
  };

  const mergeContacts = async (contact, showModal = false) => {
    try {
      if (showModal) showLoading();
      const requestMerge = await contactSrc.mergeContacts(contact);
      setContact(requestMerge.data.newContact);
      await updateAllReferences();

      setSnackbar({
        open: true,
        message: `Contacto combinado con éxito`,
        severity: "info",
      });
    } catch (error) {
    } finally {
      if (showModal) hideLoading();
    }
  };

  const closeOtherAttentions = async () => {
    for (let i = 0; i < otherChats.length; i++) {
      const chat = otherChats[i];
      socket?.emit("chat_ended", {
        chat_id: chat._id,
        reason: {
          type: "done",
          reason: "Combinación de chats desde el agente",
        },
      });
    }

    getMoreMessages();

    setOtherChats([]);
  };

  return (
    <vlFormContext.Provider
      value={{
        contact,
        setContact,
        isVerify,
        setIsVerify,
        exist,
        setExist,
        refContact,
        setRefContact,
        otherChats,
        formContact,
        isLoading,
        showLoading,
        hideLoading,
        currentChatRef,
        setCurrentChatRef,
        generalState,
        updateChatList,
        oldDataForm,
        setOldDataForm,
        updateAllReferences,
        isShowModalMerge,
        showModalMerge,
        searchContactUpdate,
        mergeContacts,
        closeOtherAttentions,
        dialogConfig,
        setDialogConfig,
        showModalCloseOthersChats,
      }}
    >
      {children}
    </vlFormContext.Provider>
  );
};
