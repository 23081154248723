import { useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import "./ticket-list-paginator.scss";

export const TicketListPaginator = ({ config, changeValue = () => {} }) => {
  const [state, setState] = useState({
    init: 1,
    end: config.step || 10,
    total: config.total || 10,
    step: config.step || 10,
    page: 1,
  });

  const back = () => {
    if (state.page > 0) {
      setState({
        ...state,
        page: state.page - 1,
        init: (state.page - 1) * state.step + 1,
        end: state.page * state.step,
      });
      changeValue(state);
      console.log("back", state);
    }
  };
  const next = () => {
    if (state.end < state.total) {
      setState({
        ...state,
        page: state.page + 1,
        init: state.page * state.step + 1,
        end:
          (state.page + 1) * state.step > state.total
            ? state.total
            : (state.page + 1) * state.step,
      });
      console.log(state);
      changeValue(state);
    }
  };
  return (
    <>
      <div className="ticket-list-paginator">
        <span className="ticket-list-paginator__text">{`${state.init} - ${state.end}
         de ${state.total}`}</span>
        <ArrowRight
          className={`ticket-list-paginator__arrow__left ${
            state.init === 1 && "ticket-list-paginator__arrow--disabled"
          }`}
          onClick={back}
        />
        <ArrowRight
          className={`ticket-list-paginator__arrow__right ${
            state.end === state.total &&
            "ticket-list-paginator__arrow--disabled"
          }`}
          onClick={next}
        />
      </div>
    </>
  );
};
