import { useContext, useEffect, useState } from "react";
import storageBox from "../../assets/storageBox.svg";
import transferIcon from "../../assets/transferIcon.svg";
import { getCanalIcon } from "../../utils";
import "./cardChat.scss";
import { AgentContext } from "../../context";

export const CardChat = ({ item, getCurrentChat, position }) => {
  const { state } = useContext(AgentContext);
  // const [itemRef, setItemRef] = useState(item);
  const IconChannel = getCanalIcon(item.channel);
  const [nameContact, setNameContact] = useState(
    item.contactV3
      ? `${item?.contactV3?.firstName ?? ""} ${
          item?.contactV3?.lastName ?? ""
        }`.trim()
      : "No especificado"
  );

  useEffect(() => {
    setNameContact(
      item.contactV3
        ? `${item?.contactV3?.firstName ?? ""} ${
            item?.contactV3?.lastName ?? ""
          }`.trim()
        : "No especificado"
    );
  }, [item]);

  const changeCurrentChat = () => {
    getCurrentChat(item?._id, item);
  };

  return (
    <div
      className={`cardChat ${
        state.currentChat?._id === item._id ? "selected" : ""
      }`}
      onClick={changeCurrentChat}
    >
      <div className="cardChat__wrapper">
        <img
          className="cardChat__wrapper__icon"
          src={IconChannel}
          alt={"Icon" + item.channel}
        ></img>
      </div>
      <div className="cardChat__text">
        <p className="cardChat__text__contact">{nameContact}</p>
        <p className="cardChat__text__ability">{item?.ability?.name}</p>
      </div>
      <div className="cardChat__extra">
        <p className="cardChat__extra__hours">
          {new Intl.DateTimeFormat(undefined, {
            timeStyle: "short",
            hour12: true,
          }).format(new Date(item.createdAt))}
        </p>
        <div className="cardChat__extra__notifications">
          {item?.count > 0 && (
            <p className="cardChat__extra__notifications__msj">
              {item?.count > 99 ? "+99" : item?.count || 0}
            </p>
          )}
          {item?.transferredBy && (
            <div className="cardChat__extra__notifications__transfert">
              <img
                className="cardChat__extra__notifications__transfert__icon"
                src={transferIcon}
                alt="transfer"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export const WrapperCardEmpy = () => {
  return (
    <div className="wrapper-cardCard-empty">
      <div className="wrapper-cardCard-empty__info">
        <img
          className="wrapper-cardCard-empty__info__icon"
          src={storageBox}
          alt="Box empty"
        />
        <p className="wrapper-cardCard-empty__info__text">
          No dispones de atenciones en curso
        </p>
      </div>
    </div>
  );
};
