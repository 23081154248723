import "./ticket-details.scss";
import { TicketDetailsHeader } from "../ticket-details-header/ticket-details-header";
import { TicketDetailsTable } from "../ticket-details-table/ticket-details-table";
import { TicketInput } from "../ticket-details-input/ticket-details-input";

export const TicketDetails = () => {
  return (
    <>
      <div className="ticket-details">
        <TicketDetailsHeader />
        <TicketDetailsTable />
        <TicketInput />
      </div>
    </>
  );
};
