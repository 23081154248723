import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

const ProfileForm = ({ isMobile, state, hanleData }) => {
  const regexForPhone = /^[0-9]*$/;
  const regexForName = /^[ñA-zÀ-ú\s]*$/;
  const [openTooltip, setOpenTooltip] = useState(false);
  const [firstname, setFirstname] = useState(state.user.firstname);
  const [lastname, setLastname] = useState(state.user.lastname);
  const [phone, setPhone] = useState(state.user.phone ? state.user.phone : "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [validatePasswordForOneLetter, setValidatePasswordForOneLetter] =
    useState(false);
  const [
    validatePasswordForOneLetterUppercase,
    setValidatePasswordForOneLetterUppercase,
  ] = useState(false);
  const [validatePasswordForOneNumber, setValidatePasswordForOneNumber] =
    useState(false);
  const [validatePasswordLength, setValidatePasswordLength] = useState(false);

  const regexForOneLetter = /[a-zA-Z]/;
  const regexForOneUppecaseLetter = /[A-Z]/;
  const regexForOneNumber = /[0-9]/;

  const [isEditName, setIsEditName] = useState(false);
  const [isEditLastname, setIsEditLastname] = useState(false);
  const [isEditPhone, setIsEditPhone] = useState(false);

  useEffect(() => {
    if (password.length >= 8) {
      setValidatePasswordLength(true);
    } else {
      setValidatePasswordLength(false);
    }
    if (regexForOneLetter.test(password)) setValidatePasswordForOneLetter(true);
    else setValidatePasswordForOneLetter(false);
    if (regexForOneUppecaseLetter.test(password))
      setValidatePasswordForOneLetterUppercase(true);
    else setValidatePasswordForOneLetterUppercase(false);
    if (regexForOneNumber.test(password)) setValidatePasswordForOneNumber(true);
    else setValidatePasswordForOneNumber(false);
  }, [password]);

  return (
    <Box>
      <Box style={{ display: !isMobile && "flex", alignItems: "center" }}>
        <Box
          style={{
            width: isMobile ? "100%" : "50%",
            marginRight: 16,
            marginBottom: isMobile && 14,
          }}
        >
          <TextField
            helperText={firstname.length <= 1 ? "mínimo 2 caracteres" : " "}
            style={{ maxWidth: !isMobile && 400 }}
            disabled={!isEditName}
            fullWidth
            inputProps={{
              maxLength: 20,
            }}
            label="Nombre"
            value={firstname}
            onChange={(event) => {
              if (!regexForName.test(event.target.value)) {
                return false;
              } else {
                setFirstname(event.target.value);
              }
            }}
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsEditName(!isEditName)}>
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          style={{
            width: isMobile ? "100%" : "50%",
            marginBottom: isMobile && 14,
          }}
        >
          <TextField
            helperText={lastname.length <= 1 ? "mínimo 2 caracteres" : " "}
            inputProps={{
              maxLength: 20,
            }}
            disabled={!isEditLastname}
            style={{ maxWidth: !isMobile && 400 }}
            fullWidth
            label="Apellido"
            variant="standard"
            value={lastname}
            onChange={(event) => {
              if (!regexForName.test(event.target.value)) {
                return false;
              } else {
                setLastname(event.target.value);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsEditLastname(!isEditLastname)}
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: !isMobile && "flex",
          alignItems: "center",
          marginTop: !isMobile && 20,
          arginBottom: isMobile && 8,
        }}
      >
        <Box
          style={{
            width: isMobile ? "100%" : "50%",
            marginRight: 16,
            marginBottom: isMobile && 14,
          }}
        >
          <TextField
            inputProps={{
              maxLength: 12,
            }}
            disabled={!isEditPhone}
            style={{ maxWidth: !isMobile && 400 }}
            fullWidth
            value={phone}
            onChange={(event) => {
              if (!regexForPhone.test(event.target.value)) {
                return false;
              } else {
                setPhone(event.target.value);
              }
            }}
            label="Teléfono"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsEditPhone(!isEditPhone)}>
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box style={{ width: isMobile ? "100%" : "50%" }}>
          <Box style={{ fontSize: 12 }}>Correo</Box>
          <Box style={{ paddingTop: 4, boxSizing: "border-box" }}>
            {state.user.email}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: !isMobile && "flex",
          alignItems: "center",
          marginTop: !isMobile && 56,
        }}
      >
        <Box
          style={{
            width: isMobile ? "100%" : "50%",
            marginRight: 16,
            marginBottom: isMobile && 14,
          }}
        >
          <Tooltip
            componentsProps={{
              tooltip: {
                className: "tooltip-class-agent",
              },
            }}
            style={{ width: "80%" }}
            disableHoverListener
            open={openTooltip}
            title={
              <div style={{ padding: 24 }}>
                <div
                  style={{
                    color: validatePasswordForOneLetter ? "#0EF00F" : "#D41924",
                    fontSize: 14,
                    marginBottom: 3,
                  }}
                >
                  Al menos una letra
                </div>

                <div
                  style={{
                    color: validatePasswordForOneLetterUppercase
                      ? "#0EF00F"
                      : "#D41924",
                    fontSize: 14,
                    marginBottom: 3,
                  }}
                >
                  Al menos una letra mayúscula
                </div>
                <div
                  style={{
                    color: validatePasswordForOneNumber ? "#0EF00F" : "#D41924",
                    fontSize: 14,
                    marginBottom: 3,
                  }}
                >
                  Al menos un número
                </div>
                <div
                  style={{
                    color: validatePasswordLength ? "#0EF00F" : "#D41924",
                    fontSize: 14,
                    marginBottom: 3,
                  }}
                >
                  Al menos 8 carácteres
                </div>
              </div>
            }
            placement="bottom-start"
          >
            <TextField
              helperText=" "
              type={isShowPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setOpenTooltip(true)}
              onBlur={() => setOpenTooltip(false)}
              style={{ maxWidth: !isMobile && 400 }}
              fullWidth
              label="Cambiar contraseña"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? (
                        <VisibilityOffIcon position="end" />
                      ) : (
                        <VisibilityIcon position="end" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </Box>
        <Box style={{ width: isMobile ? "100%" : "50%" }}>
          <TextField
            helperText={
              confirmPassword !== password && confirmPassword !== ""
                ? "No coincide contraseña"
                : " "
            }
            type={isShowConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ maxWidth: !isMobile && 400 }}
            fullWidth
            label="Confirmar nueva contraseña"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setIsShowConfirmPassword(!isShowConfirmPassword)
                    }
                  >
                    {isShowConfirmPassword ? (
                      <VisibilityOffIcon position="end" />
                    ) : (
                      <VisibilityIcon position="end" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box style={{ marginTop: 30, width: !isMobile && "50%" }}>
        <Button
          fullWidth
          variant="contained"
          style={{
            borderRadius: "25.5px",
            backgroundColor:
              firstname.length <= 1 ||
              lastname.length <= 1 ||
              password.length > 0 ||
              confirmPassword.length > 0
                ? !validatePasswordForOneLetter ||
                  !validatePasswordForOneLetterUppercase ||
                  !validatePasswordForOneNumber ||
                  !validatePasswordLength ||
                  password !== confirmPassword
                  ? "#D6D8E8"
                  : "#EA345F"
                : "#EA345F",
            color: "white",
            textTransform: "inherit",
            maxWidth: !isMobile && 300,
            height: isMobile && 27,
          }}
          disabled={
            firstname.length <= 1 ||
            lastname.length <= 1 ||
            password.length > 0 ||
            confirmPassword.length > 0
              ? !validatePasswordForOneLetter ||
                !validatePasswordForOneLetterUppercase ||
                !validatePasswordForOneNumber ||
                !validatePasswordLength ||
                password !== confirmPassword
              : false
          }
          onClick={() => {
            hanleData({
              firstname,
              lastname,
              phone,
              password,
            });
            setPassword("");
            setConfirmPassword("");
          }}
        >
          Guardar cambios
        </Button>
      </Box>
    </Box>
  );
};

export default ProfileForm;
