import { useContext } from "react";
import { TicketContext } from "../../context";
import { ProfileImage } from "../profile-image/profile-image";
import "./ticket-contact-info.scss";

import { ReactComponent as PhoneSvg } from "../../assets/icons/phone.svg";
import { ReactComponent as EmailSvg } from "../../assets/icons/mail.svg";

export const TicketContactInfo = () => {
  const { ticket } = useContext(TicketContext);
  return (
    <>
      <div className="ticket-contact-info">
        <div className="ticket-contact-info__header">
          <ProfileImage name={ticket?.client?.name} />
          <div className="ticket-contact-info__header__name">
            {ticket?.client?.name}
          </div>
        </div>
        <div className="ticket-contact-info__body">
          <div className="ticket-contact-info__body__email">
            <EmailSvg />{" "}
            <span className="ticket-contact-info__body__email__text">
              {ticket?.client?.email}
            </span>
          </div>
          <div className="ticket-contact-info__body__phone">
            <PhoneSvg />{" "}
            <span className="ticket-contact-info__body__phone__text">
              {ticket?.client?.phone}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
