import { useState, useContext } from "react";
import PropTypes from "prop-types";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import iconAgent from "../assets/Chat.svg";
import HistorialIcon from "../assets/historial.svg";

import SignOutIcon from "../assets/SignOutIcon.png";
import TextLogo from "../assets/VideoLink_Logo21.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import icon from "../assets/iconovideolink.svg";
import companyLogo from "../assets/svg/omniflou-white.svg";
import companyLogoSmall from "../assets/svg/omniflow-white-small.svg";
import { Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { getCanalIcon } from "../utils";

import { AgentContext } from "../context";

function Sidebar(props) {
  const [openAction, setOpenAction] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const { setCurrentChat } = useContext(AgentContext);

  // console.log(props?.user);

  const StyledMenu = styled((props) => (
    <Menu
      levation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 10,
      marginTop: theme.spacing(1),
      maxWidth: 180,
      backgroundColor: "#0B1022",
      color: "white",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const drawer = (
    <div
      id="sidebar-vl"
      style={{
        width: "auto",
        height: "100%",
        textAlign: "center",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto  1fr auto",
      }}
    >
      <div
        style={{
          backgroundColor: "#0B1022",
          paddingBottom: 15,
          paddingTop: "15px",
        }}
      >
        <div
          style={{
            marginBottom: props.isOpenSideMenu ? "12px" : "36px",
            marginTop: props.isOpenSideMenu && "12px",
          }}
        >
          {props.isOpenSideMenu ? (
            <div
              style={{
                display: "flex",
                marginLeft: "12px",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "auto", marginBottom: -5 }}>
                <img
                  src={companyLogo}
                  style={{ width: "150px", paddingRight: 16 }}
                  alt="logo"
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 8,
                }}
              >
                <IconButton
                  onClick={() => props.handleDrawerToggle(false)}
                  size={props.isMobile && "small"}
                  // style={{ position: "absolute", right: 0 }}
                >
                  <ArrowBackIcon style={{ color: "#FFFFFF" }} />
                </IconButton>
              </div>
            </div>
          ) : props.isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton
                variant="circular"
                width={48}
                height={48}
                style={{ backgroundColor: "#0B1022" }}
              />
            </div>
          ) : (
            <IconButton onClick={() => props.handleDrawerToggle(true)}>
              <img
                src={companyLogoSmall}
                style={{ width: "30px", objectFit: "contain" }}
                alt="logo"
              />
            </IconButton>
          )}
        </div>
        {props.isOpenSideMenu ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
                marginTop: "24px",
              }}
            >
              <Avatar
                style={{ cursor: "pointer", width: "64px", height: "64px" }}
                onClick={() => {
                  setCurrentChat(undefined);
                  props.onHandlePage("/profile");
                }}
                src={props?.avatar ? props?.avatar : getCanalIcon("web")}
              >
                <Typography variant="h4">{`${props?.user?.firstname[0]}${props?.user?.lastname[0]}`}</Typography>
              </Avatar>
            </div>
            <div
              style={{
                color: "#FFFFFF",
                overflowWrap: "break-word",
                fontWeight: "bold",
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentChat(undefined);
                props.onHandlePage("/profile");
              }}
            >
              {`${props.user?.firstname} ${props.user?.lastname}`}
            </div>
            <div
              id="demo-customized-button"
              aria-controls={"demo-customized-menu"}
              aria-haspopup="true"
              aria-expanded={"true"}
              onClick={(event) => {
                setOpenAction(!openAction);
                setAnchorElAction(event.currentTarget);
              }}
            >
              <div
                className="status-agent"
                style={{
                  width: "70%",
                  border: "0.494609px solid #FFFFFF",
                  borderRadius: 20,
                  margin: "auto",
                  marginTop: 5.82,
                  marginBottom: 30.94,
                  display: "flex",
                  // alignItems: "center",
                  padding: 4,
                }}
              >
                <div style={{ marginLeft: 8, display: "flex" }}>
                  <CircleIcon
                    style={{
                      color: props.connect ? "#0EF00F" : "#5F6B4D",
                    }}
                  />
                </div>
                <div style={{ color: "white", marginLeft: 8, display: "flex" }}>
                  {props.connect ? "Activo" : "Inactivo"}
                </div>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <KeyboardArrowDownIcon style={{ color: "white" }} />
                </div>
              </div>
              <StyledMenu
                id="basic-menu"
                anchorEl={anchorElAction}
                open={openAction}
                onClose={() => {
                  setAnchorElAction();
                  setOpenAction(false);
                }}
                MenuListProps={{
                  "aria-labelledby": "b]asic-button",
                }}
              >
                <MenuItem onClick={() => props.handleAgentConnect(true)}>
                  <CircleIcon fontSize="large" style={{ color: "#0EF00F" }} />
                  Activo
                </MenuItem>
                {/* <MenuItem onClick={() => props.handleAgentConnect("offline")}>
                  <CircleIcon style={{ color: "#EBE14B" }} />
                  Ausente
                </MenuItem> */}
                {props.currentChat || props.chatList?.length > 0 ? (
                  <Tooltip
                    title="Debes finalizar todas tus atenciones abiertas para poder cambiar el estado a inactivo"
                    placement="bottom"
                    // open={props.currentChat}
                  >
                    <MenuItem>
                      <CircleIcon style={{ color: "#5F6B4D" }} />
                      Inactivo
                    </MenuItem>
                  </Tooltip>
                ) : (
                  <MenuItem
                    onClick={() => {
                      props.handleAgentConnect(false);
                    }}
                  >
                    <CircleIcon style={{ color: "#5F6B4D" }} />
                    Inactivo
                  </MenuItem>
                )}
              </StyledMenu>
            </div>
          </div>
        ) : props.isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "24px",
            }}
          >
            <Skeleton
              variant="circular"
              width={48}
              height={48}
              style={{ backgroundColor: "#0B1022" }}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <Avatar
                style={{ width: 48, height: 48, cursor: "pointer" }}
                onClick={() => {
                  setCurrentChat(undefined);
                  props.onHandlePage("/profile");
                }}
                src={props?.avatar ? props?.avatar : getCanalIcon("web")}
              >
                <Typography variant="h6">
                  {props.user &&
                    `${props?.user?.firstname[0]}${props?.user?.lastname[0]}`}
                </Typography>
              </Avatar>
            </div>
          </>
        )}
      </div>

      {props.isOpenSideMenu ? (
        <List style={{ width: "100%" }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() =>
                props.page === "page"
                  ? console.log("")
                  : props.onHandlePage("/chat")
              }
              style={{
                // backgroundColor: props.page === "chat" ? "#1B0779" : "",

                borderRadius: "0px 20px 20px 0px",
              }}
            >
              <ListItemIcon>
                <img src={iconAgent} style={{ width: "32px" }} alt="agente" />
              </ListItemIcon>
              <ListItemText
                primary="Chat en vivo"
                primaryTypographyProps={{
                  style: {
                    fontWeight: "bold",
                    color: props.page === "chat" ? "#FFFFFF" : "#E6E7F3",
                    fontSize: 18,
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setCurrentChat(undefined);
                props.onHandlePage("/history");
              }}
            >
              <ListItemIcon>
                <img
                  src={HistorialIcon}
                  style={{ width: "32px" }}
                  alt="historial"
                />
              </ListItemIcon>
              <ListItemText
                primary="Historial"
                primaryTypographyProps={{
                  style: {
                    fontWeight: "bold",
                    color: props.page === "chat" ? "#FFFFFF" : "#E6E7F3",
                    fontSize: 18,
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <Tooltip title="Próximamente">
              <ListItemButton
                disableRipple
                onClick={console.log}
                style={{ cursor: "default" }}
              >
                <ListItemIcon>
                  <img
                    src={comment}
                    style={{ width: "32px" }}
                    alt="Comentarios"
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#FFFFFF" }}
                  primary="Comentarios"
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      color: props.page === "comments" ? "#FFFFFF" : "#E6E7F3",
                      fontSize: 18,
                    },
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem> */}
          {/* <ListItem disablePadding>
            <Tooltip title="Tickets">
              <ListItemButton
                onClick={console.log}
                disa
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <img
                    src={tickets}
                    style={{ width: "32px" }}
                    alt="historial"
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#FFFFFF" }}
                  primary="Tickets"
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      fontSize: 18,
                      color: props.page === "ticket" ? "#FFFFFF" : "#E6E7F3",
                    },
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem> */}
          {/* <ListItem disablePadding>
            <Tooltip title="Próximamente">
              <ListItemButton
                disableRipple
                onClick={console.log}
                style={{ cursor: "default" }}
              >
                <ListItemIcon>
                  <img src={help} style={{ width: "32px" }} alt="Ayuda" />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#FFFFFF" }}
                  primary="Ayuda"
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      color: props.page === "help" ? "#FFFFFF" : "#E6E7F3",
                      fontSize: 18,
                    },
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem> */}
          {/* <ListItem disablePadding disable>
            <Tooltip title="Próximamente">
              <ListItemButton
                onClick={console.log}
                disableRipple
                style={{ cursor: "default" }}
              >
                <ListItemIcon>
                  <img
                    src={settings}
                    style={{ width: "32px" }}
                    alt="Configuración"
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#FFFFFF" }}
                  primary="Configuración" 
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      color: props.page === "settings" ? "#FFFFFF" : "#E6E7F3",
                      fontSize: 18,
                    },
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem> */}
        </List>
      ) : (
        <div>
          <Divider
            style={{
              borderBottomColor: "white",
              marginLeft: 18,
              marginRight: 18,
              color: "white",
              paddingTop: 12,
              paddingBottom: 24,
            }}
          />
          <div>
            {props.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  style={{ backgroundColor: "#0B1022" }}
                />
              </div>
            ) : (
              <>
                <IconButton
                  style={{ color: "white", paddingBottom: 0 }}
                  onClick={() =>
                    props.page === "page"
                      ? console.log("")
                      : props.onHandlePage("/chat")
                  }
                >
                  <img src={iconAgent} style={{ width: "48px" }} alt="agente" />
                </IconButton>
                <Box
                  style={{ color: "#FFFFFF", fontSize: 10, cursor: "pointer" }}
                  onClick={() =>
                    props.page === "page"
                      ? console.log("")
                      : props.onHandlePage("/chat")
                  }
                >
                  Chat
                </Box>
              </>
            )}
          </div>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            {props.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  style={{ backgroundColor: "#0B1022" }}
                />
              </div>
            ) : (
              <>
                <IconButton
                  style={{ color: "white", paddingBottom: 0 }}
                  onClick={() => {
                    setCurrentChat(undefined);
                    props.onHandlePage("/history");
                  }}
                >
                  <img
                    src={HistorialIcon}
                    style={{ width: "48px" }}
                    alt="historial"
                  />
                </IconButton>
                <Box
                  style={{ color: "#FFFFFF", fontSize: 10, cursor: "pointer" }}
                  onClick={() => {
                    setCurrentChat(undefined);
                    props.page === "history"
                      ? console.log("")
                      : props.onHandlePage("/history");
                  }}
                >
                  Historial
                </Box>
              </>
            )}
          </div>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            {props.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  style={{ backgroundColor: "#0B1022" }}
                />
              </div>
            ) : (
              <></>
              // <Tooltip title="Próximamente">
              //   <Box>
              //     <IconButton
              //       style={{
              //         color: "white",
              //         cursor: "default",
              //         paddingBottom: 0,
              //       }}
              //       disableRipple
              //       disableFocusRipple
              //       disableTouchRipple
              //     >
              //       <img
              //         src={comment}
              //         style={{ width: "48px" }}
              //         alt="historial"
              //       />
              //     </IconButton>
              //     <Box
              //       style={{
              //         color: "#FFFFFF",
              //         fontSize: 10,
              //       }}
              //     >
              //       Comentarios
              //     </Box>
              //   </Box>
              // </Tooltip>
            )}
          </div>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            {props.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  style={{ backgroundColor: "#0B1022" }}
                />
              </div>
            ) : (
              <></>
              // <Tooltip title="Ticket">
              //   <IconButton
              //     style={{ color: "white", cursor: "pointer" }}
              //     onClick={console.log}
              //   >
              //     <img
              //       src={tickets}
              //       style={{ width: "48px" }}
              //       alt="historial"
              //     />
              //   </IconButton>
              // </Tooltip>
            )}
          </div>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            {props.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  style={{ backgroundColor: "#0B1022" }}
                />
              </div>
            ) : (
              <></>
              // <Tooltip title="Próximamente">
              //   <Box>
              //     <IconButton
              //       style={{
              //         color: "white",
              //         cursor: "default",
              //         paddingBottom: 0,
              //       }}
              //       disableRipple
              //       disableFocusRipple
              //       disableTouchRipple
              //     >
              //       <img src={help} style={{ width: "48px" }} alt="historial" />
              //     </IconButton>
              //     <Box
              //       style={{
              //         color: "#FFFFFF",
              //         fontSize: 10,
              //       }}
              //     >
              //       Ayuda
              //     </Box>
              //   </Box>
              // </Tooltip>
            )}
          </div>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            {props.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  style={{ backgroundColor: "#0B1022" }}
                />
              </div>
            ) : (
              <></>
              // <Tooltip title="Próximamente">
              //   <Box>
              //     <IconButton
              //       style={{
              //         color: "white",
              //         cursor: "default",
              //         paddingBottom: 0,
              //       }}
              //       disableRipple
              //       disableFocusRipple
              //       disableTouchRipple
              //     >
              //       <img
              //         src={settings}
              //         style={{ width: "48px" }}
              //         alt="historial"
              //       />
              //     </IconButton>
              //     <Box
              //       style={{
              //         color: "#FFFFFF",
              //         fontSize: 10,
              //       }}
              //     >
              //       Configuración
              //     </Box>
              //   </Box>
              // </Tooltip>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          color: "white",
          bottom: props.isMobile ? props.height > 700 && "15px" : "15px",
          position: props.isMobile
            ? props.height > 700 && "absolute"
            : props.height > 780 && "absolute",
          width: "100%",
        }}
      >
        <div
          style={{
            marginTop: "8px",
          }}
        >
          {props.isOpenSideMenu ? (
            <List>
              <ListItem disablePadding style={{ marginBottom: "8px" }}>
                {props.currentChat || props.chatList?.length > 0 ? (
                  <Tooltip
                    title="Debes finalizar todas tus atenciones abiertas para poder salir"
                    placement="bottom"
                    // open={props.currentChat}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <img
                          src={SignOutIcon}
                          style={{ width: "32px" }}
                          alt="cerrar sesion"
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          color: "#FFFFFF",
                          fontWeight: 800,
                          fontSize: 18,
                        }}
                        primaryTypographyProps={{
                          style: {
                            fontWeight: "bold",
                            color: "#FFFFFF",
                            fontSize: 18,
                          },
                        }}
                        primary="Salir"
                      />
                    </ListItemButton>
                  </Tooltip>
                ) : (
                  <ListItemButton onClick={() => props.openDialogClose(true)}>
                    <ListItemIcon>
                      <img
                        src={SignOutIcon}
                        style={{ width: "32px" }}
                        alt="cerrar sesion"
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                      primaryTypographyProps={{
                        style: {
                          fontWeight: "bold",
                          color: "#FFFFFF",
                          fontSize: 18,
                        },
                      }}
                      primary="Salir"
                    />
                  </ListItemButton>
                )}
              </ListItem>
            </List>
          ) : props.isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <Skeleton
                variant="circular"
                width={48}
                height={48}
                style={{ backgroundColor: "#0B1022" }}
              />
            </div>
          ) : props.currentChat || props.chatList?.length > 0 ? (
            <Tooltip
              title="Debes finalizar todas tus atenciones abiertas para poder salir"
              placement="bottom"
              // open={props.currentChat}
            >
              <Box>
                <IconButton style={{ color: "white" }}>
                  <img
                    src={SignOutIcon}
                    style={{ width: "32px", marginLeft: 8, height: 32 }}
                    alt="cerrar sesion"
                  />
                </IconButton>
                <Box
                  style={{
                    color: "#FFFFFF",
                    fontSize: 12,
                  }}
                >
                  Salir
                </Box>
              </Box>
            </Tooltip>
          ) : (
            // <p>salir</p>
            <>
              <IconButton
                style={{ color: "white" }}
                disabled={props.isLoading}
                onClick={() => props.openDialogClose(true)}
              >
                <img
                  src={SignOutIcon}
                  style={{ width: "32px", marginLeft: 8, height: 32 }}
                  alt="Cerrar sesion"
                />
              </IconButton>
              <Box
                style={{ color: "#FFFFFF", fontSize: 10, cursor: "pointer" }}
                onClick={() => props.openDialogClose(true)}
              >
                Salir
              </Box>
            </>

            // <IconButton
            //   style={{ color: "white" }}
            //   disabled={props.isLoading}
            //   onClick={() => props.openDialogClose(true)}
            // >
            //   <Box>
            //     <IconButton style={{ color: "white" }}>
            //       <img
            //         src={SignOutIcon}
            //         style={{ width: "32px", marginLeft: 8, height: 32 }}
            //         alt="cerrar sesion"
            //       />
            //     </IconButton>
            //     <Box
            //       style={{
            //         color: "#FFFFFF",
            //         fontSize: 12,
            //       }}
            //     >
            //       Salir
            //     </Box>
            //   </Box>
            // </IconButton>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {props.isOpenSideMenu ? (
        <Drawer
          id="sidebar-vl"
          container={props.container}
          variant="temporary"
          open={props.isOpenSideMenu}
          onClose={() => props.handleDrawerToggle(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: props.drawerwidth,
              backgroundColor: "#050A1B",
            },
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          id="sidebar-vl"
          variant="permanent"
          style={{ display: props.isMobile ? "none" : "block" }}
          sx={{
            // display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: props.drawerwidth,
              backgroundColor: "#050A1B",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
    </>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Sidebar;
