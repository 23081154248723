import LoginForm from "../../components/loginForm";
import Navbar from "../../components/navbar";

const DesktopView = ({ onLogin, isLoading, navigateToRecover }) => {
  return (
    <>
      <Navbar isMobile={false} page={"login"} />
      <LoginForm
        isMobile={false}
        onLogin={onLogin}
        isLoading={isLoading}
        navigateToRecover={navigateToRecover}
      />
    </>
  );
};

export default DesktopView;
