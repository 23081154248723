import LoginForm from "../../components/loginForm";
import Navbar from "../../components/navbar";
import RecoverForm from "../../components/recoverForm";

const MobileView = ({ navigateToLogin, sendEmail, isLoading }) => {
  return (
    <>
      <Navbar isMobile={true} />
      <RecoverForm
        isMobile={true}
        navigateToLogin={navigateToLogin}
        isLoading={isLoading}
        sendEmail={sendEmail}
      />
    </>
  );
};

export default MobileView;
