import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

import { AppRouter } from "./routes/appRouter";
import { AgentProvider } from "./context/provider";
import { documentEventsService } from "./services/documentEvents.service";

function AgentApp() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 1000 ? false : true
  );
  const [width, setWidth] = useState(window.innerWidth);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2D2D2D",
      },
    },
  });

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      if (window.innerWidth > 1000) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
    window.addEventListener("resize", handleResize);

    documentEventsService.watchFocusWindow();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AgentProvider>
          <AppRouter isMobile={isMobile} width={width} />
        </AgentProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default AgentApp;
