import Box from "@mui/material/Box";

import Navbar from "../../components/navbar";
import ProfileForm from "../../components/profileForm";
import ProfileInfo from "../../components/profileInfo";

const MobileView = ({
  state,
  handleSidebar,
  navigate,
  handleAvatar,
  hanleData,
  hanleDataSound,
  loading,
  currentPreview,
}) => {
  return (
    <>
      <Box>
        <Navbar
          isMobile={true}
          page={"profile"}
          currentChat={state.currentChat}
          isLoading={loading}
          isOpenSideMenu={state.isOpen}
          handleDrawerToggle={handleSidebar}
          handleSidebar={handleSidebar}
          navigate={navigate}
        />

        <Box
          style={{
            width: state.isOpen ? `100%` : "100%",
            backgroundColor: "#F4F4F4",
            height: "100vh",
          }}
        >
          <ProfileInfo
            state={state}
            handleAvatar={handleAvatar}
            hanleDataSound={hanleDataSound}
            isMobile={true}
            loading={loading}
            currentPreview={currentPreview}
          />
          <Box
            style={{
              width: "100%",
              backgroundColor: "#FFFFFF",

              padding: 32,
            }}
          >
            <ProfileForm state={state} hanleData={hanleData} isMobile={true} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MobileView;
