import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import backgroundVideoLink from "../assets/backgroundChat2.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

function SkeletonAgent({ toolbarHeight, mobileBreakpoint }) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <div
          style={{
            height: `calc(100vh - ${toolbarHeight}px)`,
            backgroundColor: "#f5f5f5",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: !mobileBreakpoint ? "24px" : "4px",
          }}
        >
          <div
            style={{
              fontWeight: "500",
              color: "#737373",
              marginBottom: "8px",
              display: "flex",
              background: "#FFFFFF",
              minHeight: 80,
              alignItems: "center",
              padding: 8,
            }}
          >
            <div style={{ marginRight: 14 }}>
              <Skeleton variant="circular" width={48} height={48} />
            </div>
            <div style={{ width: "100%" }}>
              <div>
                <Skeleton variant="text" style={{ width: "65%" }} height={42} />
              </div>
              <div>
                <Skeleton variant="text" style={{ width: "30%" }} height={42} />
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              marginBottom: "8px",
              padding: "8px",
              overflowX: "hidden",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "8px",
                width: "100%",
              }}
            >
              <Skeleton variant="text" style={{ width: "70%" }} height={42} />
              <div style={{ display: "flex" }}>
                {[1, 2, 3].map((item) => (
                  <div
                    key={item}
                    style={{
                      textAlign: "center",
                      width: "33.33%",
                      padding: 4,
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton variant="circular" width={52} height={52} />
                    </div>
                    <div>
                      <Skeleton variant="text" height={42} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton
                        variant="text"
                        style={{ width: "70%" }}
                        height={42}
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton
                        variant="text"
                        style={{ width: "70%" }}
                        height={42}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              height: "calc(100vh - 482px)",
              overflowY: "hidden",
              overflowX: "hidden",
              marginTop: 64,
            }}
          >
            {[1, 2, 3, 4, 5].map((item) => (
              <div
                key={item}
                style={{
                  padding: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "8px",
                  width: "100%",
                  background: "#FFFFFF",
                }}
              >
                <div style={{ marginRight: 4 }}>
                  <Skeleton variant="circular" width={52} height={52} />
                </div>
                <div style={{ width: "100%" }}>
                  <div>
                    <Skeleton
                      variant="text"
                      style={{ width: "70%" }}
                      height={24}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="text"
                      style={{ width: "30%" }}
                      height={24}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="text"
                      style={{ width: "30%" }}
                      height={24}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: "auto", width: "30%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginBottom: "4px",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      style={{ width: "100%" }}
                      height={24}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="text"
                      style={{ width: "100%" }}
                      height={24}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <div
          style={{
            height: `calc(100vh - ${toolbarHeight}px)`,
            backgroundImage: `url(${backgroundVideoLink})`,
            position: "relative",
          }}
        >
          <>
            <div
              style={{
                height: "60px",
                backgroundColor: "#EEEDED",
                width: "100%",
                position: "absolute",
                bottom: "0",
                alignItems: "center",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "60px",
                }}
              >
                <div
                  style={{
                    marginRight: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#F4F4F4",
                      borderRadius: "5px",
                      height: "40px",
                    }}
                    disabled={true}
                  ></Button>
                </div>

                <div
                  style={{
                    width: "inherit",
                    marginRight: "8px",
                  }}
                >
                  <FormControl fullWidth disabled={true}>
                    <OutlinedInput
                      style={{
                        height: "40px",
                        borderRadius: "30px",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    style={{ backgroundColor: "#FFFFFF", marginRight: "12px" }}
                    disabled={true}
                  >
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#FFFFFF",
                      }}
                    />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    style={{ backgroundColor: "#FFFFFF" }}
                    disabled={true}
                  >
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#FFFFFF",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <div
          style={{
            height: `calc(100vh - ${toolbarHeight}px)`,
            backgroundColor: "#f5f5f5",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "16px",
          }}
        >
          <div
            style={{
              minHeight: "150px",

              backgroundColor: "#FFFFFF",
              padding: "12px",
              marginBottom: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Skeleton
                variant="circular"
                style={{
                  width: 120,
                  height: 120,
                }}
              />
              <Skeleton
                variant="text"
                style={{
                  marginTop: 10,
                  width: "100%",
                  height: 42,
                }}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: "12px",
              marginBottom: "8px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Skeleton
              variant="text"
              style={{
                width: "70%",
                height: 42,
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: "18px",
              marginBottom: "12px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  width: "70%",
                  height: 52,
                  borderRadius: "25.5px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  width: "70%",
                  height: 52,
                  borderRadius: "25.5px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  width: "70%",
                  height: 52,
                  borderRadius: "25.5px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  width: "70%",
                  height: 52,

                  borderRadius: "25.5px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "25.5px",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  width: "70%",
                  height: 52,
                  borderRadius: "25.5px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "25.5px",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  width: "70%",
                  height: 52,
                  borderRadius: "25.5px",
                  backgroundColor: "rgb(98 94 94)",
                }}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: "12px",
              marginBottom: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderRadius: "25.5px",
            }}
          >
            <Skeleton
              variant="text"
              style={{
                width: "70%",
                height: 52,
                borderRadius: "25.5px",
              }}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default SkeletonAgent;
