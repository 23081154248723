import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { TicketContext } from "../../context";
import "./profile-image.scss";

export const ProfileImage = ({
  src = "",
  type = "normal",
  alt = "image",
  style = { background: "tomato", color: "white" },
  name = "",
}) => {
  const { ticket } = useContext(TicketContext);
  const [state, setState] = useState({ name: "" });
  useEffect(() => {
    setState({
      ...state,
      name,
    });
  }, [ticket]);

  return (
    <div className={`profile__img profile__img--${type}`} style={style}>
      {src.length ? (
        <img className="profile__img__img" src alt={alt} />
      ) : (
        <span className="profile__img__text">
          {state.name
            ?.split(" ")
            ?.map((e) => e[0])
            ?.join("")
            ?.slice(0, 2)
            ?.toLocaleUpperCase()}
        </span>
      )}
    </div>
  );
};
