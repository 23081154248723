import { useState, useEffect, forwardRef, useRef } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AttachmentIcon from "../assets/AttachmentIcon.png";
import TelegramIcon from "@mui/icons-material/Telegram";
import VideocamIcon from "@mui/icons-material/Videocam";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";
import Badge from "@mui/material/Badge";
import { CircularProgress } from "@mui/material";

import { consts } from "../const";
import service from "../service";

const MessagingBar = ({
  currentChat,
  onSendMessage,
  onInitCall,
  onUploadImageMessage,
  user,
  getCurrentFiles,
  cleanInput,
  state,
}) => {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(undefined);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [messageAlert, setMessageAlert] = useState(undefined);
  const [severity, setSeverity] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    setMessage("");
    setFile(undefined);
    if (currentChat?.channel !== "instagram") {
      inputRef.current.value = null;
    }
  }, [cleanInput]);

  return (
    <>
      <div
        style={{
          minHeight: file ? "90px" : "60px",
          backgroundColor: "#EEEDED",
          width: "100%",
          position: "absolute",
          bottom: "0",
          alignItems: "center",
          padding: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            minHeight: "60px",
            alignItems: "center",
          }}
        >
          {file && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "12px",
              }}
            >
              <div
                style={{
                  color: "rgb(234, 52, 95)",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {`Archivo adjuntado`} &nbsp;
              </div>
              <div style={{ fontSize: "8px" }}>
                <IconButton
                  onClick={() => {
                    inputRef.current.value = null;
                    setFile(undefined);
                  }}
                  size="small"
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: "18px",
                    fontSize: "12px",
                    height: "18px",
                  }}
                >
                  X
                </IconButton>
              </div>
            </div>
          )}

          {user?.preferences?.calls && (
            <div
              style={{
                marginRight: "8px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#337FFF",
                  borderRadius: "5px",
                  height: "40px",
                }}
                disabled={!currentChat || isLoading}
                onClick={() => onInitCall()}
              >
                <VideocamIcon style={{ color: "#FFFFFF" }} fontSize="large" />
              </Button>
            </div>
          )}

          <div
            style={{
              width: "inherit",
              marginRight: "8px",
            }}
          >
            {!file && (
              <FormControl fullWidth disabled={!currentChat || isLoading}>
                <OutlinedInput
                  multiline
                  size="small"
                  maxRows={3}
                  inputProps={{ maxLength: 4096 }}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  style={{
                    borderRadius: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                  onKeyUp={(e) => {
                    if (e.code === consts.ENTER_KEY_CODE) {
                      e.preventDefault();

                      if (!currentChat) return false;
                      else if (isLoading) return false;
                      else if (message.trim().length === 0 && !file)
                        return false;
                      else {
                        setIsLoading(true);

                        if (file) {
                          file.map((item) => {
                            onUploadImageMessage(item)
                              .then((res) => {
                                getCurrentFiles(currentChat._id);

                                const formatMessage = service.formatMessage({
                                  message,
                                  isAgent: true,
                                  id: res._id,
                                  name: res.originalname,
                                  agentId: state.user._id,
                                  url: undefined,
                                  urlContentType: item.mimeType,
                                  type: "file",
                                  _id: service.randomStringGenerator(),
                                });
                                onSendMessage(
                                  {
                                    id: res._id,
                                    name: res.originalname,
                                  },
                                  formatMessage
                                );
                                setMessage("");
                                setFile(undefined);
                                inputRef.current.value = null;
                              })
                              .then(() => setIsLoading(false))
                              .catch(() => setIsLoading(false));
                          });
                        } else {
                          const formatMessage = service.formatMessage({
                            message,
                            isAgent: true,
                            id: undefined,
                            name: undefined,
                            agentId: state.user._id,
                          });
                          onSendMessage(message, formatMessage);
                          setMessage("");
                          setIsLoading(false);
                        }
                      }
                    }
                  }}
                  placeholder="Ingrese su mensaje..."
                />
              </FormControl>
            )}
          </div>
          <div style={{ marginLeft: "auto" }}>
            {currentChat?.channel !== "instagram" && (
              <IconButton
                style={{ backgroundColor: "#FFFFFF", marginRight: "12px" }}
                disabled={!currentChat || file || isLoading}
                component="label"
              >
                <Badge
                  style={{ borderRadius: "100px", color: file && "#FFFFFF" }}
                  badgeContent={
                    file && (
                      <p
                        style={{
                          width: 20,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "100px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          backgroundColor: "#EA345F",
                        }}
                      >
                        {file.length}
                      </p>
                    )
                  }
                >
                  <img
                    src={AttachmentIcon}
                    alt="attachment"
                    style={{ width: "24px" }}
                  />
                  <input
                    type="file"
                    ref={inputRef}
                    hidden
                    onChange={onHandleFile}
                    multiple
                  />
                </Badge>
              </IconButton>
            )}
          </div>
          <div>
            <IconButton
              style={{ backgroundColor: "#FFFFFF" }}
              disabled={
                !currentChat ||
                (message === "" && !file) ||
                isLoading ||
                (message.trim().length === 0 && !file)
              }
              onClick={() => {
                setIsLoading(true);
                if (file) {
                  file.map((item) => {
                    onUploadImageMessage(item)
                      .then((res) => {
                        getCurrentFiles(currentChat._id);
                        const formatMessage = service.formatMessage({
                          message,
                          isAgent: true,
                          id: res._id,
                          name: res.originalname,
                          agentId: state.user._id,
                          url: undefined,
                          urlContentType: item.mimeType,
                          type: "file",
                          _id: service.randomStringGenerator(),
                        });
                        onSendMessage(
                          {
                            id: res._id,
                            name: res.originalname,
                          },
                          formatMessage
                        );
                        setMessage("");
                        setFile(undefined);
                        if (currentChat?.channel !== "instagram") {
                          inputRef.current.value = null;
                        }
                      })
                      .then(() => setIsLoading(false))
                      .catch(() => setIsLoading(false));
                  });
                } else {
                  const formatMessage = service.formatMessage({
                    message,
                    isAgent: true,
                    id: undefined,
                    name: undefined,
                    agentId: state.user._id,
                  });
                  onSendMessage(message, formatMessage);
                  setMessage("");
                  setIsLoading(false);
                }
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} style={{ color: "#EA345F" }} />
              ) : (
                <TelegramIcon style={{ color: "#394154" }} />
              )}
            </IconButton>
          </div>

          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={isSnackbarOpen}
            onClose={handleCloseSnackbar}
            TransitionComponent={SlideTransition}
            style={{ display: isSnackbarOpen ? "flex" : "none" }}
          >
            <Alert severity={severity}>{messageAlert}</Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );

  function handleCloseSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  }

  function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  function onHandleFile(event) {
    const files = [];
    Array.from(event.target.files).map((file) => {
      if (
        file.type !== "video/mp4" &&
        file.type !== "image/png" &&
        file.type !== "application/pdf" &&
        file.type !== "image/jpeg" &&
        file.type !== "audio/ogg" &&
        file.type !== "audio/mpeg" &&
        file.type !== "audio/mp3" &&
        file.type !== "video/webm"
      ) {
        setIsSnackbarOpen(true);
        setMessageAlert("Archivo no permitido");
        setSeverity("error");
      } else if (file.size > 10485760) {
        setIsSnackbarOpen(true);
        setMessageAlert("El tamaño del archivo es muy grande");
        setSeverity("error");
      } else if (Array.from(event.target.files).length > 10) {
        setIsSnackbarOpen(true);
        setMessageAlert("El numero maximo de archivos permitidos es 10");
        setSeverity("error");
      } else {
        files.push(file);
      }
    });
    setFile(files.length === 0 ? undefined : files);
  }
};

export default MessagingBar;
