import { useEffect, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import service from "../service";
import Navbar from "../components/navbar";
import { AgentContext } from "../context";
import DialogTransferAtention from "../components/dialogTransferAtention";

import ChangePasswordForm from "../components/changePasswordForm";

const ChangePassword = ({ isMobile }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigateToLogin = () => navigate("/");
  const { state, setConfigDialog } = useContext(AgentContext);

  const verifyToken = async () => {
    setIsLoading(true);
    try {
      const result = await service.verifyToken({ token: params.get("token") });
      if (result !== null) {
        switch (result.code) {
          case "token/expired":
            setConfigDialog({
              open: true,
              type: "err",
            });
            break;
          case "token/used":
            setConfigDialog({
              open: true,
              type: "err",
            });
            break;
          case "token/disable":
            setConfigDialog({
              open: true,
              type: "err",
            });
            break;
          case "token/invalid":
            setConfigDialog({
              open: true,
              type: "err",
            });
            break;
        }
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: changePassword.js ~ line 18 ~ useEffect ~ err",
        err
      );
    }
    setIsLoading(false);
  };

  const changePassword = async (password) => {
    setIsLoading(true);

    try {
      const result = await service.changePassword({
        token: params.get("token"),
        password,
      });
      setConfigDialog({
        open: true,
        type: "updatePassword",
      });
    } catch (err) {
      console.log(
        "🚀 ~ file: changePassword.js ~ line 30 ~ changePassword ~ err",
        err
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {});

  return (
    <>
      <Navbar isMobile={isMobile} page={"login"} />
      <ChangePasswordForm
        isMobile={isMobile}
        navigateToLogin={navigateToLogin}
        isLoading={isLoading}
        changePassword={changePassword}
      />
      <DialogTransferAtention
        open={state.configDialog.open}
        type={state.configDialog.type}
        setOpen={setConfigDialog}
        abilities={[]}
        groups={[]}
        onTransferCall={console.log}
        getCurrentAbilities={console.log}
        currentAbilities={[]}
        title={""}
        onChatEnd={console.log}
        isMobile={isMobile}
        height={100}
        navigate={navigateToLogin}
      />
    </>
  );
};

export default ChangePassword;
