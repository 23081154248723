import { environment } from "../config";
import axiosInstance from "./../api";

class ApiSrc {
  get(path, params) {
    return axiosInstance.get(`${environment.server}${path}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
  }

  put(path, body = {}) {
    return axiosInstance.put(
      `${environment.server}${path}`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
  }

  post(path, body = {}) {
    return axiosInstance.post(
      `${environment.server}${path}`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
  }

  delete(path) {
    return axiosInstance.delete(`${environment.server}${path}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
  }
}

export const apiSrc = new ApiSrc();
