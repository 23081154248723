import "./ticket-list.scss";
import refreshIcon from "../../assets/icons/refresh.svg";
import { CustomSelect } from "../custom-seelct/custom-select";
import { TicketListPaginator } from "../ticket-paginator/ticket-list-paginator";
import { useState } from "react";
import { useEffect } from "react";
import { TicketContext } from "../../context";
import { useContext } from "react";
import service from "../../service";
import diffInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import es from "date-fns/locale/es";
/**
 *
 * @param {{
 * _id:string,
 * name:string,
 * number:number,
 * summary:string,
 * description:string,
 * status: 'open' | 'pending' | 'process' |'done',
 * priority:boolean,
 * date:string,
 * isTransfer:boolean,
 * }} param0
 */
const Ticket = ({ data, onClick = () => {} }) => {
  const {
    _id,
    client,
    number,
    summary,
    description,
    // status,
    // priority,
    createdAt,
    // isTransfer,
  } = data;

  const formatDate = (date) => {
    const diff = diffInDays(new Date(), new Date(date));
    if (diff === 0) {
      return "Hoy";
    } else if (diff === 1) {
      return "Ayer";
    } else {
      return format(new Date(date), "MMM dd", { locale: es });
    }
  };
  return (
    <div className="ticket-card" id={_id} onClick={() => onClick(data)}>
      <div className="ticket-card__header">
        <span className="ticket-card__header__name">{client.name}</span>
        <span className="ticket-card__header__date">
          {formatDate(createdAt)}
        </span>
      </div>
      <div className="ticket-card__body">
        <span className="ticket-card__body__number">Ticket #: {number}</span>
        <span className="ticket-card__body__transfer"></span>
      </div>
      <div className="ticket-card__footer">
        <span className="ticket-card__footer__summary">{summary}</span>
        <span className="ticket-card__footer__description">{description}</span>
      </div>
    </div>
  );
};

const tickets = [];

export const TicketSearch = ({ refresh, search }) => {
  return (
    <>
      <div className="row">
        <div
          className="ticket-search"
          onKeyPress={(event) => {
            if (event.key === "Enter") search(event.target.value);
          }}
        >
          <input className="ticket-search__input" type="text" />
        </div>
        <button className="ticket-search__refresh" onClick={refresh}>
          <img src={refreshIcon} alt="refresh" />
        </button>
      </div>
    </>
  );
};

export const TicketFilter = ({ changeValue = () => {} }) => {
  const [state, setState] = useState({
    options: [],
  });
  async function getData() {
    try {
      const result = await service.getTicketStatuses();
      const options = result.data?.ticketStatuses.map((e) => ({
        value: e._id,
        icon: e.icon,
        text: e.name,
      }));
      options.unshift({ value: "", icon: "", text: "Todos" });
      options.push(
        { value: "act-priority", icon: "", text: "Prioridad" },
        { value: "act-transfer", icon: "", text: "Transferidos" },
        { value: "act-toExpired", icon: "", text: "Por vencer" },
        { value: "act-expired", icon: "", text: "Vencidos" },
        { value: "act-close", icon: "", text: "Cerrados" }
      );

      setState({
        options: options,
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  const onChangeValue = (value) => {
    changeValue(value);
  };

  return (
    <>
      <div className="row">
        <div className="ticket-filter">
          <CustomSelect options={state.options} changeValue={onChangeValue} />
        </div>
      </div>
    </>
  );
};

export const TicketList = () => {
  const { setTicket } = useContext(TicketContext);

  const [state, setState] = useState({
    tickets: [],
  });

  const step = 10;
  async function getData(config) {
    const x = await service.getTickets(config);
    return x;
  }

  const paginate = async (config) => {
    const { data } = await getData(config);
    setState({
      ...state,
      tickets: data.tickets,
    });
  };

  useEffect(() => {
    paginate({ limit: step, page: 1 });
  }, []);

  /**
   *
   * @param {string} searchString
   */
  const search = (searchString) => {
    paginate({ limit: step, page: 1, search: searchString });
  };

  const filterByStatus = (statusId) => {
    const query = {
      limit: step,
      page: 1,
    };
    if (statusId.includes("act")) query.action = statusId.replace("act-", "");
    else query.status = statusId.length > 0 ? statusId : null;
    paginate(query);
  };

  const onClickTicket = (ticket) => {
    setTicket(ticket);
  };

  return (
    <>
      <div className="ticket-list">
        <TicketListPaginator
          config={{ step, total: tickets.length }}
          changeValue={paginate}
        />
        <TicketSearch refresh={() => paginate()} search={search} />
        <TicketFilter changeValue={filterByStatus} />
        {state.tickets.length ? (
          state.tickets.map((ticket, index) => (
            <Ticket data={ticket} key={index} onClick={onClickTicket} />
          ))
        ) : (
          <div className="ticket-list__empty">No hay tickets</div>
        )}
      </div>
    </>
  );
};
