import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
    import { AgentContext } from "../context";
import DialogClose from "./dialogClose";
import Sidebar from "./sidebar";

/**
 *
 * @param {{config:{page:string}}} param0
 */

const Layout = ({ config, children }) => {
  const {
    state,
    handleSidebar,
    onLogout,
    connect,
    handleAgentConnect,
    handleDialogClose,
  } = useContext(AgentContext);
  const navigate = useNavigate();
  if (!state.user) return <Navigate to="/" />;
  const { page } = config;

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <>
      <Sidebar
        typeConnection={""}
        drawerwidth={state.isOpen ? 240 : 80}
        handleDrawerToggle={handleSidebar}
        container={container}
        isOpenSideMenu={state.isOpen}
        onHandlePage={(pathname) => navigate(pathname)}
        onLogout={onLogout}
        page={page}
        user={state.user}
        openDialogClose={handleDialogClose}
        groups={state.groups}
        abilities={state.abilities}
        connect={connect}
        handleAgentConnect={handleAgentConnect}
        currentChat={state.currentChat}
        queue={state.queue}
        chatList={state.chatList}
      />
      <DialogClose
        open={state.isOpenDialog}
        setOpen={handleDialogClose}
        onAccept={onLogout}
      />
      {children}
    </>
  );
};

export default Layout;
