import LoginForm from "../../components/loginForm";
import Navbar from "../../components/navbar";

const MobileView = ({ onLogin, isLoading, navigateToRecover }) => {
  return (
    <>
      <Navbar isMobile={true} />
      <LoginForm
        isMobile={true}
        onLogin={onLogin}
        isLoading={isLoading}
        navigateToRecover={navigateToRecover}
      />
    </>
  );
};

export default MobileView;
