import { ProfileImage } from "../profile-image/profile-image";
import formatDate from "date-fns/format";
import "./profile-header.scss";

function ProfileHeader({
  ticket: {
    name = "",
    description = "",
    createdAt = new Date(),
    number = false,
  },
}) {
  return (
    <div className="profile-header">
      <ProfileImage name={name} />
      <div className="profile-header-info">
        <div className="profile-header-info__title">
          <span className="profile-header-info__title__name">{name}</span>
          <span className="profile-header-info__title__date">
            {formatDate(new Date(createdAt), "dd/MM/yyyy - HH:mm a")}
          </span>
        </div>
        <div className="profile-header-info__description">
          <span className="profile-header-info__description__text">
            Requerimiento: {description}
          </span>
        </div>
      </div>
      {number && (
        <div className="profile-header__number">
          <span className="profile-header__number__text">
            Ticket #:{number}
          </span>
        </div>
      )}
    </div>
  );
}

export default ProfileHeader;
