import LoginForm from "../../components/loginForm";
import Navbar from "../../components/navbar";
import RecoverForm from "../../components/recoverForm";

const DesktopView = ({ navigateToLogin, sendEmail, isLoading }) => {
  return (
    <>
      <Navbar isMobile={false} page={"login"} />
      <RecoverForm
        isMobile={false}
        navigateToLogin={navigateToLogin}
        isLoading={isLoading}
        sendEmail={sendEmail}
      />
    </>
  );
};

export default DesktopView;
