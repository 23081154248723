import { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import logo from "../assets/icono.png";
import companyLogoSmall from "../assets/svg/omniflow-small.svg";
import { consts } from "../const";

const RecoverForm = ({
  isMobile,
  onLogin,
  isLoading,
  navigateToLogin,
  sendEmail,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const regexForEmail =
    /^[A-Za-z0-9_]+(\.[_A-Za-z0-9_]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/;
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 77px)",
        }}
      >
        <Box
          style={{
            padding: "24px",
            boxShadow: !isMobile && "0px 1px 6px rgba(0, 0, 0, 0.15)",
            minHeight: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // minWidth: 500,
          }}
        >
          <Box
            style={{
              marginTop: "8px",
              display: "flex",
              alignItems: "center",
              marginBottom: "32px",
              justifyContent: "center",
            }}
          >
            <img
              src={companyLogoSmall}
              style={{
                width: isMobile ? "45px" : "45px",
                heigth: isMobile ? "45px" : "45px",
              }}
              alt="logo"
            />
          </Box>
          <Box
            style={{
              fontSize: isMobile ? 20 : "15px",
              fontWeight: "bold",
              marginBottom: "24px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Recupera tu cuenta
          </Box>
          <Box
            style={{
              fontSize: isMobile ? 12 : "12px",
              //   fontWeight: "bold",
              marginBottom: "24px",
              display: "flex",
              justifyContent: "center",

              textAlign: "center",
            }}
          >
            Introduce tu correo electrónico o número de móvil para buscar tu
            cuenta.
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: isMobile ? 66 : "24px",
              // marginBottom: "24px",
            }}
          >
            <FormControl
              fullWidth
              style={{ marginBottom: "6px", width: "100%" }}
              sx={{ width: { xs: "300px", sm: "430px" } }}
            >
              <OutlinedInput
                fullWidth
                size={"small"}
                onKeyUp={(e) => {
                  if (e.code === consts.ENTER_KEY_CODE) {
                    if (email && password && regexForEmail.test(email)) {
                      onLogin(email, password);
                    }
                  }
                }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#F3F6F9",
                }}
                placeholder="Correo"
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon style={{ color: "#A3A3A3" }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          {!regexForEmail.test(email) && email !== "" && (
            <Box
              style={{
                position: "absolute",
                paddingLeft: "12px",
                fontSize: "12px",
                marginTop: 110,
                // marginBottom: "6px",
                color: "red",
              }}
            >
              Formato no valido
            </Box>
          )}

          <Box style={{ textAlign: "center" }}>
            <Button
              size={isMobile ? "small" : "medium"}
              variant="contained"
              disabled={
                !email || !regexForEmail.test(email) || isLoading ? true : false
              }
              style={{
                fontSize: isMobile && 16,
                maxWidth: isMobile && 300,
                backgroundColor:
                  !email || !regexForEmail.test(email) || isLoading
                    ? "#C5C5C5"
                    : "#EA345F",
                marginBottom: isMobile ? 20 : "32px",
                borderRadius: "25.5px",
                // width: "350px",
                textTransform: "capitalize",
                color: (!email || !regexForEmail.test(email)) && "#FFFFFF",
              }}
              sx={{ width: { xs: "250px", sm: "350px" } }}
              onClick={() =>
                sendEmail({
                  callback: `http://${window.location.host}/change_password`,
                  email: email.toLowerCase(),
                  type: "reset/password",
                })
              }
            >
              {isLoading ? (
                <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
              ) : (
                "Enviar"
              )}
            </Button>
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Button
              onClick={navigateToLogin}
              style={{
                color: "#FF3160",
                textTransform: "initial",
                fontSize: 14,
              }}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RecoverForm;
