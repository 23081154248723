import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { TicketContext } from "../../context";
import service from "../../service";
import { CustomSelect } from "../custom-seelct/custom-select";
import "./ticket-actions.scss";

function TicketActions() {
  const {
    ticket,
    setStatus,
    status: statusContext,
  } = useContext(TicketContext);
  const statuses = [
    { value: "open", text: "Abierto" },
    { value: "pending", text: "Pendiente" },
    { value: "process", text: "En proceso" },
    { value: "done", text: "Terminado" },
  ];

  const [state, setState] = useState({
    statuses: [],
    currentStatus: "",
  });

  async function getData() {
    try {
      const result = await service.getTicketStatuses();
      const options = result.data?.ticketStatuses.map((e) => ({
        value: e._id,
        icon: e.icon,
        text: e.name,
      }));
      setState({
        ...state,
        statuses: options,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const changeStatus = async () => {
    try {
      const status = state.status;
      const result = await service.updateTicketStatus(ticket._id, status);
      console.log({ "ticket updated": result });
      setStatus({
        ...statusContext,
      });
      console.log({ status, statusContext });
    } catch (error) {
      // console.log(error);
      console.log({ "ticket updated": error });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeStatus = async (value) => {
    setState({
      ...state,
      currentStatus: value,
    });
  };

  const abilities = [
    { value: "1", text: "Habilidad 1" },
    { value: "2", text: "Habilidad 2" },
    { value: "3", text: "Habilidad 3" },
    { value: "4", text: "Habilidad 4" },
  ];

  const onChangeAbilities = (value) => {
    console.log(value);
  };

  const changeAbilities = () => {
    console.log("change Abilities");
  };
  return (
    <div className="ticket-actions">
      <div className="ticket-actions__header">
        <h3 className="ticket-actions__header__title">
          Acciones sobre el ticket
        </h3>
      </div>
      <div className="ticket-actions__body">
        <div className="ticket-actions__field">
          <div className="ticket-actions__field__title">Estatus</div>
          <CustomSelect
            className="ticket-actions__field__select"
            options={state.statuses}
            changeValue={onChangeStatus}
            style={{ outline: "1px solid #DADADA", padding: "5px" }}
          ></CustomSelect>
          <button
            className="ticket-actions__field__button"
            onClick={(e) => changeStatus()}
          >
            Cambiar
          </button>
        </div>
        <div className="ticket-actions__body__title">Transferir</div>
        <div className="ticket-actions__field">
          <div className="ticket-actions__field__title">Grupo</div>
          <CustomSelect
            className="ticket-actions__field__select"
            options={abilities}
            changeValue={onChangeAbilities}
            style={{ outline: "1px solid #DADADA", padding: "5px" }}
          ></CustomSelect>
        </div>
        <div className="ticket-actions__field">
          <div className="ticket-actions__field__title">
            Tipo de atenci&oacute;n
          </div>
          <CustomSelect
            className="ticket-actions__field__select"
            options={abilities}
            changeValue={onChangeAbilities}
            style={{ outline: "1px solid #DADADA", padding: "5px" }}
          ></CustomSelect>
          <button
            className="ticket-actions__field__button"
            onClick={changeStatus}
          >
            Transferir
          </button>
        </div>
      </div>
    </div>
  );
}

export default TicketActions;
