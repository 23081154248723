import React from "react";
import ReactDOM from "react-dom/client";

import AgentApp from "./AgentApp";

import "./index.css";
import "./index.scss";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AgentApp />);
