import { ReactComponent as ArrowDownload } from "../../assets/icons/file-preview-arrow.svg";
import { ReactComponent as AttachFileSVG } from "../../assets/icons/attach_file.svg";
import "./file-preview.scss";

function FilePreview({ file: { name = "", url = "", preview = "" } = {} }) {
  const openLink = () => window.open(url, "_blank");
  return (
    <div className="file-preview">
      <div
        className="file-preview__preview"
        onClick={openLink}
        style={{ backgroundImage: `url("${preview}")` }}
      >
        <ArrowDownload></ArrowDownload>
      </div>
      <div className="file-preview__footer">
        <AttachFileSVG style={{ transform: "scale(.6)" }}></AttachFileSVG>
        <span>{name}</span>
      </div>
    </div>
  );
}

export default FilePreview;
