import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { environment } from "../../config";
import { TicketContext } from "../../context";
import service from "../../service";
import FilePreview from "../file-preview/file-preview";
import "./file-preview-list.scss";

function FilePreviewList({ files }) {
  const { ticket } = useContext(TicketContext);
  const [state, setState] = useState({ files: [] });

  const getData = async () => {
    const result = await service.getTicketFiles(files);
    setState({
      files: result.data?.files.map((e) => ({
        name: e.originalname,
        url: `${environment.storage}/tk/${e._id}`,
        preview: e.thumbnail
          ? `${environment.storage}/tk/${e.thumbnail._id}`
          : undefined,
      })),
    });
  };

  useEffect(() => {
    getData();
  }, [ticket]);

  return (
    <div className="filePreviewList">
      {Object.keys(state.files).map((key, index) => {
        return <FilePreview key={index} file={state.files[key]}></FilePreview>;
      })}
    </div>
  );
}

export default FilePreviewList;
