import { apiSrc } from "./api.service";
import { BehaviorSubject } from "rxjs";

class ContactSrc {
  contacts = new BehaviorSubject([]);

  constructor() {
    this.getAllContacts = this.getAllContacts.bind(this);
    this.addContact = this.addContact.bind(this);
    this.mergeContact = this.mergeContact.bind(this);
    this.joinThisChatToContact = this.joinThisChatToContact.bind(this);
  }

  async getAllContacts() {
    const response = await apiSrc.get("/a/v3/contacts");
    this.contacts.next(response?.data?.contacts ?? []);
    return response?.data?.contacts ?? [];
  }

  async addContact(contact) {
    return await apiSrc.post("/a/v3/contacts", contact);
  }

  async updateContact(contactId, data) {
    return await apiSrc.put(`/a/v3/contacts/${contactId}`, data);
  }

  async mergeContact(contactOld, contactNew) {
    return await apiSrc.post("/a/v3/contacts/merge", {
      contactOld,
      contactNew,
    });
  }

  async joinThisChatToContact(contactId, chatId) {
    await apiSrc.post("/a/v3/contacts/joinChat", {
      contactId,
      chatId,
    });
    await this.getAllContacts();
  }

  async getChatList() {
    return await apiSrc.get("/v2/chat/1/progress");
  }

  async checkExist({ email, number, code }) {
    const payload = {};

    if (email) payload.email = email;
    if (number) {
      payload.code = code;
      payload.number = number;
    }

    return await apiSrc.post("/a/v3/contacts/checkExist", payload);
  }
  async checkChatContact({ contactId, currentChatId }) {
    const payload = { contactId, currentChatId };
    return await apiSrc.post("/a/v3/contacts/checkChatContact", payload);
  }

  async searchContact(contact) {
    const payload = { ...contact };
    return await apiSrc.post("/a/v3/contacts/searchContact", payload);
  }
  async mergeContacts(contact) {
    const payload = { ...contact };
    return await apiSrc.post("/a/v3/contacts/mergeContacts", payload);
  }
}

export const contactSrc = new ContactSrc();
