import { useEffect, useState } from "react";
import "./listDocument.scss";
import { environment } from "../../config";

export const ListDocument = ({ state }) => {
  const [listFiles, setListFiles] = useState(
    state.messages
      .filter(({ type }) => type === "file")
      ?.sort((a, b) => (a.date < b.date ? 1 : -1)) ?? []
  );

  useEffect(() => {
    setListFiles(
      state.messages
        .filter(({ type }) => type === "file")
        ?.sort((a, b) => (a.date < b.date ? 1 : -1)) ?? []
    );
  }, [state]);

  return (
    <div className="list-document">
      <p className="list-document__title">Historial de archivos</p>
      <ul className="list-document__list">
        {listFiles.length > 0 ? (
          listFiles.map(({ _id, content, date }) => {
            const fileName = content?.name ?? "Archivo";
            const fileDate = new Intl.DateTimeFormat("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(date));

            const type =
              content?.urlContentType ??
              fileName.split(/\./)?.pop()?.toLocaleUpperCase() ??
              "Archivo";

            return (
              <li className="list-document__list__item" key={_id}>
                <a
                  className="list-document__list__item__file"
                  href={
                    content.id
                      ? `${environment.storage}/c/${content.id}`
                      : content.url
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="list-document__list__item__file__primary">
                    <div className="list-document__list__item__file__primary__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="currentColor"
                      >
                        <path d="M440-200h80v-167l64 64 56-57-160-160-160 160 57 56 63-63v167ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" />
                      </svg>
                    </div>
                    <p className="list-document__list__item__file__primary__name">
                      {fileName}
                    </p>
                    <div className="list-document__list__item__file__primary__download">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="currentColor"
                      >
                        <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="list-document__list__item__file__extra">
                    <div className="list-document__list__item__file__extra__type">
                      {type}
                    </div>
                    <div className="list-document__list__item__file__extra__date">
                      {fileDate}
                    </div>
                  </div>
                </a>
              </li>
            );
          })
        ) : (
          <li className="list-document__list__item">
            <p className="list-document__list__item__text">
              No se encontraron archivos
            </p>
          </li>
        )}
      </ul>
    </div>
  );
};

// {state.messages.filter(
//     (message) =>
//       message?.content?.id || message?.content?.urlContentType
//   ).length > 0 && (
//     <Box
//       style={{
//         marginLeft: "12px",
//         color: "#000000",
//         marginBottom: "4px",
//       }}
//     >
//       Documentos - PDF - Fotos
//     </Box>
//   )}
//   <Box>
//     {state.messages.filter(
//       (message) =>
//         message?.content?.id || message?.content?.urlContentType
//     ).length > 0 && (
//       <Box
//         style={{
//           background: "#FFFFFF",
//           paddingLeft: "12px",
//           paddingRight: "8px",
//           paddingTop: "8px",
//           paddingBottom: "8px",
//           marginBottom: "8px",
//         }}
//       >
//         {state.messages
//           .filter(
//             (message) =>
//               message?.content?.id ||
//               message?.content?.urlContentType
//           )
//           .map((filterMessage, index) => (
//             <Box key={index}>
//               <Box
//                 style={{
//                   borderRadius: "36px",
//                   border: "1px solid #C4C4C4",
//                   boxSizing: "border-box",
//                   padding: "8px",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box style={{ marginRight: "8px" }}>
//                   <a
//                     href={
//                       filterMessage.content.id
//                         ? ` ${environment.storage}/c/${filterMessage.content.id}`
//                         : filterMessage.content.url
//                     }
//                     target="_blank"
//                     style={{ textDecoration: "none" }}
//                   >
//                     <IconButton>
//                       <img
//                         src={AttachmentIcon}
//                         alt="attachment"
//                         style={{ width: "24px" }}
//                       />{" "}
//                     </IconButton>
//                   </a>
//                 </Box>
//                 <Box
//                   style={{
//                     whiteSpace: "nowrap",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                   }}
//                 >
//                   {state.currentChat.channel === "web" ||
//                   state.currentChat.channel === "web-widget"
//                     ? filterMessage.content.name
//                     : state.currentChat.channel === "facebook"
//                     ? "Archivo de facebook"
//                     : state.currentChat.channel === "whatsapp"
//                     ? "Archivo de whatsapp"
//                     : state.currentChat.channel === "instagram" &&
//                       "Archivo de instagram"}
//                 </Box>
//                 <Box style={{ marginLeft: "auto" }}>
//                   <a
//                     href={
//                       filterMessage.content.id
//                         ? ` ${environment.storage}/c/${filterMessage.content.id}`
//                         : filterMessage.content.url
//                     }
//                     target="_blank"
//                     style={{ textDecoration: "none" }}
//                   >
//                     <IconButton>
//                       <DownloadIcon />
//                     </IconButton>
//                   </a>
//                 </Box>
//               </Box>
//               <Box
//                 style={{
//                   padding: "8px",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box
//                   style={{
//                     fontSize: "12px",
//                     marginRight: "12px",
//                     textTransform: "uppercase",
//                   }}
//                 >
//                   {filterMessage.content.id
//                     ? filterMessage.content.name.split(".")[
//                         filterMessage.content.name.split(".")
//                           .length - 1
//                       ]
//                     : ""}
//                 </Box>
//                 <Box style={{ fontSize: "12px" }}>
//                   {filterMessage.content.id
//                     ? `${parseFloat(
//                         state.currentFiles?.find(
//                           (file) =>
//                             file._id === filterMessage.content.id
//                         )?.size / 1024
//                       ).toFixed(2)} KB`
//                     : ""}
//                 </Box>
//                 <Box
//                   style={{ fontSize: "12px", marginLeft: "auto" }}
//                 >
//                   {new DateObject(
//                     new Date(filterMessage.date)
//                   ).format("hh:mm a")}
//                 </Box>
//               </Box>
//             </Box>
//           ))}
//       </Box>
//     )}
//   </Box>
