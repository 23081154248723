import { useContext, useState } from "react";
import { ReactComponent as AttachFile } from "../../assets/icons/attach_file.svg";
import { ReactComponent as RemoveFile } from "../../assets/icons/circle-close.svg";
import { TicketContext } from "../../context";
import service from "../../service";
import "./ticket-details-input.scss";

export const TicketInput = () => {
  const { ticket, setTicket } = useContext(TicketContext);
  const [state, setState] = useState({
    message: "",
    files: [],
    text: "",
  });

  const onSubmit = async () => {
    if (state.text.trim() === "") return;
    const filesPromises = state.files.map((file) =>
      service.uploadTicketFileMessage(file)
    );
    const files = await Promise.all(filesPromises);
    const filesIds = files.map((e) => e.file);
    await service.sendMessage(ticket.id, state.message, filesIds);
    setState({ message: "", files: [], text: "" });
    document.getElementById("ticket-input__content").innerHTML = "";
    const ticketUpdated = await service.getTicket(ticket._id);
    setTicket(ticketUpdated.data?.ticket);
  };

  const onChangeMessage = (e) => {
    setState({
      ...state,
      message: e.currentTarget.innerHTML,
      text: e.currentTarget.innerText,
    });
  };
  const changeFile = (e) => {
    setState({
      ...state,
      files: Array.from(e.target.files),
    });
  };

  const removeFile = (index) => {
    const files = [...state.files];
    files.splice(index, 1);
    setState({
      ...state,
      files,
    });
  };

  return (
    <div className="ticket-input">
      <div className="ticket-input__attach">
        <div className="ticket-input__chip__container">
          {state.files.map((file, index) => {
            return (
              <div className="ticket-input__chip" key={index}>
                <span className="ticket-input__chip__name">{file.name}</span>
                <span
                  className="ticket-input__chip__remove"
                  onClick={(e) => removeFile(index)}
                >
                  <RemoveFile />
                </span>
              </div>
            );
          })}
        </div>

        <label htmlFor="input-file" className="ticket-input__attach__icon">
          <AttachFile />
          <input
            type="file"
            id="input-file"
            className="ticket-input__attach__input"
            multiple={true}
            accept="image/*,audio/*,application/pdf"
            onChange={changeFile}
          />
        </label>
      </div>
      <div className="ticket-input__client">Para: {ticket?.client?.name}</div>
      <div
        className="ticket-input__content"
        contentEditable="true"
        id="ticket-input__content"
        placeholder="Respuesta del ticket"
        style={{ whiteSpace: "pre" }}
        onInput={onChangeMessage}
      ></div>
      <div className="ticket-input__actions">
        <button className="ticket-input__actions__button" onClick={onSubmit}>
          Enviar
        </button>
      </div>
    </div>
  );
};
