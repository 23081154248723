import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import MobileView from "../views/recoverPassword/mobile";
import DesktopView from "../views/recoverPassword/desktop";

import service from "../service";
import DialogTransferAtention from "../components/dialogTransferAtention";
import { AgentContext } from "../context";

const RecoverPassword = ({ isMobile }) => {
  const navigate = useNavigate();
  const { state, setConfigDialog } = useContext(AgentContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigateToLogin = () => navigate("/");

  const sendEmail = async (data) => {
    setIsLoading(true);
    try {
      const result = await service.sendEmailForChangePassword(data);
      setConfigDialog({
        open: true,
        type: "msg",
      });
    } catch (err) {
      console.log(
        "🚀 ~ file: recoverPassword.js ~ line 17 ~ sendEmail ~ err",
        err
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      {isMobile ? (
        <MobileView
          navigateToLogin={navigateToLogin}
          sendEmail={sendEmail}
          isLoading={isLoading}
        />
      ) : (
        <DesktopView
          navigateToLogin={navigateToLogin}
          sendEmail={sendEmail}
          isLoading={isLoading}
        />
      )}
      <DialogTransferAtention
        open={state.configDialog.open}
        type={state.configDialog.type}
        setOpen={setConfigDialog}
        abilities={[]}
        groups={[]}
        onTransferCall={console.log}
        getCurrentAbilities={console.log}
        currentAbilities={[]}
        title={""}
        onChatEnd={console.log}
        isMobile={isMobile}
        height={100}
      />
    </>
  );
};

export default RecoverPassword;
