import { useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import transferMessage from "../assets/transferMessage.svg";
import closeMobile from "../assets/closeMobile.svg";

import reportMessage from "../assets/ReportMessage.svg";
import finishMessage from "../assets/finishMessage.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, TextField } from "@mui/material";

import { styled } from "@mui/material/styles";

function DialogTransferAtention({
  open,
  setOpen,
  groups,
  abilities,
  onTransferCall,
  getCurrentAbilities,
  currentAbilities,
  title,
  type,
  onChatEnd,
  isMobile,
  height,
  navigate,
  updatePassword,
}) {
  const [group, setGroup] = useState(0);
  const [abilitie, setAbilitie] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSucess, setShowSucess] = useState(false);
  const [finishChat, setFinishChat] = useState("success");
  const [reportChat, setReportChat] = useState("spam");
  const [msgFinish, setMsgFinish] = useState("");

  const CustomSelect = styled(Select)(({ isMobile }) => ({
    "& .MuiSelect-outlined": {
      backgroundColor: "#E6E7F3",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
      borderRadius: 12,
      padding: 0,
    },
    "& . css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
      borderRadius: 12,
    },

    "& .MuiSelect-select": {
      borderRadius: 12,
      padding: isMobile && 8,
      boxSizing: "border-box",
      height: isMobile && 40,
    },
    "& .MuiInputBase-input": {
      "&:focus": {
        borderColor: "#FFFFFF",
      },
      "&:hover": {
        borderColor: "#FFFFFF",
      },
    },
  }));

  const getImage = () => {
    switch (type) {
      case "finish":
        return finishMessage;
      case "transfer":
        return transferMessage;
      case "update":
        return finishMessage;
      case "err":
        return reportMessage;
      case "msg":
        return finishMessage;
      case "updatePassword":
        return finishMessage;

      default:
        return reportMessage;
    }
  };

  const getText = () => {
    switch (type) {
      case "finish":
        return "Atención finalizada";
      case "transfer":
        return "Atención transferida";
      case "update":
        return "¡Datos actualizados!";
      case "updatePassword":
        return "¡Datos actualizados!";

      case "err":
        return "¡Enlace no disponible!";
      case "msg":
        return "¡Revise su bandeja de entrada!";

      default:
        return "Atención reportada";
    }
  };

  useEffect(() => {
    if (isMobile) {
      if (type === "update") setShowSucess(true);
    }
  }, [type]);

  const SuccessMessage = (props) => {
    return (
      <Box
        style={{
          height: 230,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <img src={getImage()} style={{ width: 150 }} />
        </Box>
        <Box style={{ paddingTop: 24, fontSize: 20, textAlign: "center" }}>
          {getText()}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      PaperProps={{
        style: {
          borderTopLeftRadius: 10,
          height: isMobile
            ? type === "transfer"
              ? height < 700
                ? "60%"
                : height > 1000
                ? "40%"
                : "50%"
              : height < 700
              ? "60%"
              : height > 1000
              ? "32%"
              : "40%"
            : "",
          position: "fixed",
          bottom: !showSucess && isMobile && 0,
          width: isMobile
            ? !showSucess
              ? "100%"
              : height < 600
              ? "90%"
              : "80%"
            : "",
          borderTopRightRadius: 10,
          borderBottomLeftRadius: !showSucess && isMobile ? 0 : 12,
          borderBottomRightRadius: !showSucess && isMobile ? 0 : 12,
        },
      }}
      onClose={() => {
        setOpen({ open: false, type: undefined });
        setMsgFinish("");
        if (showSucess) {
          setShowSucess(false);
        }
        setGroup(0);
        setAbilitie(0);
        if (type === "err") navigate();
      }}
      maxWidth="xl"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: 0,
          paddingTop: 4,
          paddingRight: !isMobile && 8,
          minHeight: 60,
        }}
      >
        {isMobile ? (
          !showSucess ? (
            <IconButton
              id="close=btn-dialog"
              style={{ margin: "auto" }}
              onClick={() => {
                setOpen({ open: false, type: undefined });
                setMsgFinish("");
                if (showSucess) {
                  setShowSucess(false);
                }
                setGroup(0);
                setAbilitie(0);
                if (type === "err") navigate();
              }}
            >
              <img src={closeMobile} alt="" />
            </IconButton>
          ) : (
            <IconButton
              id="close=btn-dialog"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                setOpen({ open: false, type: undefined });
                setMsgFinish("");
                if (showSucess) {
                  setShowSucess(false);
                }
                setGroup(0);
                setAbilitie(0);
                if (type === "err") navigate();
              }}
            >
              <CloseIcon
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  borderRadius: "100%",
                  color: "#FFFFFF",
                  padding: "4px",
                }}
              />
            </IconButton>
          )
        ) : (
          <IconButton
            id="close=btn-dialog"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setOpen({ open: false, type: undefined });
              setMsgFinish("");
              if (showSucess) {
                setShowSucess(false);
              }
              setGroup(0);
              setAbilitie(0);
              if (type === "err") navigate();
            }}
          >
            <CloseIcon
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                borderRadius: "100%",
                color: "#FFFFFF",
                padding: "4px",
              }}
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        style={{
          width: !isMobile && 500,
          maxWidth: !isMobile && 500,
          minHeight: isMobile ? "" : !showSucess ? 250 : 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {showSucess ? (
          <Box>
            <SuccessMessage />
          </Box>
        ) : (
          <>
            <Box
              style={{
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center",
                color: "#17191E",
                marginBottom: 12,
              }}
            >
              {title}
            </Box>
            {type === undefined && <></>}
            {type === "update" && <SuccessMessage />}

            {type === "updatePassword" && <SuccessMessage />}
            {type === "err" && <SuccessMessage />}
            {type === "msg" && <SuccessMessage />}

            {type === "transfer" && (
              <>
                <FormControl fullWidth style={{ marginBottom: "12.28px" }}>
                  <CustomSelect
                    onChange={(event) => {
                      setIsLoading(true);
                      setGroup(event.target.value);
                      getCurrentAbilities(event.target.value);
                      setAbilitie(0);
                      setIsLoading(false);
                    }}
                    value={group}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={0} disabled>
                      Grupos
                    </MenuItem>

                    {groups?.map((group, index) => (
                      <MenuItem key={index} value={group._id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "12.28px" }}>
                  <CustomSelect
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(event) => setAbilitie(event.target.value)}
                    value={abilitie}
                  >
                    <MenuItem value={0} disabled>
                      {"Habilidades"}
                    </MenuItem>
                    {currentAbilities?.length === 0 && (
                      <MenuItem value={""}>{"No disponible"}</MenuItem>
                    )}
                    {group &&
                      currentAbilities?.map(
                        (abilitie, index) =>
                          groups
                            ?.find((item) => item._id === group)
                            .abilities.includes(abilitie._id) && (
                            <MenuItem value={abilitie._id} key={index}>
                              {abilitie.name}
                            </MenuItem>
                          )
                      )}
                  </CustomSelect>
                </FormControl>
                <TextField
                  fullWidth
                  id="multilineInput"
                  multiline
                  inputProps={{ maxLength: 100 }}
                  value={msgFinish}
                  onChange={(e) => {
                    if (msgFinish.length <= 256) {
                      setMsgFinish(e.target.value);
                    }
                  }}
                  rows={
                    isMobile
                      ? type === "transfer"
                        ? 2
                        : 2
                      : type === "transfer"
                      ? 2
                      : 3
                  }
                  style={{
                    borderRadius: 12,
                    backgroundColor: "#E6E7F3",
                    border: "none",
                    borderColor: "#FFFFFF",
                  }}
                  placeholder="Escribe el mensaje"
                />
              </>
            )}
            {type === "finish" && (
              <>
                <FormControl fullWidth style={{ marginBottom: "12.28px" }}>
                  <CustomSelect
                    inputProps={{
                      style: {
                        height: isMobile && 40,
                      },
                    }}
                    isMobile={isMobile}
                    IconComponent={KeyboardArrowDownIcon}
                    labelId="demo-simple-select-disabled-label"
                    id="demo-simple-select-disabled"
                    value={finishChat}
                    onChange={(e) => setFinishChat(e.target.value)}
                  >
                    <MenuItem value="success">Resuelta</MenuItem>
                    <MenuItem value="noSuccess">No resuelta</MenuItem>
                  </CustomSelect>
                </FormControl>
                <TextField
                  id="multilineInput"
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  value={msgFinish}
                  onChange={(e) => {
                    if (msgFinish.length <= 256) {
                      setMsgFinish(e.target.value);
                    }
                  }}
                  multiline
                  rows={
                    isMobile
                      ? type === "transfer"
                        ? 2
                        : 2
                      : type === "transfer"
                      ? 2
                      : 3
                  }
                  style={{
                    borderRadius: 12,
                    backgroundColor: "#E6E7F3",
                    border: "none",
                    borderColor: "#FFFFFF",
                  }}
                  placeholder="Escribe el mensaje"
                />
              </>
            )}
            {type === "report" && (
              <>
                <FormControl fullWidth style={{ marginBottom: "12.28px" }}>
                  <CustomSelect
                    IconComponent={KeyboardArrowDownIcon}
                    value={reportChat}
                    onChange={(e) => {
                      e.preventDefault();
                      setReportChat(e.target.value);
                    }}
                  >
                    <MenuItem value="spam">Spam</MenuItem>
                    <MenuItem value="outContext">Fuera de contexto</MenuItem>
                    <MenuItem value="graphContent">Contenido gráfico</MenuItem>
                    <MenuItem value="bullying">Acoso</MenuItem>
                    <MenuItem value="hate">Odio e intolerancia</MenuItem>
                  </CustomSelect>
                </FormControl>
                <TextField
                  id="multilineInput"
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  multiline
                  rows={
                    isMobile
                      ? type === "transfer"
                        ? 2
                        : 2
                      : type === "transfer"
                      ? 2
                      : 3
                  }
                  value={msgFinish}
                  onChange={(e) => {
                    if (msgFinish.length <= 256) {
                      setMsgFinish(e.target.value);
                    }
                  }}
                  style={{
                    borderRadius: 12,
                    backgroundColor: "#E6E7F3",
                    border: "none",
                    borderColor: "#FFFFFF",
                  }}
                  placeholder="Escribe el mensaje"
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      {type === undefined ||
      type === "update" ||
      type === "err" ||
      type === "msg" ? (
        <></>
      ) : (
        !showSucess && (
          <DialogActions
            style={{
              justifyContent: "center",
              paddingBottom: "18px",
              paddingLeft: "18px",
              paddingRight: "18px",
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "25.5px",
                backgroundColor:
                  type === "updatePassword"
                    ? "#EA345F"
                    : type === "transfer"
                    ? msgFinish !== "" && abilitie
                      ? "#EA345F"
                      : "#C5C5C5"
                    : msgFinish === ""
                    ? "#C5C5C5"
                    : type === "finish"
                    ? "#4A27ED"
                    : "#EBE14B",
                color: "white",
                boxShadow: "none",
                height: isMobile ? 38 : 48,
              }}
              sx={{ width: { xs: "250px", sm: "350px" } }}
              variant="contained"
              disabled={
                type === "updatePassword"
                  ? false
                  : type === "transfer"
                  ? msgFinish !== "" && abilitie
                    ? false
                    : true
                  : msgFinish === ""
                  ? true
                  : false
              }
              onClick={async () => {
                switch (type) {
                  case "transfer":
                    try {
                      await onTransferCall(group, abilitie, msgFinish);
                      setShowSucess(true);
                    } catch (err) {
                      console.log(err);
                    }
                    break;
                  case "finish":
                    try {
                      await onChatEnd(finishChat, msgFinish);
                      setShowSucess(true);
                    } catch (err) {
                      console.log(err);
                    }
                    break;
                  case "updatePassword":
                    setOpen({ open: false, type: undefined });
                    navigate();
                    break;

                  default:
                    try {
                      await onChatEnd(type, `${reportChat} - ${msgFinish}`);
                      setShowSucess(true);
                    } catch (err) {
                      console.log(err);
                    }
                    break;
                }
              }}
            >
              {isLoading ? (
                <CircularProgress color="primary" />
              ) : type === "transfer" ? (
                "Transferir"
              ) : type === "finish" ? (
                "Finalizar"
              ) : type === "updatePassword" ? (
                "Aceptar"
              ) : (
                "Reportar"
              )}
            </Button>
          </DialogActions>
        )
      )}
    </Dialog>
  );
}

export default DialogTransferAtention;
