import React, { useState, useEffect, useContext, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AgentContext } from "../context";
import ChangePassword from "../pages/changePassword";

import Chat from "../pages/chat";
import History from "../pages/history";
import Login from "../pages/login";
import Profile from "../pages/profile";
import RecoverPassword from "../pages/recoverPassword";
import Ticket from "../pages/ticket";
import Sound from "../assets/sound.mp3";
import service from "../service";
import { TOKEN_KEY } from "../config";
import { documentEventsService } from "../services/documentEvents.service";
import { RoutesWithNotFound } from "../utils/RoutesWithNotFound";

export const AppRouter = ({ isMobile, width }) => {
  const {
    setTabActive,
    setUser,
    state,
    setCurrentChat,
    socket,
    setChatList,
    setQueue,
    setConfigDialog,
    setSnackbar,
    setLocalMessages,
    connect,
    setCurrentFiles,
    setCurrentAbilities,
    connectSocket,
  } = useContext(AgentContext);

  const [isLoading, setIsLoading] = useState(false);
  const [cleanInput, setCleanInput] = useState(false);
  const refreshTimerRef = useRef(null);
  const { chatList } = state;
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log(event.persisted);
      // Aquí puedes ejecutar la lógica que necesitas antes de que el navegador se cierre
      // Por ejemplo, guardar datos o mostrar un mensaje de despedida
      //  localStorage.clear();
      // Si no necesitas mostrar un mensaje, simplemente omite las líneas anteriores
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Limpia el listener cuando el componente se desmonta
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const getCurrentFiles = async (chatId) => {
    try {
      const currentFiles = await service.getCurrentFiles(
        state.user.company,
        chatId
      );
      setCurrentFiles(currentFiles);
    } catch (err) {
      console.log(err);
    }
  };

  const onReconnect = async (chatList = state.chatList) => {
    // console.log({ chatList });
    if (connect) {
      if (chatList.length > 0) {
        Promise.all(
          chatList?.map(async (chat) => {
            const chatBD = await service.getChat(state.user.company, chat._id);
            // console.log({ chatBD });
            if (chatBD.agent === state.user._id) {
              if (chatBD.status === "progress") {
                // console.log({ socket });
                socket?.emit("chat_join", { chat_id: chatBD._id });
              } else if (chatBD.status === "ended") {
                const filterChats = chatList.filter(
                  (chat) => chat._id !== chatBD._id
                );
                setChatList(filterChats);

                if (chatBD._id === localStorage.getItem("videolink_chat")) {
                  setCurrentChat(undefined);
                  setLocalMessages([]);
                  localStorage.removeItem("currentChat");
                  localStorage.setItem("messages", JSON.stringify([]));
                  setSnackbar({
                    open: true,
                    message: "El cliente ha finalizado la atención",
                    severity: "info",
                  });
                } else {
                  const { firstName = "", lastName = "" } =
                    chat?.contactV3 ?? {};

                  let clientName = firstName
                    ? `${firstName} ${lastName ?? ""}`.trim()
                    : "Sin especificar";

                  setSnackbar({
                    open: true,
                    message: `El cliente ${clientName} ha finalizado la atención`,
                    severity: "info",
                  });
                }
              } else {
                const filterChats = chatList.filter(
                  (chat) => chat._id !== chatBD._id
                );
                setChatList(filterChats);

                if (chatBD._id === localStorage.getItem("videolink_chat")) {
                  setCurrentChat(undefined);
                  setLocalMessages([]);
                  localStorage.setItem("messages", JSON.stringify([]));
                  setSnackbar({
                    open: true,
                    message: "El cliente ha vuelto a la cola",
                    severity: "info",
                  });
                } else {
                  const { firstName = "", lastName = "" } =
                    chat?.contactV3 ?? {};

                  let clientName = firstName
                    ? `${firstName} ${lastName ?? ""}`.trim()
                    : "Sin especificar";

                  setSnackbar({
                    open: true,
                    message: `El cliente ${clientName} ha vuelto a la cola`,
                    severity: "info",
                  });
                }
              }
            } else {
              const filterChats = chatList.filter(
                (chat) => chat._id !== chatBD._id
              );
              setChatList(filterChats);
              // console.log(
              //   localStorage.getItem("videolink_chat"),
              //   "LOCALSTORAGE"
              // );
              if (chatBD._id === localStorage.getItem("videolink_chat")) {
                setCurrentChat(undefined);
                setLocalMessages([]);
                setSnackbar({
                  open: true,
                  message: "El cliente ha vuelto a la cola",
                  severity: "info",
                });
              } else {
                const { firstName = "", lastName = "" } = chat?.contactV3 ?? {};

                let clientName = firstName
                  ? `${firstName} ${lastName ?? ""}`.trim()
                  : "Sin especificar";

                setSnackbar({
                  open: true,
                  message: `El cliente ${clientName} ha vuelto a la cola`,
                  severity: "info",
                });
              }
            }
          })
        );
      }
    }
  };

  const onRefreshToken = async () => {
    try {
      const result = await service.refreshToken();
      localStorage.setItem(TOKEN_KEY, result.token);
      const userInfo = service.getUserInfo(result.token);
      setUser({
        ...state.user,
        exp: userInfo.exp,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onRefreshSocket = () => {
    if (connect) {
      socket.disconnect();
    }

    connectSocket();
  };

  useEffect(() => {
    function handleVisibility() {
      if (document.hidden) {
        setTabActive(false);
      } else {
        setTabActive(true);
      }
    }
    document.addEventListener("visibilitychange", handleVisibility);
  }, []);

  const playSound = () => {
    if (!documentEventsService.windowWasFocused) return;
    const soundTag = document.createElement("audio");
    soundTag.setAttribute("src", Sound);
    soundTag.play();
  };

  useEffect(() => {
    socket?.on("queue_list", (data) => {
      const queue = data.filter(
        (chat) =>
          state.user?.groups.includes(chat.group) &&
          state.user?.abilities.includes(chat.ability._id)
      );

      const oldQueue = JSON.parse(localStorage.getItem("queue"))
        ? JSON.parse(localStorage.getItem("queue"))
        : [];
      const isSoundActive =
        localStorage.getItem("sound") === "true" ? true : false;

      setQueue(queue);

      if (oldQueue.length < queue.length) {
        if (isSoundActive) {
          // console.log({ Sound });
          // const audio = new Audio(Sound);
          // audio.play();
          playSound();
        }
      }

      if (queue.length > 0) service.handleNotifyFavicon();
      else service.handleFavicon();
    });
    // return () => socket?.off("queue_list");
  }, [socket]);

  useEffect(() => {
    socket?.on("chat_join", async (data) => {
      const currentChatList = JSON.parse(localStorage.getItem("chatList"))
        ? JSON.parse(localStorage.getItem("chatList"))
        : [];

      if (!currentChatList.find((chat) => chat._id === data.chat_id)) {
        const preferences = await service.getPreferences(state.user.company);
        if (preferences.noForm) delete preferences.attentionsForms;
        setUser({ ...JSON.parse(localStorage.getItem("user")), preferences });
        localStorage.setItem("videolink_chat", data.chat_id);
        const chat = await service.getChat(state.user.company, data.chat_id);
        currentChatList.push(chat);
        setChatList(currentChatList);
        setCurrentChat(chat);
        const localMessages = chat.totalMessages
          ? chat.totalMessages
              .filter(
                (eventChat) =>
                  eventChat.eventType === "msg" ||
                  (eventChat.eventType === "sysmsg" &&
                    (eventChat.action === "chat_transfer" ||
                      eventChat.action === "chat_ended" ||
                      eventChat.action === "chat_ended_admin"))
              )
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })
          : chat.history
              .filter(
                (eventChat) =>
                  eventChat.eventType === "msg" ||
                  (eventChat.eventType === "sysmsg" &&
                    (eventChat.action === "chat_transfer" ||
                      eventChat.action === "chat_ended" ||
                      eventChat.action === "chat_ended_admin"))
              )
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              });
        setLocalMessages(localMessages);
        socket?.emit("chat_join", data);

        setIsLoading(false);
      } else {
        console.log("Out validation duplicate chatList.");
      }
      setTimeout(() => {
        service.scrollToBottom();
      }, 100);
    });
    return () => socket?.off("chat_join");
  }, [socket]);

  useEffect(() => {
    socket?.on("message", async (data) => {
      if (Object.keys(data.content).length > 0) {
        const isSound = localStorage.getItem("sound") === "true" ? true : false;
        const newMessage = JSON.parse(localStorage.getItem("messages"));
        const tabActive =
          localStorage.getItem("tabActive") === "true" ? true : false;
        if (data.chat === localStorage.getItem("videolink_chat")) {
          if (data.type === "file") {
            await getCurrentFiles(localStorage.getItem("videolink_chat"));
          }

          const formatMessage = service.formatMessage({
            message: data.content.text,
            isAgent: false,
            id: data.content.id,
            name: data.content.name,
            agentId: data.sender,
            url: data.content.url,
            urlContentType: data.content.id ? "" : data.content.urlContentType,
            type: data.type,
            _id: service.randomStringGenerator(),
          });
          newMessage.push(formatMessage);
          localStorage.setItem("messages", JSON.stringify(newMessage));
          setLocalMessages(newMessage);
          if (!tabActive) {
            if (isSound) {
              playSound();
            }
            service.handleNotifyFavicon();
          }
          setTimeout(() => {
            service.scrollToBottom();
          }, 100);
        } else {
          const newlistChat = JSON.parse(localStorage.getItem("chatList"));
          const index = newlistChat?.findIndex(
            (chat) => chat._id === data.chat
          );
          if (index !== -1) {
            if (isSound) {
              playSound();
            }
            // service.handleNotifyFavicon();
            newlistChat[index].count = newlistChat[index].count + 1;
          }
          setChatList(newlistChat);
        }
      }
    });

    return () => socket?.off("message");
  }, [socket]);

  useEffect(() => {
    socket?.on("group:abilities:active", (data) => {
      const abilities = JSON.parse(localStorage.getItem("abilities"));
      setCurrentAbilities(
        abilities?.filter(
          (item) => data.includes(item._id) && item.status !== "inactive"
        )
      );
    });
    // return () => socket?.off("group:abilities:active");
  }, [socket]);

  useEffect(() => {
    socket?.on("chat_end", (data) => {
      const chatList = JSON.parse(localStorage.getItem("chatList"));
      const filterChats = chatList?.filter((chat) => chat._id !== data._id);
      if (data._id === localStorage.getItem("videolink_chat")) {
        setCleanInput(!cleanInput);
        setCurrentChat(undefined);
        setLocalMessages([]);
        localStorage.setItem("messages", JSON.stringify([]));
        localStorage.removeItem("videolink_chat");
        setConfigDialog({ open: false, type: undefined });
        console.log("mas arriba");

        setSnackbar({
          open: true,
          message: `El ${
            data.endedBy === "supervisor"
              ? "supervisor ha finalizado la conversación"
              : "cliente ha finalizado la conversación"
          } `,
          severity: "info",
        });
      } else {
        if (chatList.find((chat) => chat._id === data._id)) {
          const otherChats = localStorage.getItem("othersChats")
            ? JSON.parse(localStorage.getItem("othersChats"))
            : [];

          console.log({ otherChats });

          console.log("Se detona aqui");

          if (otherChats.includes(data._id)) {
            setSnackbar({
              open: true,
              message: `Se ha cerrado la atención por medio de la combinación de atenciones del mismo contacto.`,
              severity: "info",
            });
          } else {
            const { firstName = "", lastName = "" } = data?.contactV3 ?? {};

            let clientName = firstName
              ? `${firstName} ${lastName ?? ""}`.trim()
              : "sin especificar";

            setSnackbar({
              open: true,
              message: `El ${
                data.endedBy === "supervisor"
                  ? `supervisor ha finalizado la conversación del cliente ${clientName}`
                  : `El cliente ${clientName} ha finalizado la conversación`
              } `,
              severity: "info",
            });
          }
        }
      }

      setChatList(filterChats);
    });
    return () => socket?.off("chat_end");
  }, [socket]);

  useEffect(() => {
    socket?.on("chat_transfer", async (data) => {
      const chatList = JSON.parse(localStorage.getItem("chatList"));
      const transferChat = chatList?.find((chat) => chat._id === data._id);
      const filterChats = chatList?.filter((chat) => chat._id !== data._id);
      if (transferChat?._id == localStorage.getItem("videolink_chat")) {
        setCurrentChat(undefined);
        setLocalMessages([]);
        localStorage.setItem("messages", JSON.stringify([]));
        localStorage.removeItem("videolink_chat");
        const fullName =
          transferChat && transferChat.contact
            ? transferChat?.contact?.firstname === "No especificado" &&
              transferChat?.contact?.lastname === "No especificado"
              ? "No especificado"
              : service.getName(transferChat?.contact)
            : "No especificado";

        const { firstName = "", lastName = "" } = transferChat?.contactV3 ?? {};

        let clientName = firstName
          ? `${firstName} ${lastName ?? ""}`.trim()
          : "sin especificar";

        if (data.transferredBy !== "agent") {
          setSnackbar({
            open: true,
            message: `El cliente ${clientName} ha sido transferido por el supervisor`,
            severity: "info",
          });
        }
      } else {
        const fullName =
          transferChat && transferChat.contact
            ? transferChat?.contact?.firstname === "No especificado" &&
              transferChat?.contact?.lastname === "No especificado"
              ? "No especificado"
              : service.getName(transferChat?.contact)
            : "No especificado";

        const { firstName = "", lastName = "" } = transferChat?.contactV3 ?? {};

        let clientName = firstName
          ? `${firstName} ${lastName ?? ""}`.trim()
          : "sin especificar";
        setSnackbar({
          open: true,
          message: `El cliente ${clientName} ha sido transferido por el supervisor`,
          severity: "info",
        });
      }

      setChatList(filterChats);
    });
    // return () => socket?.off("chat_transfer");
  }, [socket]);

  useEffect(() => {
    onReconnect();
  }, [connect, chatList]);

  useEffect(() => {
    if (state.user) {
      const currentTime = new Date().getTime();
      const expirationTime = new Date(state.user.exp * 1000).getTime(); // Fecha de expiración del token en formato Unix Epoch
      const timeRemaining = expirationTime - currentTime;
      const refreshTime = timeRemaining - 1800000; // Tiempo restante menos un minuto

      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
      }
      if (refreshTime > 0) {
        refreshTimerRef.current = setTimeout(() => {
          onRefreshToken()
            .then(() => onRefreshSocket())
            .catch(console.error);
        }, refreshTime);
      }
    }
    return () => {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
      }
    };
  }, [state.user]);

  return (
    <Router>
      <RoutesWithNotFound>
        <Route path="/" element={<Login isMobile={isMobile} />} />
        <Route
          path="/chat"
          element={
            <Chat
              isMobile={isMobile}
              width={width}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setCleanInput={setCleanInput}
              cleanInput={cleanInput}
              onReconnect={onReconnect}
            />
          }
        />
        <Route
          path="/history"
          element={<History isMobile={isMobile} width={width} />}
        />
        <Route path="/profile" element={<Profile isMobile={isMobile} />} />
        <Route
          path="/change_password"
          element={<ChangePassword isMobile={isMobile} />}
        />
        <Route
          path="/recover"
          element={<RecoverPassword isMobile={isMobile} />}
        />
        <Route path="/ticket" element={<Ticket isMobile={isMobile} />} />
      </RoutesWithNotFound>
    </Router>
  );
};
