import { useState, useContext, forwardRef, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AgentContext } from "../context";
import DesktopView from "../views/profile/desktop";
import MobileView from "../views/profile/mobile";
import service from "../service";
import { environment } from "../config";
import DialogClose from "../components/dialogClose";
import DialogTransferAtention from "../components/dialogTransferAtention";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "react-avatar-edit";
import CircularProgress from "@mui/material/CircularProgress";
import { getCanalIcon } from "../utils";

const Profile = ({ isMobile }) => {
  const {
    state,
    setUser,
    handleSidebar,
    onLogout,
    handleDialogClose,
    connect,
    socket,
    setSnackbar,
    setConfigDialog,
  } = useContext(AgentContext);
  const [height, setHeight] = useState(window.innerHeight);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [code64URL, setCode64URL] = useState(null);
  const [currentPreview, setCurrentPreview] = useState(
    state?.user?.avatar ? state?.user?.avatar : getCanalIcon("web")
  );
  const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false);
  const [currentCropFile, setCurrentCropFile] = useState(null);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [loading] = useState(false);
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const navigate = useNavigate();
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { chatList, currentChat } = state;

  const handleAvatar = async (file) => {
    try {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
        // file.type !== "image/svg+xml"
      ) {
        setSnackbar({
          open: true,
          message: `Archivo no permitido`,
          severity: "error",
        });
      } else {
        const resizedString = await convertToBase64(file);
        setFile(file);

        setCode64URL(resizedString);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateAvatar = async () => {
    try {
      setIsLoadingAvatar(true);
      const resizedString = await convertToBase64(currentCropFile);
      setCurrentPreview(resizedString);

      const image = await service.onUploadImageMessage(currentCropFile);
      const urlAvatar = `${environment.storage}/c/${image._id}`;
      await service.updateAgentAvatar(state.user._id, urlAvatar);
      const currentUser = await service.getCurrentAgent(state.user._id);
      setUser(currentUser[0]);
      setIsOpenDialog(false);
      setCode64URL(null);
      setFile(null);
      setCurrentCropFile(null);
      setIsLoadingAvatar(false);
    } catch (err) {
      setIsLoadingAvatar(false);
      console.log(err);
    }
  };

  const hanleData = async (payload) => {
    try {
      const newUser = await service.updateAgent(state.user._id, payload);
      setUser(newUser);
      setConfigDialog({ open: true, type: "update" });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAgentConnect = (event) => {
    if (event) {
      socket?.connect();
    } else {
      socket?.disconnect();
    }
    localStorage.setItem("connect", event);
  };

  const hanleDataSound = async (payload) => {
    try {
      const newUser = await service.updateAgentSound(state.user._id, payload);
      delete newUser.password;
      setUser(newUser);
      localStorage.setItem("sound", payload.isActive);
      console.log(payload);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const find = chatList?.find((item) => item.count > 0);

    if (find) service.handleNotifyFavicon();
    else service.handleFavicon();
  }, [chatList, currentChat]);

  function convertToBase64(blob) {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  return (
    <>
      {state.user ? (
        isMobile ? (
          <MobileView
            state={state}
            handleSidebar={handleSidebar}
            onLogout={onLogout}
            container={container}
            navigate={navigate}
            handleDialogClose={handleDialogClose}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            handleAvatar={handleAvatar}
            hanleData={hanleData}
            height={height}
            hanleDataSound={hanleDataSound}
            loading={loading}
            currentPreview={currentPreview}
          />
        ) : (
          <DesktopView
            state={state}
            handleSidebar={handleSidebar}
            onLogout={onLogout}
            container={container}
            navigate={navigate}
            handleDialogClose={handleDialogClose}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            handleAvatar={handleAvatar}
            hanleData={hanleData}
            height={height}
            hanleDataSound={hanleDataSound}
            loading={loading}
            currentPreview={currentPreview}
          />
        )
      ) : (
        <Navigate to="/" />
      )}
      <DialogClose
        open={state.isOpenDialog}
        setOpen={handleDialogClose}
        onAccept={onLogout}
        onClose={() => handleDialogClose(false)}
      />
      <DialogTransferAtention
        open={state.configDialog.open}
        type={state.configDialog.type}
        setOpen={setConfigDialog}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={state.snackbar.open}
        onClose={() =>
          setSnackbar({
            open: false,
            message: undefined,
            severity: undefined,
          })
        }
        style={{ display: state.snackbar.open ? "flex" : "none" }}
      >
        <Alert severity={state.snackbar.severity}>
          {state.snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={isOpenDialog}
        onClose={() => {
          setIsOpenDialog(false);
          setCode64URL(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <Box
          style={{
            height: 80,
            backgroundColor: "#1B0845",
            display: "flex",
            alignItems: "center",
            paddingLeft: 22,
            paddingRight: 22,
          }}
        >
          <Box
            style={{
              width: isMobile && "10%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CloseIcon
              onClick={() => {
                setIsOpenDialog(false);
                setCode64URL(null);
                setFile(null);
                setCurrentCropFile(null);
              }}
              fontSize={isMobile ? "small" : "medium"}
              style={{
                color: "#FFFFFF",
                // position: "absolute",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            style={{
              alignItems: "center",
              textAlign: "center",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontSize: isMobile && 14,
            }}
          >
            Arrastra la imagen para ajustarla
          </Box>
        </Box>
        <Box style={{ overflow: "hidden" }}>
          {code64URL !== null && (
            <Avatar
              width={"100%"}
              height={450}
              src={code64URL && code64URL}
              onClose={() => {
                setCode64URL(null);
                setFile(null);
                setCurrentCropFile(null);
              }}
              onCrop={(event) => {
                const cropFile = dataURLtoFile(`${event}`, `${file.name}`);
                setCurrentCropFile(cropFile);
              }}
              imageWidth={isMobile && 325}
              minCropRadius={100}
              // cropRadius={120}
              closeIconColor="transparent"
            />
          )}
        </Box>
        <Box
          style={{
            height: isMobile ? 80 : 140,
            background: "#D9D9D9",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton
            style={{
              width: isMobile ? 60 : 75,
              height: isMobile ? 60 : 75,
              background: "#EA345F",
              marginTop: -40,
              marginRight: isMobile ? 12 : 62,
            }}
            disabled={isLoadingAvatar}
            onClick={() => updateAvatar()}
          >
            {isLoadingAvatar ? (
              <CircularProgress size={40} style={{ color: "#FFFFFF" }} />
            ) : (
              <CheckIcon style={{ color: "#FFFFFF", width: 40, height: 40 }} />
            )}
          </IconButton>
        </Box>
      </Dialog>
      <Dialog
        open={isOpenWarningDialog}
        onClose={() => setIsOpenWarningDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 30,
            padding: 24,
            paddingBottom: 78,
          },
        }}
      >
        <Box style={{ display: "flex" }}>
          <CloseIcon
            onClick={() => {
              setIsOpenWarningDialog(false);
            }}
            fontSize={isMobile ? "small" : "medium"}
            style={{
              borderRadius: "100%",
              background: "#0000001F",
              color: "#FFFFFF",
              marginLeft: "auto",
              // position: "absolute",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          style={{
            fontFamily: "Rubik",
            fontWeight: 700,
            color: "#00000066",
            fontSize: 25,
            textAlign: "center",
            marginTop: 28,
          }}
        >
          Estas fotos son muy pequeñas, Por favor, seleccionar fotos con altura
          y anchura de mínimo 192 pixeles.
        </Box>
      </Dialog>
      <img
        src={code64URL}
        alt=""
        style={{ display: "none" }}
        id="validateImg"
        onLoad={() => {
          const element = document.querySelector("#validateImg");
          if (element.naturalWidth < 192 || element.naturalHeight < 192) {
            setIsOpenDialog(false);
            setFile(null);
            setCode64URL(null);
            setCurrentCropFile(null);
            setIsOpenWarningDialog(true);
          } else {
            setIsOpenDialog(true);
          }
        }}
      />
    </>
  );
};

export default Profile;
