import { createContext, useContext, useEffect, useState } from "react";
import { contactSrc } from "../services/contact.service";
import { AgentContext } from ".";

export const chatContext = createContext();
export const ChatContextProvider = ({ children }) => {
  const { state } = useContext(AgentContext);
  const [isLoading, setIsLoading] = useState(false);
  const validateList = async () => {
    try {
      const list = await contactSrc.getChatList();
      localStorage.removeItem("chatList");
      localStorage.setItem("chatList", JSON.stringify(list));
      state.chatList = list;
    } catch (error) {}
  };

  useEffect(() => {
    updateChatList();
  }, []);

  const updateChatList = async () => {
    await validateList();
  };

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  return (
    <chatContext.Provider
      value={{
        isLoading,
        showLoading,
        hideLoading,
        updateChatList,
      }}
    >
      {children}
    </chatContext.Provider>
  );
};
