import DateObject from "react-date-object";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { environment } from "../config";
import service from "../service";

const HistoryFiles = ({ files, agents, details }) => {
  console.log({
    files,
    agents,
    details,
  });
  return (
    <Grid container spacing={1} style={{ padding: 24 }}>
      {files.map((item) =>
        item.conversation.map((item) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            xl={4}
            key={item.content.id}
          >
            <Card sx={{ maxWidth: 345, maxHeight: 325 }}>
              <CardActionArea>
                {details.channel === "whatsapp" ||
                details.channel === "facebook" ||
                details.channel === "instagram" ? (
                  item.content.id ? (
                    item.content.name.split(".")[
                      item.content.name.split.length - 1
                    ] === "pdf" ? (
                      <a
                        href={`${environment.storage}/c/${item.content.id}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="message-img-vl-agent"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "8px",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "8px",
                            }}
                          >
                            <InsertDriveFileIcon style={{ color: "#EA345F" }} />
                          </div>
                          <div
                            style={{
                              color: "#EA345F",
                              wordBreak: "break-word",
                            }}
                          >
                            {item.content.name}
                          </div>
                        </div>
                      </a>
                    ) : item.content.name
                        .split(".")
                        [item.content.name.split.length - 1].includes("mp4") ? (
                      <video style={{ width: "100%" }} height="250" controls>
                        <source
                          src={`${environment.storage}/c/${item.content.id}`}
                          type={"video/mp4"}
                        />
                      </video>
                    ) : (
                      <img
                        src={`${environment.storage}/c/${item.content.id}`}
                        alt=""
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          objectFit: "contain",
                          height: 250,
                        }}
                        className="message-img-vl-agent"
                      />
                    )
                  ) : item?.content?.url &&
                    item?.content?.urlContentType?.includes("audio/") ? (
                    <Box
                      style={{
                        height: 250,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <audio controls style={{ width: "100%" }} height="250">
                        <source
                          src={item?.content?.url}
                          type={item?.content?.urlContentType}
                        />
                      </audio>
                    </Box>
                  ) : item?.content?.urlContentType === "application/pdf" ? (
                    <a
                      href={item?.content?.url}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="message-img-vl-agent"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "8px",
                          height: 250,
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "8px",
                          }}
                        >
                          <InsertDriveFileIcon
                            style={{ color: "#EA345F", fontSize: 72 }}
                          />
                        </div>
                        <div
                          style={{
                            color: "#EA345F",
                            wordBreak: "break-word",
                            fontSize: 48,
                          }}
                        >
                          PDF
                        </div>
                      </div>
                    </a>
                  ) : item?.content?.urlContentType?.includes("video/") ? (
                    <video style={{ width: "100%" }} height="250" controls>
                      <source
                        src={item?.content?.url}
                        type={item?.content?.urlContentType}
                      />
                    </video>
                  ) : (
                    item?.content?.urlContentType?.includes("image/") && (
                      <a
                        href={item?.content?.url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <CardMedia
                          component="img"
                          style={{
                            width: "100%",
                            // borderRadius: "10px",
                            objectFit: "cover",
                            height: 250,
                          }}
                          image={item?.content?.url}
                          alt=""
                        />
                      </a>
                    )
                  )
                ) : details.channel === "web" ||
                  details.channel === "web-widget" ? (
                  item.content.id ? (
                    item.content.name.split(".")[
                      item.content.name.split.length - 1
                    ] === "pdf" ? (
                      <a
                        href={`${environment.storage}/c/${item.content.id}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="message-img-vl-agent"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "8px",
                            height: 250,
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "8px",
                            }}
                          >
                            <InsertDriveFileIcon
                              style={{ color: "#EA345F", fontSize: 72 }}
                            />
                          </div>
                          <div
                            style={{
                              color: "#EA345F",
                              wordBreak: "break-word",
                              fontSize: 48,
                            }}
                          >
                            PDF
                          </div>
                        </div>
                      </a>
                    ) : item.content.name
                        .split(".")
                        [item.content.name.split(".").length - 1].includes(
                          "mp3"
                        ) ||
                      item.content.name
                        .split(".")
                        [item.content.name.split(".").length - 1].includes(
                          "ogg"
                        ) ? (
                      <div
                        style={{
                          height: 250,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <audio
                          style={{
                            width: "100%",
                          }}
                          controls
                        >
                          <source
                            src={`${environment.storage}/c/${item.content.id}`}
                            type={"audio/mp3"}
                          />
                        </audio>
                      </div>
                    ) : item.content.name
                        .split(".")
                        [item.content.name.split(".").length - 1].includes(
                          "mp4"
                        ) ? (
                      <>
                        <video style={{ width: "100%" }} height="250" controls>
                          <source
                            src={`${environment.storage}/c/${item.content.id}`}
                            type={"video/mp4"}
                          />
                        </video>
                      </>
                    ) : (
                      <a
                        href={`${environment.storage}/c/${item.content.id}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={`${environment.storage}/c/${item.content.id}`}
                          alt=""
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: 250,
                          }}
                          className="message-img-vl-agent"
                        />
                      </a>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </CardActionArea>

              <CardContent>
                <Box
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.content?.id && item.content.name}
                </Box>
                <Box style={{ fontSize: 12 }}>
                  {new DateObject(item.date).format("DD/MM/YYYY - hh:mm a")}
                </Box>
                <Box style={{ fontSize: 12 }}>
                  <strong>Enviado </strong>
                  {item.isAgent
                    ? agents.find((agent) => agent.id === item.sender)?.fullName
                    : details && details.contact
                    ? details.contact.firstname === "No especificado" &&
                      details.contact.lastname === "No especificado"
                      ? "No especificado"
                      : service.getName(details.contact)
                    : details?.menbers[0]?.user?.p?.firstname === ""
                    ? "No especificado"
                    : details?.menbers[0]?.user?.p?.firstname}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default HistoryFiles;
