import { useContext } from "react";
import { TicketContext } from "../../context";
import ProfileHeader from "../profile-header/profile-header";
import "./ticket-details-header.scss";

export const TicketDetailsHeader = () => {
  const { ticket } = useContext(TicketContext);
  return (
    <>
      {ticket.summary && (
        <ProfileHeader
          ticket={{
            name: ticket?.client?.name,
            description: ticket?.description,
            createdAt: ticket?.createdAt,
            number: ticket?.number,
          }}
        ></ProfileHeader>
      )}
    </>
  );
};
