import axiosInstance from "./api";
import DateObject from "react-date-object";
import notifyFavicon from "./assets/svg/omniflou-new-msg-icon.svg";
import faviconIcon from "./assets/svg/omniflow-small.svg";
import { environment } from "./config";

const service = {
  onLogin,
  onSession,
  refreshToken,
  getUserInfo,
  getGroups,
  getChat,
  handleNotifyFavicon,
  handleFavicon,
  getTitle,
  onUpdateClientInfo,
  updateCurrentChat,
  getAbilities,
  getAgent,
  getAllAgents,
  formatMessage,
  randomStringGenerator,
  onUploadImageMessage,
  getCurrentFiles,
  scrollToBottom,
  getHistorial,
  getChatDetails,
  getChatDetailsMessages,
  getFilesHistorial,
  getChannelName,
  updateContactInfo,
  updateAgentAvatar,
  getCurrentAgent,
  updateAgent,
  getPreferences,
  updateAgentSound,
  onUpdateDynamicForm,
  sendEmailForChangePassword,
  verifyToken,
  changePassword,
  updateContact,
  getContactsInfo,
  getName,
  mergeContact,
  getProgressChat,
  getContactsMatch,
  // tickets
  getTickets,
  getTicket,
  getTicketFiles,
  sendMessage,
  uploadTicketFileMessage,
  getTicketStatuses,
  updateTicketStatus,
};

function onLogin(email, password) {
  return axiosInstance.post(`/auth/login`, {
    email: email.toLowerCase(),
    password,
    loginAs: "agent",
  });
}

function onSession(email) {
  return axiosInstance.post(`/auth/session`, {
    email: email.toLowerCase(),
    loginAs: "agent",
  });
}
function refreshToken(email, password) {
  return axiosInstance.post(`/auth/refresh-token`);
}

function getUserInfo(token) {
  const tokenHashed = token.split(".")[1];
  const token_clean = atob(tokenHashed);
  return JSON.parse(token_clean);
}

function getGroups(company) {
  return axiosInstance.get(`/c/${company}/groups`);
}

function getAbilities(company) {
  return axiosInstance.get(`/c/${company}/ability`);
}

function getPreferences(company) {
  return axiosInstance.get(`/c/${company}/pref`);
}

function getChat(company, id, skip = 0) {
  return axiosInstance.get(`/c/${company}/chats/${id}/current/${skip}`);
}

function updateContact(id, data) {
  return axiosInstance.put(`/v2/contacts/${id}`, data);
}

function mergeContact(data) {
  return axiosInstance.put(`/v2/contacts/merge/chat`, data);
}

function getContactsInfo(contact) {
  return axiosInstance.get(`/v2/contacts/names`);
}

function getContactsMatch(contact) {
  return axiosInstance.get(`/v2/contacts/match`, {
    params: {
      contact: JSON.stringify(contact),
    },
  });
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

function getAgent(company, id) {
  if (id == undefined) return;
  return axiosInstance.get(`/c/${company}/chats/agentname/${id}`);
}

function getAllAgents(company) {
  return axiosInstance.get(`/c/${company}/users/info`);
}

function formatMessage(input) {
  return {
    _id: input._id,
    isAgent: input.isAgent,
    sender: input.agentId,
    type: input.type,
    content: {
      text: input.message,
      id: input.id,
      name: input.name,
      url: input.url,
      urlContentType: input.urlContentType,
    },
    date: new Date(),
  };
}

function getName(option) {
  return option.firstname !== "No especificado" &&
    option.lastname !== "No especificado"
    ? `${option.firstname} ${option.lastname} `
    : option.firstname === "No especificado" &&
      option.lastname !== "No especificado"
    ? option.lastname
    : option.firstname !== "No especificado" &&
      option.lastname === "No especificado"
    ? option.firstname
    : "";
}

function handleNotifyFavicon() {
  const favicon = getFaviconEl(); // Accessing favicon element
  favicon.href = notifyFavicon;
}

function handleFavicon() {
  const favicon = getFaviconEl(); // Accessing favicon element
  favicon.href = faviconIcon;
}

function getTitle(state) {
  if (state.configDialog.type === "transfer") {
    return "Transferir a:";
  } else if (state.configDialog.type === "finish") {
    return "Marca tu atención como:";
  } else if (state.configDialog.type === "report") {
    return "¿Por qué reportas este usuario?";
  } else {
    return "";
  }
}

function onUploadImageMessage(file) {
  const formData = new FormData();
  formData.append("image", file);

  return axiosInstance.post(`${environment.storage}/c/chat`, formData);
}

function updateAgentAvatar(id, url) {
  return axiosInstance.put(`${environment.server}/user/${id}/agent/avatar`, {
    avatar: url,
  });
}

function updateAgent(id, payload) {
  return axiosInstance.put(`${environment.server}/user/${id}/agent`, payload);
}

function updateAgentSound(id, payload) {
  return axiosInstance.put(
    `${environment.server}/user/${id}/agent/sound`,
    payload
  );
}

function getCurrentAgent(id) {
  return axiosInstance.get(`${environment.server}/user/${id}`);
}

function onUpdateClientInfo(company, id, form) {
  return axiosInstance.put(`/c/${company}/guess/info/${id}`, form);
}

function onUpdateDynamicForm(id, form) {
  return axiosInstance.put(`/v2/chat/${id}/form`, { form });
}

function getProgressChat(id, form) {
  return axiosInstance.get(`/v2/chat/1/progress`);
}

function updateCurrentChat(currentChat, chatList, res, customFields) {
  currentChat.menbers[0].user.email = res.email;
  currentChat.menbers[0].user.p.firstname = res.p.firstname;
  currentChat.menbers[0].user.p.identification = res.p.identification;
  currentChat.menbers[0].user.p.phone[0] = res.p.phone[0];
  currentChat.customFields = customFields;
  const array = chatList?.map((chat) => {
    if (chat._id === currentChat._id) chat = currentChat;
    return chat;
  });

  return {
    currentChat,
    chatList: array,
  };
}

function updateContactInfo(currentChat, chatList, data) {
  const { firstname, lastname, email, phone } = data;
  currentChat.contact = {
    ...currentChat.contact,
    firstname,
    lastname,
    email,
    phone,
  };

  const array = chatList?.map((chat) => {
    if (chat._id === currentChat._id) chat = currentChat;
    return chat;
  });
  return {
    currentChat,
    chatList: array,
  };
}

function randomStringGenerator() {
  return (
    Math.random().toString(36).substring(2) +
    Date.now().toString(36) +
    Math.random().toString(36).substring(2)
  );
}

function getCurrentFiles(company, chat) {
  return axiosInstance.get(`/c/${company}/chats/${chat}/files`);
}

function scrollToBottom() {
  const chatContainer = document.getElementById("contianed-chat-vl");
  chatContainer.scrollTop = chatContainer.scrollHeight;
  return true;
}

function getHistorial(
  company,
  limit,
  page,
  channels,
  startDate,
  endDate,
  username
) {
  const format = new DateObject(new Date(startDate)).format("YYYY-MM-DD");
  const formatEnd = new DateObject(
    new Date(endDate[0] === null ? null : endDate)
  ).format("YYYY-MM-DD");
  const isResolved = channels
    ? channels.find((cha) => cha === "resolved")
    : false;
  const isNotResolved = channels
    ? channels.find((cha) => cha === "notResolved")
    : false;
  const channelsFilteres = channels
    ? channels.filter((cha) => cha !== "resolved" && cha !== "notResolved")
    : false;
  return axiosInstance.get(
    `c/${company}/chats/history/agent?limit=${limit}${
      format ? `&startDate=${format}&endDate=${formatEnd}` : ""
    }${
      isResolved && !isNotResolved
        ? "&resolved=true"
        : !isResolved && isNotResolved
        ? `&resolved=false`
        : ""
    }&page=${page}${
      channelsFilteres &&
      channelsFilteres.length > 0 &&
      channelsFilteres !== undefined
        ? `&channel=${String(channelsFilteres)}`
        : ""
    }${!username ? "" : `&search=${username}`}`
  );
}

function getChatDetails(company, chat) {
  return axiosInstance.get(`/chat/v2/${chat}`);
}

function getChatDetailsMessages(company, chat) {
  return axiosInstance.get(`/c/${company}/chats/${chat}/history`);
}

function getFilesHistorial(company, chat) {
  return axiosInstance.get(`/c/${company}/chats/${chat}/history/files`);
}

function getChannelName(details) {
  if (details?.channel === "web") {
    return "Hook";
  } else if (details?.channel === "whatsapp") {
    return "Whatsapp";
  } else if (details?.channel === "web-widget") {
    return "Widget";
  } else if (details?.channel === "facebook") {
    return "Facebook";
  } else if (details?.channel === "instagram") {
    return "Instagram";
  }
}
/**
 * @typedef {Object} Tickets
 * @property {string} _id
 * @property {string} summary
 * @property {string} description
 * @property {string} status
 * @property {boolean} priority
 *
 *
 * @typedef {Object} ticketListResponse
 * @property {'err'|'ok'} type
 * @property { {tickets:Array<Tickets>}} data
 *
 */

/**
 * @typedef {Object} ticketQuery
 * @property {number } [page=1]
 * @property {number} [limit=10]
 * @property {string} [sort = 'createdAt']
 * @property {'asc'|'desc'} [order = 'desc']
 * @property {string?} search
 *
 * @param {ticketQuery} params query params of request
 * @returns {Promise<ticketListResponse>}
 */
function getTickets(params) {
  return axiosInstance.get(`/api/v2/tk`, {
    params,
  });
}
function getTicket(id) {
  return axiosInstance.get(`/api/v2/tk/${id}`);
}
/**
 *
 * @param {Array<string>} ids
 * @param {any}
 * @returns
 */
function getTicketFiles(ids) {
  try {
    return axiosInstance.get(`/api/v2/tk/files`, {
      params: { ids },
    });
  } catch (error) {
    throw error;
  }
}
/**
 *
 * @param {String} id
 * @param {text} message
 * @param {Array<string>} files
 * @returns
 */
function sendMessage(id, message, files) {
  return axiosInstance.post(`/api/v2/tk/${id}/msg`, {
    message,
    files,
  });
}

/**
 *
 * @param {File} file
 * @returns
 */
function uploadTicketFileMessage(file) {
  const formData = new FormData();
  formData.append("document", file);
  return axiosInstance.post(`${environment.storage}/tk/upload`, formData);
}

function updateTicketStatus(id, status) {
  return axiosInstance.put(`/api/v2/tk/${id}/status`, {
    status,
  });
}

function getTicketStatuses() {
  return axiosInstance.get(`/api/v2/tk/status/list`);
}

function sendEmailForChangePassword(payload) {
  return axiosInstance.post(`/pwd/reset`, payload);
}

function verifyToken(token) {
  return axiosInstance.post(`/pwd/valid`, token);
}

function changePassword(payload) {
  return axiosInstance.post(`/pwd/change_password`, payload);
}

export default service;
