import { useState, forwardRef, useContext, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import service from "../service";
import { consts } from "../const";
import { AgentContext } from "../context";
import DesktopView from "../views/ticket/desktop";
import Layout from "../components/layout";

const Ticket = () => {
  return (
    <Layout config={{ page: "ticket" }}>
      <DesktopView />
    </Layout>
  );
};

export default Ticket;
