class DocumentEventsService {
  windowWasFocused = false;

  watchFocusWindow() {
    window.addEventListener("focus", () => {
      console.log("window was focused");
      this.windowWasFocused = true;
    });
  }
}

export const documentEventsService = new DocumentEventsService();
