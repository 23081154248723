import Avatar from "@mui/material/Avatar";
import DateObject from "react-date-object";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { environment } from "../config";
import { getCanalIcon, getText } from "../utils";
import service from "../service";

const HistoryMessages = ({ messages, agents, details, width }) => {
  let agent = messages?.find((el) => el.conversation.length > 0)?.agent;
  const currentMessages = messages?.filter((el) => el.conversation.length > 0);

  let clientName = "No identificado";

  const { contactV3 } = details;

  if (contactV3) {
    const { firstName, lastName } = contactV3;

    if (firstName) {
      clientName = `${firstName} ${lastName ?? ""}`.trim();
    }
  }

  return (
    <Grid
      container
      spacing={1}
      style={{ paddingTop: 24, paddingBottom: 24, paddingRigth: 24 }}
    >
      {currentMessages.map((item, index) => {
        let content = undefined;
        if (index > 0) {
          agent = item.agent;
          content = (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingBottom: 12,
                borderBottom: "solid 1px #E0E0E0",
                marginBottom: 12,
              }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                El cliente fue transferido a &nbsp;
                <strong>
                  {
                    agents.find((agentCurrent) => agentCurrent?.id === agent)
                      ?.fullName
                  }
                </strong>
              </Box>
            </Grid>
          );
        }
        return item.conversation.map((item) => {
          let output;
          if (content !== "" && item.content) {
            output = (
              <>
                {content}
                <Grid
                  key={item._id}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    paddingBottom: 12,
                    borderBottom: "solid 1px #E0E0E0",
                    marginBottom: 12,
                  }}
                >
                  <Box style={{ display: "flex" }}>
                    {item.eventType !== "sysmsg" && (
                      <Box>
                        <Avatar
                          src={
                            agents.find((agent) => agent?.id === item.sender)
                              ? agents.find(
                                  (agent) => agent?.id === item.sender
                                ).avatar
                              : getCanalIcon(details.channel)
                          }
                        />
                      </Box>
                    )}

                    <Box
                      style={{
                        marginLeft: 12,
                        marginRight: 12,
                        width: width < 850 && "80%",
                      }}
                    >
                      <Box
                        style={{
                          fontWeight: "bold",
                          marginBottom: 4,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width:
                            width < 400 && width > 321
                              ? 200
                              : width < 321
                              ? 150
                              : "",
                        }}
                      >
                        {item.eventType === "sysmsg"
                          ? "Mensaje de sistema"
                          : item.isAgent
                          ? agents.find((agent) => agent?.id === item.sender)
                              ?.fullName || "Owner"
                          : details && clientName}
                      </Box>
                      {details.channel === "whatsapp" ||
                      details.channel === "facebook" ||
                      details.channel === "instagram" ? (
                        item.content?.id ? (
                          item.content.name.split(".")[
                            item.content.name.split.length - 1
                          ] === "pdf" ? (
                            <a
                              href={`${environment.storage}/c/${item.content?.id}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <div
                                className="message-img-vl-agent"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "8px",
                                  }}
                                >
                                  <InsertDriveFileIcon
                                    style={{ color: "#EA345F" }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "#EA345F",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {item.content.name}
                                </div>
                              </div>
                            </a>
                          ) : item.content.name
                              .split(".")
                              [item.content.name.split.length - 1].includes(
                                "mp4"
                              ) ? (
                            <video
                              style={{ width: "100%" }}
                              height="250"
                              controls
                            >
                              <source
                                src={`${environment.storage}/c/${item.content.id}`}
                                type={"video/mp4"}
                              />
                            </video>
                          ) : (
                            <img
                              src={`${environment.storage}/c/${item.content?.id}`}
                              alt=""
                              style={{
                                width: width < 850 ? "80%" : "100%",
                                borderRadius: "10px",
                                objectFit: "contain",
                                maxHeight: 500,
                              }}
                              className="message-img-vl-agent"
                            />
                          )
                        ) : item?.content?.url &&
                          item?.content?.urlContentType?.includes("audio/") ? (
                          <audio controls>
                            <source
                              src={item?.content?.url}
                              type={item?.content?.urlContentType}
                            />
                          </audio>
                        ) : item?.content?.urlContentType ===
                          "application/pdf" ? (
                          <a
                            href={item?.content?.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              className="message-img-vl-agent"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "8px",
                                }}
                              >
                                <InsertDriveFileIcon
                                  style={{ color: "#EA345F" }}
                                />
                              </div>
                              <div
                                style={{
                                  color: "#EA345F",
                                  wordBreak: "break-word",
                                }}
                              >
                                Archivo PDF
                              </div>
                            </div>
                          </a>
                        ) : item?.content?.urlContentType?.includes(
                            "video/"
                          ) ? (
                          <video width="320" height="240" controls>
                            <source
                              src={item?.content?.url}
                              type={item?.content?.urlContentType}
                            />
                          </video>
                        ) : (
                          item?.content?.urlContentType?.includes("image/") && (
                            <img
                              src={item?.content?.url}
                              alt=""
                              style={{
                                width: width < 850 ? "80%" : "100%",
                                borderRadius: "10px",
                                objectFit: "contain",
                                maxHeight: 500,
                              }}
                              className="message-img-vl-agent"
                            />
                          )
                        )
                      ) : details.channel === "web" ||
                        details.channel === "web-widget" ? (
                        item.content?.id ? (
                          item.content.name.split(".")[
                            item.content.name.split.length - 1
                          ] === "pdf" ? (
                            <a
                              href={`${environment.storage}/c/${item.content?.id}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <div
                                className="message-img-vl-agent"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "8px",
                                  }}
                                >
                                  <InsertDriveFileIcon
                                    style={{ color: "#EA345F" }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "#EA345F",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {item.content.name}
                                </div>
                              </div>
                            </a>
                          ) : item.content.name
                              .split(".")
                              [
                                item.content.name.split(".").length - 1
                              ].includes("mp3") ||
                            item.content.name
                              .split(".")
                              [
                                item.content.name.split(".").length - 1
                              ].includes("ogg") ? (
                            <audio width="320" height="240" controls>
                              <source
                                src={`${environment.storage}/c/${item.content?.id}`}
                                type={"audio/mp3"}
                              />
                            </audio>
                          ) : item.content.name
                              .split(".")
                              [
                                item.content.name.split(".").length - 1
                              ].includes("mp4") ? (
                            <>
                              <video
                                style={{ width: "100%" }}
                                height="250"
                                controls
                              >
                                <source
                                  src={`${environment.storage}/c/${item.content.id}`}
                                  type={"video/mp4"}
                                />
                              </video>
                            </>
                          ) : (
                            <img
                              src={`${environment.storage}/c/${item.content?.id}`}
                              alt=""
                              style={{
                                width: width < 850 ? "80%" : "100%",
                                borderRadius: "10px",
                                objectFit: "contain",
                                maxHeight: 500,
                              }}
                              className="message-img-vl-agent"
                            />
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <Box
                        style={{ wordBreak: "break-word", color: "#394154" }}
                      >
                        {item.eventType === "sysmsg"
                          ? `Motivo de transferencia: ${item.content?.text}`
                          : item.content?.text}
                      </Box>
                    </Box>
                    <Box style={{ marginLeft: "auto", textAlign: "right" }}>
                      <Box
                        style={{
                          wordBreak: width > 500 && "break-word",
                          fontSize: width < 850 && 12,
                        }}
                      >
                        {new DateObject(new Date(item.date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Box>
                      <Box
                        style={{
                          wordBreak: width > 500 && "break-word",
                          fontSize: width < 850 && 12,
                        }}
                      >
                        {new DateObject(new Date(item.date)).format("hh:mm a")}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            );
            content = undefined;
          } else {
            output = "";
          }
          return output;
        });
      })}
      <Box style={{ textAlign: "center", width: "100%" }}>
        {details?.chatEndInformation ? (
          details?.chatEndInformation?.type === "success" ? (
            <Box>
              <Box style={{ marginBottom: 8 }}>
                <strong>Atención Finalizada</strong> : Resuelta
              </Box>
              <Box>
                <strong>Mensaje</strong> : {details.chatEndInformation.reason}
              </Box>
            </Box>
          ) : details?.chatEndInformation?.type === "report" ? (
            <Box>
              <Box style={{ marginBottom: 8 }}>
                <strong>Reporte</strong> :{" "}
                {getText(details?.chatEndInformation?.reason?.split("-")[0])}
              </Box>
              <Box>
                <strong>Atención Finalizada</strong> :{" "}
                {details?.chatEndInformation?.reason?.split("-")[1]}
              </Box>
            </Box>
          ) : (
            <Box>
              <Box style={{ marginBottom: 8 }}>
                <strong>Atención Finalizada</strong> : No resuelta
              </Box>
              <Box>
                <strong>Mensaje</strong> : {details?.chatEndInformation?.reason}
              </Box>
            </Box>
          )
        ) : (
          ""
        )}
      </Box>
      <Box style={{ textAlign: "center", width: "100%", marginTop: 8 }}>
        {details?.endedBy === "supervisor" ? (
          <Box>
            <strong>Atención finalizada por</strong> : Supervisor
          </Box>
        ) : details?.endedBy === "client" ? (
          <Box>
            <strong>Atención finalizada por</strong> : Cliente
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
};

export default HistoryMessages;
