import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { environment } from "../../config";
import { TicketContext } from "../../context";
import service from "../../service";
import FilePreviewList from "../file-preview-list/file-preview-list";
import FilePreview from "../file-preview/file-preview";
import ProfileHeader from "../profile-header/profile-header";
import RawHTML from "../raw-html/raw-html";
import "./ticket-details-table.scss";

export const TicketDetailsTable = () => {
  const { ticket } = useContext(TicketContext);
  const [state, setState] = useState({ files: [] });

  const { files = {}, ...res } = ticket.formData || {};
  const data = { ...res };

  const ids = Object.entries(files).map(([key, value]) => value);

  return (
    <div className="ticket-details">
      <div className="ticket-details-table">
        {Object.keys(data).map((key, index) => {
          return (
            <div className="ticket-details-table__row" key={index}>
              <div className="ticket-details-table__key">{key}:</div>
              <div className="ticket-details-table__value">{data[key]}</div>
            </div>
          );
        })}

        {ids && <FilePreviewList files={ids}></FilePreviewList>}
      </div>
      <div className="ticket-details-messages">
        {ticket?.messages?.map((message, index) => {
          return (
            <>
              <div className="ticket-details-messages__message" key={index}>
                <ProfileHeader
                  key={index}
                  ticket={{
                    name: message?.agent?.name || "Sistema",
                    description: ticket?.description,
                    createdAt: message?.date,
                  }}
                ></ProfileHeader>
                <div className="ticket-details-messages__message__content">
                  <RawHTML>{message.text}</RawHTML>
                </div>
                {message.files.length ? (
                  <FilePreviewList files={message.files}></FilePreviewList>
                ) : null}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
