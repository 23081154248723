import axios from "axios";
import { TOKEN_KEY, API_BASE_URL } from "../config";

const requestHandler = (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) config.headers.Authorization = `videolink ${token}`;
  return config;
};

const responseHandler = (response) => {
  const { data: dataResponse, status } = response;
  if (status === 200) {
    return dataResponse;
  } else {
    return Promise.reject({ dataResponse, status });
  }
};

const baseURL = API_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true;
  },
});

axiosInstance.interceptors.request.use(requestHandler, (error) =>
  Promise.reject(error)
);

axiosInstance.interceptors.response.use(responseHandler, (error) =>
  Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.status === 401) {
      window.location.href = "/";
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
