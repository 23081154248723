import { useState } from "react";
import DateObject from "react-date-object";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import FilterAltIcon from "../../assets/filternot.svg";
import FilterActIcon from "../../assets/filter.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Skeleton from "@mui/material/Skeleton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import MenuItem from "@mui/material/MenuItem";
import { getCanalIcon } from "../../utils";
import TablePaginationActions from "../../components/tableActions";
import GeneralInfo from "../../components/historyGeneralInfo";
import HistoryMessages from "../../components/historyMessages";
import HistoryFiles from "../../components/historyFiles";
import HistoryClient from "../../components/HistoryClient";
import service from "../../service";

const DesktopView = ({
  state,
  handleToggle,
  handleSidebar,
  onLogout,
  container,
  navigate,
  connect,
  handleDialogClose,
  handleAgentConnect,
  setSearch,
  setFilterHistory,
  isLoadingPageTransition,
  setSearchByUsername,
  searchByUsername,
  totalChats,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  handleDialogDetails,
  getDetails,
  handleDetails,
  handleDetailsScreen,
  height,
  loading,
  loadingRows,
}) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElDetail, setAnchorElDetail] = useState(null);

  const [rowSelected, setRowSelected] = useState(undefined);
  function findValueByLabel(labels, data, type) {
    const labelSet = new Set(labels.map((label) => label.toLowerCase().trim()));
    if (data === undefined) {
      return undefined;
    }
    const field = data.find(
      (el) => el._id === type && labelSet.has(el.label.trim().toLowerCase())
    );
    if (field === undefined) {
      return undefined;
    }

    return field.value;
  }

  return (
    <>
      <Box style={{ display: "flex", overflow: "hidden" }}>
        <Navbar
          isMobile={false}
          page={"history"}
          currentChat={state.currentChat}
          isLoading={loading}
          isOpenSideMenu={state.isOpen}
          handleDrawerToggle={handleSidebar}
          setSearch={setSearch}
          search={state.search}
          setSearchByUsername={setSearchByUsername}
          searchByUsername={searchByUsername}
        />

        <Box
          component="nav"
          sx={{
            width: { md: state.isOpen ? 240 : 80 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folder"
        >
          <Sidebar
            typeConnection={""}
            drawerwidth={state.isOpen ? 240 : 80}
            handleDrawerToggle={handleSidebar}
            container={container}
            isOpenSideMenu={state.isOpen}
            onHandlePage={(pathname) => navigate(pathname)}
            onLogout={onLogout}
            page={"history"}
            user={state.user}
            openDialogClose={handleDialogClose}
            groups={state.groups}
            abilities={state.abilities}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            currentChat={state.currentChat}
            queue={state.queue}
            chatList={state.chatList}
            avatar={state?.user?.avatar}
            height={height}
            isLoading={loading}
          />
        </Box>
        <Box
          style={{
            height: "calc(100vh)",
            width: `calc(100% - ${state.isOpen ? 240 : 80}px)`,
            backgroundColor: "#F4F4F4",
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <Box
            style={{
              // display: loading && "flex",
              width: "100%",
              paddingTop: 80,
            }}
          >
            <Box style={{ display: "flex" }}>
              {loading ? (
                <Skeleton
                  style={{ borderRadius: 59, marginRight: 8 }}
                  variant="rectangular"
                  width={120}
                  height={30}
                />
              ) : (
                <Button
                  disabled={isLoadingPageTransition}
                  onClick={() => setFilterHistory("day")}
                  style={{
                    borderRadius: 59,
                    background:
                      state.filterHistory === "day" ? "#4F46E5" : "#FFFFFF",
                    textTransform: "capitalize",
                    width: 120,
                    color:
                      state.filterHistory === "day" ? "#FFFFFF" : "#394154",

                    marginRight: 8,
                  }}
                >
                  Dia
                </Button>
              )}
              {loading ? (
                <Skeleton
                  style={{ borderRadius: 59, marginRight: 8 }}
                  variant="rectangular"
                  width={120}
                  height={30}
                />
              ) : (
                <Button
                  disabled={isLoadingPageTransition}
                  onClick={() => setFilterHistory("week")}
                  style={{
                    borderRadius: 59,
                    background:
                      state.filterHistory === "week" ? "#4F46E5" : "#FFFFFF",

                    textTransform: "capitalize",
                    width: 120,
                    color:
                      state.filterHistory === "week" ? "#FFFFFF" : "#394154",
                    marginRight: 8,
                  }}
                >
                  Semana
                </Button>
              )}
              {loading ? (
                <Skeleton
                  style={{ borderRadius: 59, marginRight: 8 }}
                  variant="rectangular"
                  width={120}
                  height={30}
                />
              ) : (
                <Button
                  disabled={isLoadingPageTransition}
                  onClick={() => setFilterHistory("month")}
                  style={{
                    borderRadius: 59,
                    background:
                      state.filterHistory === "month" ? "#4F46E5" : "#FFFFFF",
                    textTransform: "capitalize",
                    width: 120,
                    color:
                      state.filterHistory === "month" ? "#FFFFFF" : "#394154",
                    marginRight: 8,
                  }}
                >
                  Mes
                </Button>
              )}
              {loading ? (
                <Skeleton
                  style={{ borderRadius: 59, marginRight: 8 }}
                  variant="rectangular"
                  width={120}
                  height={30}
                />
              ) : (
                <Button
                  onClick={() => setFilterHistory("year")}
                  disabled={isLoadingPageTransition}
                  style={{
                    background:
                      state.filterHistory === "year" ? "#4F46E5" : "#FFFFFF",

                    borderRadius: 59,
                    textTransform: "capitalize",
                    width: 120,
                    color:
                      state.filterHistory === "year" ? "#FFFFFF" : "#394154",
                    marginRight: 8,
                  }}
                >
                  Año
                </Button>
              )}
              {loading ? (
                <Skeleton
                  style={{
                    borderRadius: 59,
                    marginLeft: "auto",
                    marginRight: 8,
                  }}
                  variant="rectangular"
                  width={120}
                  height={30}
                />
              ) : (
                <Box
                  style={{
                    marginLeft: "auto",
                    marginRight: 8,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    onClick={(event) => {
                      setAnchorElFilter(event.currentTarget);
                    }}
                    style={{
                      borderRadius: 59,
                      background:
                        anchorElFilter || state.filterChecked.length > 0
                          ? "#4F46E5"
                          : "#FFFFFF",
                      textTransform: "capitalize",
                      width: 120,
                      color:
                        anchorElFilter || state.filterChecked.length > 0
                          ? "#FFFFFF"
                          : "#394154",
                    }}
                    id={`basic-menu-filter`}
                    aria-controls={
                      anchorElFilter ? `basic-menu-filter` : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={anchorElFilter ? "true" : undefined}
                  >
                    <img
                      style={{ width: 18 }}
                      src={
                        anchorElFilter || state.filterChecked.length > 0
                          ? FilterActIcon
                          : FilterAltIcon
                      }
                      alt=""
                    />

                    {<>&nbsp; Filtro</>}
                  </Button>
                </Box>
              )}
            </Box>

            <Menu
              id={`basic-menu-filter`}
              anchorEl={anchorElFilter}
              open={anchorElFilter ? true : false}
              onClose={() => {
                setAnchorElFilter(null);
              }}
              MenuListProps={{
                "aria-labelledby": `basic-menu-filter`,
              }}
            >
              <Box
                style={{
                  // height: "350px",
                  width: "200px",
                  padding: 12,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                }}
              >
                {state.filterHistoryItems.map((filterValue, index) => {
                  return (
                    <>
                      <Box style={{ display: "flex" }} key={index}>
                        {filterValue.title}
                      </Box>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {filterValue.items.map((value) => {
                          const labelId = `checkbox-list-label-${value}`;
                          return (
                            <ListItem key={value.value} disablePadding>
                              <ListItemButton
                                role={undefined}
                                onClick={() => handleToggle(value.value)}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      state.filterChecked.indexOf(
                                        value.value
                                      ) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    disabled={isLoadingPageTransition}
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={`${value.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </>
                  );
                })}
              </Box>
            </Menu>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
              }}
            >
              <Paper style={{ width: "100%" }}>
                {loading ? (
                  <Skeleton
                    style={{
                      marginRight: 6,
                      marginBottom: 24,
                      width: "100%",
                      height: `calc(100vh - 455px)`,
                    }}
                    variant="rectangular"
                  />
                ) : (
                  <TableContainer
                    style={{
                      width: "100%",
                      maxHeight: `calc(100vh - 200px)`,
                    }}
                  >
                    <Table aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#848484" }}
                          >
                            Nombre
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#848484" }}
                          >
                            Fecha
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#848484" }}
                          >
                            Correo
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#848484" }}
                          >
                            Estatus
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#848484" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loadingRows
                          ? [1, 2, 3, 4, 5].map((item) => {
                              return (
                                <TableRow
                                  onClick={() => {
                                    // console.log("");
                                    // getDetails(row);
                                  }}
                                  key={item}
                                  className="classRowHistoryVL"
                                >
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Skeleton
                                      variant="circular"
                                      width={52}
                                      height={52}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      paddingTop: 8,
                                      paddingBottom: 8,

                                      paddingLeft: 8,
                                      paddingRight: 8,
                                    }}
                                  >
                                    <Skeleton
                                      variant="text"
                                      style={{ width: "100%" }}
                                      height={52}
                                    />
                                  </TableCell>

                                  <TableCell align="center">
                                    <Skeleton
                                      variant="text"
                                      style={{ width: "100%" }}
                                      height={52}
                                    />
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{
                                      width: 40,
                                    }}
                                  >
                                    <Skeleton
                                      variant="text"
                                      style={{
                                        width: "100%",
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                      }}
                                      height={52}
                                    />
                                  </TableCell>

                                  <TableCell align="center">
                                    <Skeleton
                                      variant="text"
                                      style={{ width: "100%" }}
                                      height={52}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : state.history.map((row, index) => {
                              const { contactV3 } = row;
                              let clientName = "No identificado";
                              let clientEmail = "No identificado";
                              if (contactV3) {
                                const { firstName, lastName, email } =
                                  contactV3;

                                if (firstName) {
                                  clientName = `${firstName} ${
                                    lastName ?? ""
                                  }`.trim();
                                }

                                if (email) {
                                  clientEmail = email;
                                }
                              }

                              return (
                                <TableRow
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    const element = document.getElementById(
                                      `menu-${index}`
                                    );
                                    element.click();
                                  }}
                                  key={row._id}
                                  className="classRowHistoryVL"
                                >
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    style={{
                                      display:
                                        isLoadingPageTransition && "flex",
                                      justifyContent:
                                        isLoadingPageTransition && "center",
                                    }}
                                  >
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="circular"
                                        width={52}
                                        height={52}
                                      />
                                    ) : (
                                      <img
                                        src={getCanalIcon(row.channel)}
                                        alt="canal"
                                        style={{ width: "48px" }}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      paddingTop: 8,
                                      paddingBottom: 8,

                                      paddingLeft: 8,
                                      paddingRight: 8,
                                    }}
                                  >
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="text"
                                        style={{ width: "100%" }}
                                        height={52}
                                      />
                                    ) : (
                                      clientName
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="text"
                                        style={{ width: "100%" }}
                                        height={52}
                                      />
                                    ) : (
                                      new DateObject(row.createdAt).format(
                                        "DD-MM-YYYY"
                                      )
                                    )}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{
                                      width: 40,
                                    }}
                                  >
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="text"
                                        style={{
                                          width: "100%",
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                        }}
                                        height={52}
                                      />
                                    ) : (
                                      clientEmail
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="text"
                                        style={{ width: "100%" }}
                                        height={52}
                                      />
                                    ) : row.status === "ended" ? (
                                      "Terminado"
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      id={`menu-${index}`}
                                      onClick={(e) => {
                                        console.log(e);
                                        setAnchorElDetail(e.currentTarget);
                                        setRowSelected(row);
                                      }}
                                    >
                                      <MoreVertIcon
                                        style={{ color: "#757575" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}

                        <Menu
                          id="basic-menu"
                          anchorEl={anchorElDetail}
                          open={rowSelected}
                          onClose={() => {
                            setAnchorElDetail(null);
                            setRowSelected(undefined);
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          MenuListProps={{
                            style: {
                              // width: "100%",
                              // display: "flex",
                              // justifyContent: "center",
                            },
                          }}
                          PaperProps={{
                            style: {
                              borderRadius: 25,
                              padding: 8,
                              minWidth: 200,
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              getDetails(rowSelected);
                              setAnchorElDetail(null);
                              setRowSelected(undefined);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: 16,
                              color: "#394154",
                              fontFamily: "Rubik",
                              borderRadius: 25,
                            }}
                          >
                            Detalle
                          </MenuItem>
                        </Menu>
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "Todos", value: -1 },
                            ]}
                            colSpan={6}
                            count={totalChats}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": "items per page",
                              },
                            }}
                            labelRowsPerPage="Filas"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            backIconButtonProps={isLoadingPageTransition}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </Box>
            <Dialog
              onClose={() => {
                handleDialogDetails(false);
                handleDetails({
                  messages: [],
                  details: undefined,
                  files: [],
                });
                handleDetailsScreen("general");
              }}
              open={state.openDialogDetails}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="md"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1B0845",
                }}
              >
                <div style={{ color: "#FFFFFF" }}>Detalles</div>
                <IconButton
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    handleDialogDetails(false);
                    handleDetails({
                      messages: [],
                      details: undefined,
                      files: [],
                    });
                    handleDetailsScreen("general");
                  }}
                >
                  <CloseIcon
                    fontSize="large"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      borderRadius: "100%",
                      color: "#FFFFFF",
                      padding: "4px",
                    }}
                  />
                </IconButton>
              </DialogTitle>
              <DialogContent
                style={{ marginBottom: "12px", marginTop: 8, height: 500 }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "solid 1px #E0E0E0",
                    alignItems: "center",
                    marginBottom: 24,
                    position: "fixed",
                    background: "#FFFFFF",
                    zIndex: 9999,
                    width: "100%",
                    maxWidth: 850,
                  }}
                >
                  {loading ? (
                    <Skeleton
                      style={{ marginRight: 6, marginBottom: 24 }}
                      variant="rectangular"
                      width={130}
                      height={30}
                    />
                  ) : (
                    <Button
                      style={{
                        marginRight: 6,
                        fontSize: 16,
                        color: "#394154",
                        paddingBottom: 12,
                        borderBottom:
                          state.historyDetailsScreen === "general" &&
                          "solid 2px",
                        borderRadius: 0,
                        textTransform: "inherit",
                      }}
                      onClick={() => handleDetailsScreen("general")}
                    >
                      Información general
                    </Button>
                  )}
                  {loading ? (
                    <Skeleton
                      style={{ marginRight: 6, marginBottom: 24 }}
                      variant="rectangular"
                      width={130}
                      height={30}
                    />
                  ) : (
                    <Button
                      style={{
                        textTransform: "capitalize",
                        marginRight: 6,
                        fontSize: 16,
                        color: "#394154",
                        paddingBottom: 12,
                        borderRadius: 0,
                        borderBottom:
                          state.historyDetailsScreen === "messages" &&
                          "solid 2px",
                      }}
                      onClick={() => handleDetailsScreen("messages")}
                    >
                      Mensajes
                    </Button>
                  )}
                  {loading ? (
                    <Skeleton
                      style={{ marginRight: 6, marginBottom: 24 }}
                      variant="rectangular"
                      width={130}
                      height={30}
                    />
                  ) : (
                    <Button
                      style={{
                        textTransform: "inherit",
                        marginRight: 6,
                        fontSize: 16,
                        borderBottom:
                          state.historyDetailsScreen === "files" && "solid 2px",

                        paddingBottom: 12,
                        color: "#394154",
                        borderRadius: 0,
                      }}
                      onClick={() => handleDetailsScreen("files")}
                    >
                      Archivos adjuntos
                    </Button>
                  )}
                  {loading ? (
                    <Skeleton
                      style={{ marginRight: 6, marginBottom: 24 }}
                      variant="rectangular"
                      width={130}
                      height={30}
                    />
                  ) : (
                    <Button
                      style={{
                        textTransform: "inherit",
                        marginRight: 6,
                        fontSize: 16,
                        color: "#394154",
                        borderBottom:
                          state.historyDetailsScreen === "client" &&
                          "solid 2px",

                        paddingBottom: 12,
                        borderRadius: 0,
                      }}
                      onClick={() => handleDetailsScreen("client")}
                    >
                      Información del cliente
                    </Button>
                  )}
                </Box>
                <Box style={{ marginTop: 42 }} />
                {state.historyDetailsScreen === "general" ? (
                  <GeneralInfo
                    details={state.historyDetails.details}
                    isLoading={loading}
                  />
                ) : state.historyDetailsScreen === "messages" ? (
                  <HistoryMessages
                    messages={state.historyDetails.messages}
                    agents={state.agents}
                    details={state.historyDetails.details}
                  />
                ) : state.historyDetailsScreen === "files" ? (
                  <HistoryFiles
                    files={state.historyDetails.files}
                    agents={state.agents}
                    details={state.historyDetails.details}
                  />
                ) : (
                  <HistoryClient
                    details={state.historyDetails.details}
                    user={state.user}
                  />
                )}
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DesktopView;
