import { TicketContact } from "../../components/ticket-contact/ticket-contact";
import { TicketDetails } from "../../components/ticket-details/ticket-details";
import { TicketList } from "../../components/ticket-list/ticket-list";
import { TicketProvider } from "../../context/provider";
import "./ticket.scss";

const DesktopView = () => {
  return (
    <>
      <TicketProvider>
        <div className="ticket-container">
          <TicketList />
          <TicketDetails />
          <TicketContact />
        </div>
      </TicketProvider>
    </>
  );
};

export default DesktopView;
