import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import DateObject from "react-date-object";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import AttachmentIcon from "../../assets/AttachmentIcon.png";
import DownloadIcon from "@mui/icons-material/Download";

import LineChatItem from "../../components/lineChatItem";
import storageBox from "../../assets/storageBox.svg";
import LinePeopleItem from "../../components/linePeopleItem";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import backgroundVideoLink from "../../assets/backgroundChat2.svg";
import MessagingBar from "../../components/messagingBar";
import ChatItem from "../../components/chatItem";
import loading from "../../assets/loading.svg";
import transfer from "../../assets/transferItem.svg";
import user from "../../assets/userItem.svg";
import warning from "../../assets/warning_outlineItem.svg";
import file from "../../assets/fileItem.svg";
import AttentionInformation from "../../components/attentionInformation";
import AdditionalInformation from "../../components/additionalInformation";
import { environment } from "../../config";
import { CircularProgress } from "@mui/material";
import service from "../../service";
import { CardChat, WrapperCardEmpy } from "../../components/cardChat/cardChat";
import { ContactFormNew } from "../../components/contactForm/contactForm";
import { ListDocument } from "../../components/listDocument/listDocument";

const MobileView = ({
  state,
  container,
  handleSidebar,
  setConfigDialog,
  onLogout,
  handleDialogClose,
  setCurrentChat,
  socket,
  onSendMessage,
  onUploadImageMessage,
  connect,
  getCurrentFiles,
  getCurrentChat,
  handleAgentConnect,
  setIsPreviewImages,
  setSelectedImage,
  navigate,
  isLoading,
  cleanInput,
  height,
  onUpdateDynamicForm,
  setIsLoading,
  loading,
  width,
  createContact,
  getMoreMessages,
  loadingMoreMessages,
  contactsInfo,
  mergeContact,
  getContactsInfo,
}) => {
  const [openAction, setOpenAction] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [showClientInfo, setShowClientInfo] = useState(false);

  const StyledMenu = styled((props) => (
    <Menu
      levation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 4,
      marginTop: theme.spacing(2.2),
      minWidth: 233,
      maxWidth: 250,
      backgroundColor: "#FFFFFF",
      color: "white",
      boxShadow:
        "0px 14px 29px rgba(0, 0, 0, 0.1), 0px 1.75302px 10.5884px rgba(0, 0, 0, 0.05)",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <>
      <Box>
        <Navbar
          isMobile={true}
          handleSidebar={handleSidebar}
          currentChat={state.currentChat}
          setCurrentChat={setCurrentChat}
          openAction={openAction}
          setOpenAction={setOpenAction}
          setAnchorElAction={setAnchorElAction}
          setConfigDialog={setConfigDialog}
          showClientInfo={showClientInfo}
          setShowClientInfo={setShowClientInfo}
          page="chat"
        />
        <Box
          component="nav"
          sx={{
            width: { md: state.isOpen ? 240 : 80 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folder"
        >
          <Sidebar
            typeConnection={""}
            drawerwidth={state.isOpen ? 240 : 80}
            handleDrawerToggle={handleSidebar}
            container={container}
            isOpenSideMenu={state.isOpen}
            onHandlePage={(pathname) => navigate(pathname)}
            onLogout={onLogout}
            page={"chat"}
            user={state.user}
            openDialogClose={handleDialogClose}
            groups={state.groups}
            abilities={state.abilities}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            isMobile={true}
            currentChat={state.currentChat}
            queue={state.queue}
            chatList={state.chatList}
            height={height}
            avatar={state?.user?.avatar}
          />
        </Box>
        <Box
          style={{
            width: state.isOpen ? `100%` : "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              height: "calc(100vh - 77px)",
              width: "100%",
              backgroundColor:
                (!state.currentChat || showClientInfo) && "#f5f5f5",
              backgroundImage:
                state.currentChat &&
                !showClientInfo &&
                `url(${backgroundVideoLink})`,
            }}
          >
            {!state.currentChat ? (
              <>
                <Box
                  style={{
                    backgroundColor: "#FFFFFF",
                    paddingTop: 18,
                    // paddingLeft: 24,
                    paddingBottom: 18,
                  }}
                >
                  <Box
                    style={{
                      marginLeft: "13px",
                      fontWeight: "500",
                      marginBottom: "8px",
                      color: "#000000",
                    }}
                  >
                    {`Personas en cola: ${
                      state.queue.length > 0 ? state.queue.length : 0
                    }`}
                  </Box>
                  <Box
                    style={{
                      height: "168px",

                      marginBottom: "8px",
                      padding: "8px",
                      display: "-webkit-box",
                      overflowX: "scroll",
                      maxWidth: "100%",
                      // display: "flex",
                      paddingTop: "24px",
                      filter: !connect && "blur(1px)",
                    }}
                  >
                    {state.queue.length > 0 ? (
                      state.queue.map((item, index) => (
                        <LinePeopleItem
                          key={index}
                          item={item}
                          groups={state.groups}
                        />
                      ))
                    ) : (
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          // alignItems: "center",
                          justifyContent: "center",
                          placeContent: "space-evenly",
                        }}
                      >
                        <Box
                          style={{
                            width: 58,
                            height: 58,
                            borderRadius: "100%",
                            backgroundColor: "#DFDFDF",
                          }}
                        ></Box>
                        <Box
                          style={{
                            width: 58,
                            height: 58,
                            borderRadius: "100%",
                            backgroundColor: "#DFDFDF",
                          }}
                        ></Box>
                        <Box
                          style={{
                            width: 58,
                            height: 58,
                            borderRadius: "100%",
                            backgroundColor: "#DFDFDF",
                          }}
                        ></Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    style={{
                      marginLeft: "12px",
                      marginRight: "12px",
                      marginBottom: "32px",
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        borderRadius: "25.5px",
                        backgroundColor:
                          state.queue?.filter(
                            (chat) =>
                              state.user?.groups?.includes(chat?.group) &&
                              state.user?.abilities?.includes(chat?.ability._id)
                          ).length === 0 ||
                          !connect ||
                          state.chatList.length === 5 ||
                          loading ||
                          isLoading
                            ? "#C5C5C5"
                            : "#EA345F",
                        color: "white",
                        fontSize: 18,
                      }}
                      disabled={
                        state.queue.filter(
                          (chat) =>
                            state.user?.groups?.includes(chat.group) &&
                            state.user?.abilities?.includes(chat.ability._id)
                        ).length === 0 ||
                        state.chatList.length === 5 ||
                        loading ||
                        !connect ||
                        isLoading
                      }
                      onClick={async () => {
                        setIsLoading(true);
                        await getContactsInfo();
                        socket?.emit("next");
                      }}
                    >
                      {loading || isLoading ? (
                        <CircularProgress
                          style={{ color: "#FFFFFF", width: 32, height: 32 }}
                        />
                      ) : (
                        "TOMAR ATENCIÓN"
                      )}
                    </Button>
                  </Box>
                </Box>
                <Box
                  style={{
                    marginTop: 8,
                    marginLeft: "13px",
                    fontWeight: "500",
                    color: "#000000",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Atenciones abiertas
                </Box>
                <Box
                  style={{
                    maxHeight: "calc(100vh - 430px)",
                    // overflowY: "scroll",
                    overflowX: "hidden",
                    height:
                      state.chatList.length === 0 && "calc(100vh - 380px)",
                    display: state.chatList.length === 0 && "flex",
                    alignItems: state.chatList.length === 0 && "center",
                    justifyContent: state.chatList.length === 0 && "center",
                  }}
                >
                  {state.chatList.length > 0 ? (
                    state.chatList.map((item, index) => (
                      <CardChat
                        key={index}
                        item={item}
                        getCurrentChat={getCurrentChat}
                      />
                    ))
                  ) : (
                    <WrapperCardEmpy />
                  )}
                </Box>
              </>
            ) : showClientInfo ? (
              <Box>
                <Box
                  style={{
                    padding: "16px",
                    overflowX: "scroll",
                    maxHeight: `calc(100vh - 100px)`,
                  }}
                  id="contianed-chat-vl"
                >
                  <AttentionInformation
                    currentChat={state.currentChat}
                    groups={state.groups}
                  />
                  {state.currentChat && (
                    <ContactFormNew
                      generalState={state}
                      getCurrentChat={getCurrentChat}
                      getMoreMessages={getMoreMessages}
                    />
                  )}

                  <ListDocument state={state} />
                  {/* {state.messages.filter(
                    (message) =>
                      message?.content?.id || message?.content?.urlContentType
                  ).length > 0 && (
                    <>
                      <Box
                        style={{
                          marginLeft: "12px",
                          color: "#000000",
                          marginBottom: "4px",
                        }}
                      >
                        Documentos - PDF - Fotos
                      </Box>
                      <Box>
                        <Box
                          style={{
                            background: "#FFFFFF",
                            paddingLeft: "12px",
                            paddingRight: "8px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          {state.messages
                            .filter(
                              (message) =>
                                message?.content?.id ||
                                message?.content?.urlContentType
                            )
                            .map((filterMessage, index) => (
                              <Box key={index}>
                                <Box
                                  style={{
                                    borderRadius: "36px",
                                    border: "1px solid #C4C4C4",
                                    boxSizing: "border-box",
                                    padding: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box style={{ marginRight: "8px" }}>
                                    <a
                                      href={
                                        filterMessage.content.id
                                          ? ` ${environment.storage}/c/${filterMessage.content.id}`
                                          : filterMessage.content.url
                                      }
                                      target="_blank"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <IconButton>
                                        <img
                                          src={AttachmentIcon}
                                          alt="attachment"
                                          style={{ width: "24px" }}
                                        />{" "}
                                      </IconButton>
                                    </a>
                                  </Box>
                                  <Box
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {state.currentChat.channel === "web" ||
                                    state.currentChat.channel === "web-widget"
                                      ? filterMessage.content.name
                                      : state.currentChat.channel === "facebook"
                                      ? "Archivo de facebook"
                                      : state.currentChat.channel ===
                                          "whatsapp" && "Archivo de whatsapp"}
                                  </Box>
                                  <Box style={{ marginLeft: "auto" }}>
                                    <a
                                      href={
                                        filterMessage.content.id
                                          ? ` ${environment.storage}/c/${filterMessage.content.id}`
                                          : filterMessage.content.url
                                      }
                                      target="_blank"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <IconButton>
                                        <DownloadIcon />
                                      </IconButton>
                                    </a>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    padding: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      fontSize: "12px",
                                      marginRight: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {filterMessage.content.id
                                      ? filterMessage.content.name.split(".")[
                                          filterMessage.content.name.split(".")
                                            .length - 1
                                        ]
                                      : ""}
                                  </Box>
                                  <Box style={{ fontSize: "12px" }}>
                                    {filterMessage.content.id
                                      ? `${parseFloat(
                                          state.currentFiles?.find(
                                            (file) =>
                                              file._id ===
                                              filterMessage.content.id
                                          )?.size / 1024
                                        ).toFixed(2)} KB`
                                      : ""}
                                  </Box>
                                  <Box
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    {new DateObject(
                                      new Date(filterMessage.date)
                                    ).format("hh:mm a")}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    </>
                  )} */}
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  style={{
                    padding: "16px",
                    overflowX: "scroll",
                    maxHeight: `calc(100vh - 153px)`,
                    // paddingBottom: "70px",
                  }}
                  id="contianed-chat-vl"
                >
                  {isLoading ? (
                    <>
                      <Box style={{ textAlign: "center" }}>
                        <Box>
                          <img
                            style={{ width: "32px", marginTop: "48px" }}
                            src={loading}
                            alt=""
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    state.messages.map((item, index) => {
                      return (
                        <ChatItem
                          currentChat={state.currentChat}
                          isUser={!item.isAgent}
                          key={index}
                          message={item.content?.text}
                          id={item.content?.id}
                          name={item.content?.name}
                          agent={state.user}
                          date={item.date}
                          agents={state.agents}
                          client={state.currentChat.menbers[0]}
                          item={item}
                          setIsImgView={setIsPreviewImages}
                          setSelectedImage={setSelectedImage}
                        />
                      );
                    })
                  )}
                </Box>
                <MessagingBar
                  currentChat={state.currentChat}
                  onSendMessage={onSendMessage}
                  onInitCall={console.log}
                  onUploadImageMessage={onUploadImageMessage}
                  user={state.user}
                  getCurrentFiles={getCurrentFiles}
                  cleanInput={cleanInput}
                  state={state}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorElAction}
        open={openAction}
        onClose={() => {
          setAnchorElAction();
          setOpenAction(false);
        }}
        MenuListProps={{
          "aria-labelledby": "b]asic-button",
        }}
      >
        <MenuItem
          style={{ height: 60 }}
          onClick={() => {
            setOpenAction(false);
            setAnchorElAction(null);
            setConfigDialog({ open: true, type: "transfer" });
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box style={{ color: "#5F5F5F", width: 40, textAlign: "left" }}>
              <img src={transfer} alt="" />
            </Box>
            <Box style={{ color: "#5F5F5F" }}>Transferir atención</Box>
          </Box>
        </MenuItem>
        <MenuItem
          style={{ height: 60 }}
          onClick={() => {
            setOpenAction(false);
            setAnchorElAction(null);
            setShowClientInfo(true);
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box style={{ color: "#5F5F5F", width: 40, textAlign: "left" }}>
              <img src={user} alt="" />{" "}
            </Box>
            <Box style={{ color: "#5F5F5F" }}>Datos adicionales</Box>
          </Box>
        </MenuItem>
        <MenuItem
          style={{ height: 60 }}
          onClick={() => {
            setIsPreviewImages(true);
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ color: "#5F5F5F", width: 40, paddingLeft: 6 }}>
              <img src={file} alt="" />
            </Box>
            <Box style={{ color: "#5F5F5F" }}>Documentos adjuntos</Box>
          </Box>
        </MenuItem>
        <MenuItem
          style={{ height: 60 }}
          onClick={() => {
            setOpenAction(false);
            setAnchorElAction(null);
            setConfigDialog({ open: true, type: "report" });
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ color: "#5F5F5F", width: 40, textAlign: "left" }}>
              <img src={warning} alt="" />
            </Box>
            <Box style={{ color: "#5F5F5F" }}>Reportar</Box>
          </Box>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default MobileView;
