import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import logo from "../assets/icono.png";
import companyLogoSmall from "../assets/svg/omniflow-small.svg";
import { consts } from "../const";

const ChangePasswordForm = ({ isMobile, isLoading, changePassword }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [validatePasswordForOneLetter, setValidatePasswordForOneLetter] =
    useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const [
    validatePasswordForOneLetterUppercase,
    setValidatePasswordForOneLetterUppercase,
  ] = useState(false);
  const [validatePasswordForOneNumber, setValidatePasswordForOneNumber] =
    useState(false);
  const [validatePasswordLength, setValidatePasswordLength] = useState(false);

  const regexForOneLetter = /[a-zA-Z]/;
  const regexForOneUppecaseLetter = /[A-Z]/;
  const regexForOneNumber = /[0-9]/;

  useEffect(() => {
    if (password.length >= 8) {
      setValidatePasswordLength(true);
    } else {
      setValidatePasswordLength(false);
    }
    if (regexForOneLetter.test(password)) setValidatePasswordForOneLetter(true);
    else setValidatePasswordForOneLetter(false);
    if (regexForOneUppecaseLetter.test(password))
      setValidatePasswordForOneLetterUppercase(true);
    else setValidatePasswordForOneLetterUppercase(false);
    if (regexForOneNumber.test(password)) setValidatePasswordForOneNumber(true);
    else setValidatePasswordForOneNumber(false);
  }, [password]);

  useEffect(() => {}, []);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          style={{
            padding: "24px",
            boxShadow: !isMobile && "0px 1px 6px rgba(0, 0, 0, 0.15)",
            minHeight: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // minWidth: 500,
          }}
        >
          <Box
            style={{
              marginTop: "8px",
              display: "flex",
              alignItems: "center",
              marginBottom: "32px",
              justifyContent: "center",
            }}
          >
            <img
              src={companyLogoSmall}
              style={{
                width: isMobile ? "45px" : "45px",
                heigth: isMobile ? "45px" : "45px",
              }}
              alt="logo"
            />
          </Box>
          <Box
            style={{
              fontSize: isMobile ? 20 : "15px",
              fontWeight: "bold",
              marginBottom: "24px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Generar nueva contraseña
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: isMobile ? 12 : "24px",
              // marginBottom: "24px",
            }}
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  className: "tooltip-class-agent",
                },
              }}
              style={{ width: "80%" }}
              disableHoverListener
              open={openTooltip}
              title={
                <div style={{ padding: 24 }}>
                  <div
                    style={{
                      color: validatePasswordForOneLetter
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    Al menos una letra
                  </div>

                  <div
                    style={{
                      color: validatePasswordForOneLetterUppercase
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    Al menos una letra mayúscula
                  </div>
                  <div
                    style={{
                      color: validatePasswordForOneNumber
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    Al menos un número
                  </div>
                  <div
                    style={{
                      color: validatePasswordLength ? "#0EF00F" : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    Al menos 8 carácteres
                  </div>
                </div>
              }
              placement="bottom-start"
            >
              <FormControl
                fullWidth
                style={{ marginBottom: "6px", width: "100%" }}
                sx={{ width: { xs: "300px", sm: "430px" } }}
              >
                <OutlinedInput
                  fullWidth
                  onFocus={() => setOpenTooltip(true)}
                  onBlur={() => setOpenTooltip(false)}
                  size={"small"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  style={{
                    borderRadius: "30px",
                    backgroundColor: "#F3F6F9",
                  }}
                  placeholder="Cambiar contraseña"
                  type={isShowPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <PasswordIcon style={{ color: "#A3A3A3" }} />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setIsShowPassword(!isShowPassword)}
                      >
                        {isShowPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Tooltip>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginBottom:
                confirmPassword !== password && confirmPassword !== ""
                  ? ""
                  : isMobile
                  ? 66
                  : "55px",
              // marginBottom: "24px",
            }}
          >
            <FormControl
              fullWidth
              style={{ marginBottom: "6px", width: "100%" }}
              sx={{ width: { xs: "300px", sm: "430px" } }}
            >
              <OutlinedInput
                fullWidth
                size={"small"}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#F3F6F9",
                }}
                placeholder="Confirmar nueva contraseña"
                type={isShowConfirmPassword ? "text" : "password"}
                startAdornment={
                  <InputAdornment position="start">
                    <PasswordIcon style={{ color: "#A3A3A3" }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setIsShowConfirmPassword(!isShowConfirmPassword)
                      }
                    >
                      {isShowConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          {confirmPassword !== password && confirmPassword !== "" && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: isMobile ? 66 : "55px",
              }}
            >
              <Box style={{ width: "5%", textAlign: "center" }}></Box>
              <Box style={{ color: "#D41924", fontSize: 12 }}>
                No coincide contraseña
              </Box>
            </Box>
          )}
          <Box style={{ textAlign: "center" }}>
            <Button
              size={isMobile ? "small" : "medium"}
              variant="contained"
              disabled={
                password !== "" && confirmPassword !== ""
                  ? validatePasswordForOneLetter &&
                    validatePasswordForOneLetterUppercase &&
                    validatePasswordForOneNumber &&
                    validatePasswordLength &&
                    password === confirmPassword
                    ? false
                    : true
                  : true
              }
              style={{
                fontSize: isMobile && 16,
                maxWidth: isMobile && 300,
                backgroundColor:
                  password !== "" && confirmPassword !== ""
                    ? validatePasswordForOneLetter &&
                      validatePasswordForOneLetterUppercase &&
                      validatePasswordForOneNumber &&
                      validatePasswordLength &&
                      password === confirmPassword
                      ? "#EA345F"
                      : "#D6D8E8"
                    : "#D6D8E8",
                marginBottom: isMobile ? 20 : "32px",
                borderRadius: "25.5px",
                color: "white",
                textTransform: "capitalize",
              }}
              sx={{ width: { xs: "250px", sm: "350px" } }}
              onClick={() => changePassword(password)}
            >
              {isLoading ? (
                <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
              ) : (
                " Generar contraseña "
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChangePasswordForm;
