import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FilterAltIcon from "../../assets/filternot.svg";
import FilterActIcon from "../../assets/filter.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";

import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

import TablePaginationActions from "../../components/tableActions";
import GeneralInfo from "../../components/historyGeneralInfo";
import HistoryMessages from "../../components/historyMessages";
import HistoryFiles from "../../components/historyFiles";
import HistoryClient from "../../components/HistoryClient";
import service from "../../service";

const MobileView = ({
  state,
  handleToggle,
  handleSidebar,
  onLogout,
  container,
  navigate,
  connect,
  handleDialogClose,
  handleAgentConnect,
  setSearch,
  setFilterHistory,
  isLoadingPageTransition,
  setSearchByUsername,
  searchByUsername,
  totalChats,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  getDetails,
  handleDetails,
  handleDetailsScreen,
  height,
  loading,
  width,
}) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElDetail, setAnchorElDetail] = useState(null);
  function findValueByLabel(labels, data, type) {
    const labelSet = new Set(labels.map((label) => label.toLowerCase().trim()));

    const field = data.find(
      (el) => el._id === type && labelSet.has(el.label.trim().toLowerCase())
    );

    if (field === undefined) {
      return undefined;
    }

    return field.value;
  }
  const [rowSelected, setRowSelected] = useState(undefined);
  return (
    <>
      <Box>
        <Navbar
          isMobile={true}
          page={"history"}
          currentChat={state.currentChat}
          isLoading={loading}
          isOpenSideMenu={state.isOpen}
          handleDrawerToggle={handleSidebar}
          setSearch={setSearch}
          search={state.search}
          setSearchByUsername={setSearchByUsername}
          searchByUsername={searchByUsername}
          handleDetails={handleDetails}
          isDetails={state.historyDetails.details}
          handleSidebar={handleSidebar}
        />
        <Box
          component="nav"
          sx={{
            width: { md: state.isOpen ? 240 : 80 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folder"
        >
          <Sidebar
            typeConnection={""}
            drawerwidth={state.isOpen ? 240 : 80}
            handleDrawerToggle={handleSidebar}
            container={container}
            isOpenSideMenu={state.isOpen}
            onHandlePage={(pathname) => navigate(pathname)}
            onLogout={onLogout}
            page={"history"}
            user={state.user}
            openDialogClose={handleDialogClose}
            groups={state.groups}
            abilities={state.abilities}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            isMobile={true}
            currentChat={state.currentChat}
            queue={state.queue}
            chatList={state.chatList}
            height={height}
            avatar={state?.user?.avatar}
          />
        </Box>
        <Box
          style={{
            width: state.isOpen ? `100%` : "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              minHeight: "calc(100vh - 77px)",
              width: "100%",
              backgroundColor: "#f5f5f5",
              overflowY: "auto",
            }}
          >
            {state.historyDetails.details ? (
              <>
                <Box>
                  <Box
                    style={{
                      overflowX: "scroll",
                    }}
                    id="contianed-chat-vl"
                  >
                    <Box
                      style={{
                        height: 120,
                        backgroundColor: "#1B0845",
                        display: "flex",
                      }}
                    >
                      <Button
                        size="small"
                        style={{
                          marginRight: 6,
                          fontSize: 12,
                          color: "#FFFFFF",
                          paddingBottom: 12,
                          borderRadius: 0,
                          textTransform: "inherit",
                          width: "100%",
                          borderBottom:
                            state.historyDetailsScreen === "general" &&
                            "solid 2px #FF3160",
                        }}
                        onClick={() => handleDetailsScreen("general")}
                      >
                        Información general
                      </Button>
                      <Button
                        size="small"
                        style={{
                          marginRight: 6,
                          fontSize: 12,
                          color: "#FFFFFF",
                          paddingBottom: 12,
                          borderRadius: 0,
                          textTransform: "inherit",
                          width: "100%",
                          borderBottom:
                            state.historyDetailsScreen === "messages" &&
                            "solid 2px #FF3160",
                        }}
                        onClick={() => handleDetailsScreen("messages")}
                      >
                        Mensajes
                      </Button>
                      <Button
                        size="small"
                        style={{
                          marginRight: 6,
                          fontSize: 12,
                          color: "#FFFFFF",
                          paddingBottom: 12,
                          borderRadius: 0,
                          textTransform: "inherit",
                          width: "100%",
                          borderBottom:
                            state.historyDetailsScreen === "files" &&
                            "solid 2px #FF3160",
                        }}
                        onClick={() => handleDetailsScreen("files")}
                      >
                        Archivos adjuntos
                      </Button>
                      <Button
                        size="small"
                        style={{
                          marginRight: 6,
                          fontSize: 12,
                          color: "#FFFFFF",
                          paddingBottom: 12,
                          borderRadius: 0,
                          textTransform: "inherit",
                          width: "100%",
                          borderBottom:
                            state.historyDetailsScreen === "client" &&
                            "solid 2px #FF3160",
                        }}
                        onClick={() => handleDetailsScreen("client")}
                      >
                        Información del cliente
                      </Button>
                    </Box>
                    {state.historyDetailsScreen === "general" ? (
                      <GeneralInfo
                        details={state.historyDetails.details}
                        isLoading={state.isLoadingDetails}
                      />
                    ) : state.historyDetailsScreen === "messages" ? (
                      <Box style={{ padding: 8 }}>
                        <HistoryMessages
                          messages={state.historyDetails.messages}
                          agents={state.agents}
                          details={state.historyDetails.details}
                          width={width}
                        />
                      </Box>
                    ) : state.historyDetailsScreen === "files" ? (
                      <HistoryFiles
                        files={state.historyDetails.files}
                        agents={state.agents}
                        details={state.historyDetails.details}
                      />
                    ) : (
                      <HistoryClient
                        details={state.historyDetails.details}
                        user={state.user}
                      />
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <Box>
                <Box
                  style={{
                    padding: "8px",
                    overflowX: width < 321 ? "auto" : "hidden",
                    maxHeight: `calc(100vh - ${width > 320 ? 100 : 87})`,
                  }}
                  id="contianed-chat-vl"
                >
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      paddingTop: 8,
                      whiteSpace: "nowrap",
                      overflowX: width < 321 ? "auto" : "hidden",
                    }}
                    id="scrollbar-vl"
                  >
                    {loading ? (
                      <Skeleton
                        style={{ borderRadius: 59, marginRight: 8 }}
                        variant="rectangular"
                        width={120}
                        height={30}
                      />
                    ) : (
                      <Button
                        disabled={isLoadingPageTransition}
                        onClick={() => setFilterHistory("day")}
                        style={{
                          borderRadius: 59,
                          background:
                            state.filterHistory === "day"
                              ? "#4F46E5"
                              : "#FFFFFF",
                          textTransform: "capitalize",
                          width: width < 321 ? 60 : 120,
                          color:
                            state.filterHistory === "day"
                              ? "#FFFFFF"
                              : "#394154",

                          marginRight: 8,
                        }}
                      >
                        Dia
                      </Button>
                    )}
                    {loading ? (
                      <Skeleton
                        style={{ borderRadius: 59, marginRight: 8 }}
                        variant="rectangular"
                        width={120}
                        height={30}
                      />
                    ) : (
                      <Button
                        disabled={isLoadingPageTransition}
                        onClick={() => setFilterHistory("week")}
                        style={{
                          borderRadius: 59,
                          background:
                            state.filterHistory === "week"
                              ? "#4F46E5"
                              : "#FFFFFF",

                          textTransform: "capitalize",
                          width: 120,
                          color:
                            state.filterHistory === "week"
                              ? "#FFFFFF"
                              : "#394154",
                          marginRight: 8,
                        }}
                      >
                        Semana
                      </Button>
                    )}
                    {loading ? (
                      <Skeleton
                        style={{ borderRadius: 59, marginRight: 8 }}
                        variant="rectangular"
                        width={120}
                        height={30}
                      />
                    ) : (
                      <Button
                        disabled={isLoadingPageTransition}
                        onClick={() => setFilterHistory("month")}
                        style={{
                          borderRadius: 59,
                          background:
                            state.filterHistory === "month"
                              ? "#4F46E5"
                              : "#FFFFFF",
                          textTransform: "capitalize",
                          width: 120,
                          color:
                            state.filterHistory === "month"
                              ? "#FFFFFF"
                              : "#394154",
                          marginRight: 8,
                        }}
                      >
                        Mes
                      </Button>
                    )}
                    {loading ? (
                      <Skeleton
                        style={{ borderRadius: 59, marginRight: 8 }}
                        variant="rectangular"
                        width={120}
                        height={30}
                      />
                    ) : (
                      <Button
                        onClick={() => setFilterHistory("year")}
                        disabled={isLoadingPageTransition}
                        style={{
                          background:
                            state.filterHistory === "year"
                              ? "#4F46E5"
                              : "#FFFFFF",

                          borderRadius: 59,
                          textTransform: "capitalize",
                          width: 120,
                          color:
                            state.filterHistory === "year"
                              ? "#FFFFFF"
                              : "#394154",
                          marginRight: 8,
                        }}
                      >
                        Año
                      </Button>
                    )}
                    {loading ? (
                      <Skeleton
                        style={{
                          borderRadius: 59,
                          marginLeft: "auto",
                          marginRight: 8,
                        }}
                        variant="rectangular"
                        width={120}
                        height={30}
                      />
                    ) : (
                      <Button
                        onClick={(event) => {
                          setAnchorElFilter(event.currentTarget);
                        }}
                        style={{
                          borderRadius: 59,
                          background: anchorElFilter ? "#4F46E5" : "#FFFFFF",
                          textTransform: "capitalize",
                          // width: 120,
                          color: anchorElFilter ? "#FFFFFF" : "#394154",
                          marginLeft: "auto",
                          marginRight: 8,
                        }}
                        id={`basic-menu-filter`}
                        aria-controls={
                          anchorElFilter ? `basic-menu-filter` : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={anchorElFilter ? "true" : undefined}
                      >
                        <img
                          style={{ width: 18 }}
                          src={anchorElFilter ? FilterActIcon : FilterAltIcon}
                          alt=""
                        />
                      </Button>
                    )}
                  </Box>
                  <Menu
                    id={`basic-menu-filter`}
                    anchorEl={anchorElFilter}
                    open={anchorElFilter ? true : false}
                    onClose={() => {
                      setAnchorElFilter(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": `basic-menu-filter`,
                    }}
                  >
                    <Box
                      style={{
                        // height: "350px",
                        width: "200px",
                        padding: 12,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "10px",
                      }}
                    >
                      {state.filterHistoryItems.map((filterValue, index) => {
                        return (
                          <>
                            <Box style={{ display: "flex" }} key={index}>
                              {filterValue.title}
                            </Box>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                            >
                              {filterValue.items.map((value) => {
                                const labelId = `checkbox-list-label-${value}`;
                                return (
                                  <ListItem key={value.value} disablePadding>
                                    <ListItemButton
                                      role={undefined}
                                      onClick={() => handleToggle(value.value)}
                                      dense
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          edge="start"
                                          checked={
                                            state.filterChecked.indexOf(
                                              value.value
                                            ) !== -1
                                          }
                                          tabIndex={-1}
                                          disableRipple
                                          disabled={isLoadingPageTransition}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        id={labelId}
                                        primary={`${value.name}`}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </>
                        );
                      })}
                    </Box>
                  </Menu>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 32,
                    }}
                  >
                    <Paper style={{ width: "100%" }}>
                      {loading ? (
                        <Skeleton
                          style={{
                            marginRight: 6,
                            marginBottom: 24,
                            width: "100%",
                            height: `calc(100vh - 455px)`,
                          }}
                          variant="rectangular"
                        />
                      ) : (
                        <TableContainer
                          style={{
                            width: "100%",
                            maxHeight: `calc(100vh - ${
                              width > 320 ? 200 : 180
                            }))`,
                          }}
                        >
                          <Table aria-label="custom pagination table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ color: "#848484" }}
                                  align="center"
                                >
                                  Nombre
                                </TableCell>
                                {width > 320 && (
                                  <TableCell
                                    style={{ color: "#848484" }}
                                    align="center"
                                  >
                                    Correo
                                  </TableCell>
                                )}
                                <TableCell
                                  style={{ color: "#848484" }}
                                  align="center"
                                >
                                  Estatus
                                </TableCell>
                                {/* <TableCell align="center"></TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {state.history.map((row, index) => (
                                <TableRow
                                  onClick={() => {
                                    // console.log("");
                                    getDetails(row);
                                  }}
                                  key={row._id}
                                  className="classRowHistoryVL"
                                >
                                  <TableCell
                                    align="center"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,

                                      paddingLeft: 4,
                                      paddingRight: 4,
                                    }}
                                  >
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="text"
                                        style={{ width: "100%" }}
                                        height={52}
                                      />
                                    ) : row && row.contact ? (
                                      row.contact.firstname ===
                                        "No especificado" &&
                                      row.contact.lastname ===
                                        "No especificado" ? (
                                        "No especificado"
                                      ) : (
                                        service.getName(row.contact)
                                      )
                                    ) : !findValueByLabel(
                                        [
                                          "nombre",
                                          "nombre completo",
                                          "apellido",
                                          "su nombre",
                                          "name",
                                          "first name",
                                          "lastname",
                                          "fullname",
                                          "full name",
                                          "tu nombre",
                                          "tu nombre completo",
                                          "primer nombre",
                                          "tu primer nombre",
                                          "su primer nombre",
                                          "nombre de pila",
                                          "given name",
                                        ],
                                        row?.customFields?.data,
                                        "text"
                                      ) ? (
                                      findValueByLabel(
                                        [
                                          "nombre",
                                          "nombre completo",
                                          "apellido",
                                          "su nombre",
                                          "name",
                                          "first name",
                                          "lastname",
                                          "fullname",
                                          "full name",
                                          "tu nombre",
                                          "tu nombre completo",
                                          "primer nombre",
                                          "tu primer nombre",
                                          "su primer nombre",
                                          "nombre de pila",
                                          "given name",
                                        ],
                                        row?.customFields?.data,
                                        "text"
                                      ) ? (
                                        findValueByLabel(
                                          [
                                            "nombre",
                                            "nombre completo",
                                            "apellido",
                                            "su nombre",
                                            "name",
                                            "first name",
                                            "lastname",
                                            "fullname",
                                            "full name",
                                            "tu nombre",
                                            "tu nombre completo",
                                            "primer nombre",
                                            "tu primer nombre",
                                            "su primer nombre",
                                            "nombre de pila",
                                            "given name",
                                          ],
                                          row?.customFields?.data,
                                          "text"
                                        )
                                      ) : (
                                        "No especificado"
                                      )
                                    ) : (
                                      findValueByLabel(
                                        [
                                          "nombre",
                                          "nombre completo",
                                          "apellido",
                                          "su nombre",
                                          "name",
                                          "first name",
                                          "lastname",
                                          "fullname",
                                          "full name",
                                          "tu nombre",
                                          "tu nombre completo",
                                          "primer nombre",
                                          "tu primer nombre",
                                          "su primer nombre",
                                          "nombre de pila",
                                          "given name",
                                        ],
                                        row?.customFields?.data,
                                        "text"
                                      )
                                    )}
                                  </TableCell>

                                  {width > 320 && (
                                    <TableCell
                                      align="center"
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        maxWidth: 50,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                      }}
                                    >
                                      {isLoadingPageTransition ? (
                                        <Skeleton
                                          variant="text"
                                          style={{
                                            width: "100%",
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                          }}
                                          height={52}
                                        />
                                      ) : row && row.contact ? (
                                        `${row.contact.email}`
                                      ) : (
                                        row.menbers[0]?.user?.email
                                      )}
                                    </TableCell>
                                  )}
                                  <TableCell
                                    align="center"
                                    style={{
                                      paddingTop: 24,
                                      paddingBottom: 24,

                                      paddingLeft: 4,
                                      paddingRight: 4,
                                    }}
                                  >
                                    {isLoadingPageTransition ? (
                                      <Skeleton
                                        variant="text"
                                        style={{ width: "100%" }}
                                        height={52}
                                      />
                                    ) : row.status === "ended" ? (
                                      "Terminado"
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {/* <TableCell align="center">
                                    <IconButton
                                      onClick={(e) => {
                                        setAnchorElDetail(e.currentTarget);
                                        setRowSelected(row);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </TableCell> */}
                                </TableRow>
                              ))}
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorElDetail}
                                open={rowSelected}
                                onClose={() => {
                                  setAnchorElDetail(null);
                                  setRowSelected(undefined);
                                }}
                                MenuListProps={{
                                  "aria-labelledby": "b]asic-button",
                                }}
                                PaperProps={{
                                  style: {
                                    borderRadius: 10,
                                    padding: 8,
                                    minWidth: 150,
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    getDetails(rowSelected);
                                    setAnchorElDetail(null);
                                    setRowSelected(undefined);
                                  }}
                                >
                                  Detalle
                                </MenuItem>
                              </Menu>
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                  rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { label: "Todos", value: -1 },
                                  ]}
                                  colSpan={width < 320 ? 2 : 3}
                                  count={totalChats}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  SelectProps={{
                                    inputProps: {
                                      "aria-label": "items per page",
                                    },
                                  }}
                                  labelRowsPerPage=""
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                  ActionsComponent={TablePaginationActions}
                                  backIconButtonProps={isLoadingPageTransition}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      )}
                    </Paper>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MobileView;
