import { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Switch from "@mui/material/Switch";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getCanalIcon } from "../utils";

const ProfileInfo = ({
  isMobile,
  state,
  handleAvatar,
  hanleDataSound,
  loading,
  currentPreview,
}) => {
  const [avatar, setAvatar] = useState(
    state?.user?.avatar ? state?.user?.avatar : getCanalIcon("web")
  );
  const [soundActive, setSoundActive] = useState(
    state?.user?.isSoundActive ? state?.user?.isSoundActive : false
  );
  const inputRef = useRef(null);

  // function convertToBase64(blob) {
  //   return new Promise((resolve) => {
  //     var reader = new FileReader();
  //     reader.onload = function () {
  //       resolve(reader.result);
  //     };
  //     reader.readAsDataURL(blob);
  //   });
  // }

  async function handleChageBanner(event) {
    const file = event.target.files[0];
    // const resizedString = await convertToBase64(file);
    const uploadAvatar = await handleAvatar(file);
    // if (uploadAvatar) setAvatar(resizedString);
  }

  const VlSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#6848F3",
        "& + .MuiSwitch-track": {
          backgroundColor: "#B0A2ED",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box
      style={{
        // display: loading && "flex",
        width: isMobile ? "100%" : "30%",
        marginTop: !isMobile && 44,
        boxSizing: "border-box",
        padding: !isMobile && 36,
        paddingBottom: 0,
      }}
    >
      <Box
        component="label"
        style={{
          height: isMobile ? "20%" : "35%",
          backgroundColor: "#FFFFFF",
          marginBottom: 18,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: isMobile && 12,
        }}
      >
        <Avatar
          style={{
            position: "relative",
            width: isMobile ? 100 : 150,
            height: isMobile ? 100 : 150,
            // opacity: "0.6",
          }}
          src={currentPreview}
        ></Avatar>
        <Box
          style={{
            position: "absolute",
            cursor: "pointer",
            width: isMobile ? 100 : 150,
            height: isMobile ? 100 : 150,
            textAlign: "center",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            opacity: 0.8,
          }}
        >
          {!isMobile && !loading && (
            <CameraAltIcon
              style={{ width: 42, height: 42, color: "#FFFFFF" }}
            />
          )}
          {!isMobile && !loading && (
            <Box style={{ color: "#FFFFFF" }}>Cambiar foto</Box>
          )}

          {loading && <CircularProgress style={{ color: "#FFFFFF" }} />}

          <input
            type="file"
            ref={inputRef}
            hidden
            onChange={handleChageBanner}
          />
        </Box>
      </Box>
      <Box
        style={{
          height: !isMobile && "45%",
          minHeight: isMobile && 200,
          backgroundColor: "#FFFFFF",
          marginBottom: 18,
          boxSizing: "border-box",
          paddingTop: 16,
          paddingLeft: 26,
          overflowY: "scroll",
        }}
      >
        <Box
          style={{
            marginBottom: 10,
            fontSize: isMobile ? 10 : 16,
            fontWeight: "bold",
          }}
        >
          {state.user.abilities.length > 1
            ? "Tipos de atenciones"
            : "Tipo de atención"}
        </Box>
        {state.abilities
          .filter((item) =>
            state.user.abilities.find((ability) => ability === item._id)
          )
          .map((el) => (
            <Box style={{ fontSize: isMobile ? 10 : 16 }}>{el.name}</Box>
          ))}
        <Box
          style={{
            marginTop: 10,
            fontSize: isMobile ? 10 : 16,
            marginBottom: 10,
            fontWeight: "bold",
          }}
        >
          {state.user.groups.length > 1 ? "Grupos" : "Grupo"}
        </Box>
        <ul>
          {state.groups
            .filter((item) =>
              state.user.groups.find((group) => group === item._id)
            )
            .map((el) => (
              <li style={{ fontSize: isMobile ? 12 : 16 }}>{el.name}</li>
            ))}
        </ul>
      </Box>
      <Box
        style={{
          marginBottom: isMobile && 8,

          boxSizing: "border-box",
          paddingTop: !isMobile ? 16 : 8,
          paddingBottom: !isMobile ? 16 : 8,
          paddingLeft: 26,

          height: "20%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              borderRadius: "100%",
              backgroundColor: "#4A27ED",
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              padding: 4,
            }}
          >
            <NotificationsActiveOutlinedIcon
              style={{ color: "#FFFFFF", fontSize: 20 }}
            />
          </Box>
          <Box style={{ marginLeft: "13px", fontSize: 16 }}>Notificaciones</Box>
          <Box style={{ marginLeft: "auto", marginRight: 16 }}>
            <VlSwitch
              inputProps={{
                style: {
                  color: "red",
                },
              }}
              checked={soundActive}
              onChange={(e) => {
                setSoundActive(!soundActive);
                hanleDataSound({
                  isActive: !soundActive,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileInfo;
