import { useMemo, useState, useEffect, useCallback } from "react";

import io from "socket.io-client";

export const useSocket = (serverPath, user) => {
  const [connect, setConnect] = useState(false);
  const [socket, setSocket] = useState(null);
  const [notified_connect, setNotified_connect] = useState(
    Boolean(localStorage.getItem("notified_connect"))
  );

  const connectSocket = useCallback(() => {
    const currentSocket = io.connect(serverPath, {
      transport: ["websocket"],
      withCredentials: true,
      timeout: 20000,
      query: {
        data: JSON.stringify({
          agent: true,
          token: localStorage.getItem("agent_token"),
        }),
      },
    });
    setSocket(currentSocket);
  }, [serverPath]);

  useEffect(() => {
    socket?.on("connect", () => {
      setConnect(true);
      socket.on("id", (data) => {
        localStorage.setItem("id_videolink", data.id);
      });
      if (!notified_connect) {
        socket.emit("connect_agent");
        setNotified_connect(true);
        localStorage.setItem("notified_connect", true);
      }
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("disconnect", () => {
      setConnect(false);
    });
  }, [socket]);

  return {
    socket,
    connect,
    connectSocket,
    notified_connect,
    setNotified_connect,
  };
};
