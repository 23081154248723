import { types } from "../types";

export const AgentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.sign_in:
      return {
        ...state,
        user: action.payload,
        logged: true,
      };
    case types.logout:
      return {
        ...state,
        user: action.payload,
        logged: false,
      };
    case types.handle_sidebar:
      return {
        ...state,
        isOpen: action.payload,
      };

    case types.handle_dialog_close:
      return {
        ...state,
        isOpenDialog: action.payload,
      };
    case types.get_groups:
      return {
        ...state,
        groups: action.payload,
      };
    case types.get_abilities:
      return {
        ...state,
        abilities: action.payload,
      };
    case types.get_current_chat:
      return {
        ...state,
        currentChat: action.payload,
      };
    case types.set_loading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.set_chat_list:
      return {
        ...state,
        chatList: action.payload,
      };
    case types.get_queue:
      return {
        ...state,
        queue: action.payload,
      };

    case types.set_config_dialog:
      return {
        ...state,
        configDialog: action.payload,
      };
    case types.set_snackbar:
      return {
        ...state,
        snackbar: action.payload,
      };
    case types.set_messages:
      return {
        ...state,
        messages: action.payload,
      };
    case types.get_agents:
      return {
        ...state,
        agents: action.payload,
      };
    case types.set_tab_active:
      return {
        ...state,
        isTabActive: action.payload,
      };
    case types.set_current_files:
      return {
        ...state,
        currentFiles: action.payload,
      };
    case types.set_current_abilities:
      return {
        ...state,
        currentAbilities: action.payload,
      };
    case types.set_preview_images:
      return {
        ...state,
        isPreviewImages: action.payload,
      };
    case types.set_messages_images:
      return {
        ...state,
        messagesWithImages: action.payload,
      };
    case types.set_selected_image:
      return {
        ...state,
        selectedImage: action.payload,
      };
    case types.set_search:
      return {
        ...state,
        search: action.payload,
      };
    case types.set_filter_history:
      return {
        ...state,
        filterHistory: action.payload,
      };
    case types.set_checked_filter:
      return {
        ...state,
        filterChecked: action.payload,
      };
    case types.get_history:
      return {
        ...state,
        history: action.payload,
      };

    case types.handle_dialog_details:
      return {
        ...state,
        openDialogDetails: action.payload,
      };
    case types.handle_details:
      return {
        ...state,
        historyDetails: action.payload,
      };
    case types.handle_dialog_screen:
      return {
        ...state,
        historyDetailsScreen: action.payload,
      };
    default:
      return state;
  }
};
