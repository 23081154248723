import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MenuIcon from "@mui/icons-material/Menu";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import endChatIcon from "../assets/endChat.svg";

import TextLogo from "../assets/TextLogo.png";
import companyLogo from "../assets/svg/omniflou-white.svg";
import { consts } from "../const";
import { getCanalIcon } from "../utils";
import service from "../service";
import { useEffect, useState } from "react";

import "./navbar.scss";

const Navbar = ({
  isMobile,
  page,
  currentChat,
  isLoading,
  handleSidebar,
  setOpenDialog,
  openDialog,
  setSearch,
  search,
  searchByUsername,
  setSearchByUsername,
  setCurrentChat,
  setOpenAction,
  setAnchorElAction,
  openAction,
  setConfigDialog,
  showClientInfo,
  setShowClientInfo,
  isDetails,
  handleDetails,
  navigate,
}) => {
  const regexForName = /^[ña-zA-Z\s]*$/;

  const [showBtnChat, setShowBtnChat] = useState(false);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [clientName, setClientName] = useState("No especificado");

  useEffect(() => {
    if (page === "history") {
      setShowSearchHistory(true);
    } else {
      setShowSearchHistory(false);
    }

    if (page === "page" && currentChat) {
      setShowBtnChat(true);
    } else {
      setShowBtnChat(false);
    }
  }, [page]);

  useEffect(() => {
    if (page === "chat" && currentChat) {
      setShowBtnChat(true);

      if (currentChat.contactV3) {
        const { firstName = "", lastName = "" } = currentChat.contactV3 ?? {};

        if (firstName) {
          setClientName(`${firstName} ${lastName ?? ""}`.trim());
        }
      } else {
        setClientName("No especificado");
      }
    } else {
      setShowBtnChat(false);
    }
  }, [currentChat]);

  return isMobile ? (
    <Box
      style={{
        backgroundColor: "#1B0845",
        height: 77,
        display: "flex",
        alignItems: "center",
        paddingLeft: currentChat ? 18 : 24,
        paddingRight: currentChat ? 6 : 24,
        width: "100%",
      }}
    >
      {!currentChat ? (
        <>
          <Box style={{ paddingBottom: 6 }}>
            {page && page !== "profile" && page !== "login" && !isDetails && (
              <IconButton
                style={{
                  marginRight: page === "history" ? 0 : 17,
                  color: "#FFFFFF",
                }}
                onClick={() => handleSidebar(true)}
              >
                <MenuIcon fontSize="large" style={{ color: "#FFFFFF" }} />
              </IconButton>
            )}
          </Box>
          {page === "history" ? (
            isDetails ? (
              <Box
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Box>
                  <IconButton
                    style={{ color: "#FFFFFF" }}
                    onClick={() =>
                      handleDetails({
                        messages: [],
                        files: [],
                        details: undefined,
                      })
                    }
                  >
                    <ArrowBackIosIcon style={{ color: "#FFFFFF" }} />
                  </IconButton>
                </Box>
                <Box style={{ marginLeft: 14 }}>
                  <Box style={{ fontSize: 14, color: "#FFFFFF" }}>Detalles</Box>
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  marginLeft: 4,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControl fullWidth>
                  <OutlinedInput
                    style={{
                      height: "40px",
                      borderRadius: "30px",
                      // width: 400,
                      backgroundColor: "#F3F6F9",
                    }}
                    value={search}
                    onChange={(event) => {
                      if (!regexForName.test(event.target.value)) {
                        return false;
                      } else {
                        setSearch(event.target.value);
                      }
                    }}
                    placeholder="Buscar..."
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#A3A3A3" }} />
                      </InputAdornment>
                    }
                    onKeyUp={(e) => {
                      if (e.code === consts.ENTER_KEY_CODE) {
                        setSearchByUsername(!searchByUsername);
                      }
                    }}
                  />
                </FormControl>

                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    marginLeft: 8,
                    borderColor: "#FFFFFF",
                    color: "#FFFFFF",
                  }}
                  onClick={() => setSearchByUsername(!searchByUsername)}
                >
                  Buscar
                </Button>
              </Box>
            )
          ) : page === "profile" ? (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                style={{ color: "#FFFFFF" }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosIcon style={{ color: "#FFFFFF" }} />
              </IconButton>
              <Box style={{ fontSize: 20, color: "#FFFFFF" }}>Mi perfil</Box>
            </Box>
          ) : (
            <Box>
              <img
                src={companyLogo}
                style={{
                  width: "100%",
                  maxWidth: "182px",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <Box style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box>
              <IconButton
                style={{ color: "#FFFFFF" }}
                onClick={() =>
                  showClientInfo
                    ? setShowClientInfo(false)
                    : setCurrentChat(undefined)
                }
              >
                <ArrowBackIosIcon style={{ color: "#FFFFFF" }} />
              </IconButton>
            </Box>
            {!showClientInfo && (
              <Box>
                <img
                  src={getCanalIcon(currentChat?.channel)}
                  style={{
                    border: "2px solid #01962D",
                    width: 46,
                    height: 46,
                    borderRadius: "100%",
                    padding: 2,
                    display: "flex",
                  }}
                />
              </Box>
            )}

            <Box style={{ marginLeft: 14 }}>
              <Box style={{ fontSize: 14, color: "#FFFFFF" }}>
                {currentChat && clientName}
              </Box>
              <Box style={{ fontSize: 10, color: "#FFFFFF" }}>En linea</Box>
            </Box>
            <Box style={{ marginLeft: "auto" }}>
              <Box style={{ display: "flex" }}>
                <IconButton
                  style={{ color: "#FFFFFF", marginRight: 8 }}
                  onClick={() => {
                    setOpenAction(false);
                    setAnchorElAction(null);
                    setConfigDialog({ open: true, type: "finish" });
                  }}
                >
                  <img src={endChatIcon} alt="" />
                </IconButton>

                <IconButton
                  style={{ color: "#FFFFFF" }}
                  aria-controls={"demo-customized-menu"}
                  aria-haspopup="true"
                  aria-expanded={"true"}
                  onClick={(event) => {
                    setOpenAction(!openAction);
                    setAnchorElAction(event.currentTarget);
                  }}
                >
                  <MoreVertIcon style={{ color: "#FFFFFF" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  ) : (
    <div className="navbar">
      <div className="navbar__info">
        <img
          src={companyLogo}
          style={{ width: "100%", maxWidth: "182px", objectFit: "contain" }}
          alt="logo"
        />

        {showSearchHistory && (
          <Box
            style={{
              marginLeft: 21,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <OutlinedInput
                style={{
                  height: "40px",
                  borderRadius: "30px",
                  width: 400,
                  backgroundColor: "#F3F6F9",
                }}
                value={search}
                onChange={(event) => {
                  if (!regexForName.test(event.target.value)) {
                    return false;
                  } else {
                    setSearch(event.target.value);
                  }
                }}
                placeholder="Buscar..."
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#A3A3A3" }} />
                  </InputAdornment>
                }
                onKeyUp={(e) => {
                  if (e.code === consts.ENTER_KEY_CODE) {
                    setSearchByUsername(!searchByUsername);
                  }
                }}
              />
            </FormControl>

            <Button
              variant="outlined"
              style={{
                borderRadius: "30px",
                textTransform: "capitalize",
                marginLeft: 8,
                borderColor: "#FFFFFF",
                color: "#FFFFFF",
              }}
              onClick={() => setSearchByUsername(!searchByUsername)}
            >
              Buscar
            </Button>
          </Box>
        )}
      </div>
      <div className="navbar__actions">
        {showBtnChat && (
          <>
            <Button
              variant="outlined"
              style={{
                marginRight: "12px",
                borderRadius: "25.5px",
                fontSize: "12px",
                color: openDialog.type === "transfer" ? "#EA345F" : "#FFFFFF",
                borderColor: "#FFFFFF",
                backgroundColor: openDialog.type === "transfer" && "#FFFFFF",
              }}
              onClick={() => setOpenDialog({ open: true, type: "transfer" })}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <PhoneCallbackIcon fontSize="small" />
              &nbsp; Transferir atención
            </Button>
            <Button
              onClick={() => setOpenDialog({ open: true, type: "finish" })}
              variant="outlined"
              style={{
                marginRight: "12px",
                borderRadius: "25.5px",
                fontSize: "12px",
                color: openDialog.type === "finish" ? "#EA345F" : "#FFFFFF",
                borderColor: "#FFFFFF",
                backgroundColor: openDialog.type === "finish" && "#FFFFFF",
              }}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <CallEndIcon
                fontSize="small"
                style={{
                  color: openDialog.type === "finish" ? "#EA345F" : "#FFFFFF",
                }}
              />
              &nbsp; Finalizar
            </Button>
            <Button
              onClick={() => setOpenDialog({ open: true, type: "report" })}
              variant="outlined"
              style={{
                marginRight: "12px",
                borderRadius: "25.5px",
                fontSize: "12px",
                color: openDialog.type === "report" ? "#EA345F" : "#FFFFFF",
                borderColor: "#FFFFFF",
                backgroundColor: openDialog.type === "report" && "#FFFFFF",
              }}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <WarningAmberIcon
                fontSize="small"
                style={{
                  color: openDialog.type === "report" ? "#EA345F" : "#FFFFFF",
                }}
              />
              {/* <CallEndIcon fontSize="small" /> */}
              &nbsp; Reportar
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;

/**
 * 
 * 

<Box
      style={{
        backgroundColor: "#1B0845",
        height: 60,
        display: "flex",
        alignItems: "center",
        paddingLeft: !page ? 35 : 80,
        paddingRight: 24,
        position: "fixed",
        width: "100%",
        justifyContent: page === "login" ? "" : page === "history" ? "" : "end",
      }}
    >
      <Box>
        {/* {page === "login" && <img src={companyLogo} alt="logo" />} /}
        <img src={companyLogo} alt="logo" />
        {isLoading ? (
          <>
            <Button
              variant="outlined"
              disabled
              style={{
                marginRight: "12px",
                borderRadius: "25.5px",
                width: "150px",
                height: "45px",
                color: openDialog ? "#EA345F" : "#FFFFFF",
                borderColor: "#442F72",
              }}
            ></Button>
            <Button
              variant="outlined"
              disabled
              style={{
                marginRight: "12px",
                borderRadius: "25.5px",
                width: "150px",
                height: "45px",
                color: openDialog ? "#EA345F" : "#FFFFFF",
                borderColor: "#442F72",
              }}
            ></Button>
          </>
        ) : page === "chat" && currentChat ? (
          <Box style={{ marginLeft: "auto", width: "100%" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                style={{
                  marginRight: "12px",
                  borderRadius: "25.5px",
                  fontSize: "12px",
                  color: openDialog.type === "transfer" ? "#EA345F" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  backgroundColor: openDialog.type === "transfer" && "#FFFFFF",
                }}
                onClick={() => setOpenDialog({ open: true, type: "transfer" })}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <PhoneCallbackIcon fontSize="small" />
                &nbsp; Transferir atención
              </Button>
              <Button
                onClick={() => setOpenDialog({ open: true, type: "finish" })}
                variant="outlined"
                style={{
                  marginRight: "12px",
                  borderRadius: "25.5px",
                  fontSize: "12px",
                  color: openDialog.type === "finish" ? "#EA345F" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  backgroundColor: openDialog.type === "finish" && "#FFFFFF",
                }}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <CallEndIcon
                  fontSize="small"
                  style={{
                    color: openDialog.type === "finish" ? "#EA345F" : "#FFFFFF",
                  }}
                />
                &nbsp; Finalizar
              </Button>
              <Button
                onClick={() => setOpenDialog({ open: true, type: "report" })}
                variant="outlined"
                style={{
                  marginRight: "12px",
                  borderRadius: "25.5px",
                  fontSize: "12px",
                  color: openDialog.type === "report" ? "#EA345F" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  backgroundColor: openDialog.type === "report" && "#FFFFFF",
                }}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <WarningAmberIcon
                  fontSize="small"
                  style={{
                    color: openDialog.type === "report" ? "#EA345F" : "#FFFFFF",
                  }}
                />
                {/* <CallEndIcon fontSize="small" /> /}
                &nbsp; Reportar
              </Button>
            </Box>
          </Box>
        ) : (
          page === "history" && (
            <>
              <Box
                style={{
                  marginLeft: 21,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <OutlinedInput
                    style={{
                      height: "40px",
                      borderRadius: "30px",
                      width: 400,
                      backgroundColor: "#F3F6F9",
                    }}
                    value={search}
                    onChange={(event) => {
                      if (!regexForName.test(event.target.value)) {
                        return false;
                      } else {
                        setSearch(event.target.value);
                      }
                    }}
                    placeholder="Buscar..."
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#A3A3A3" }} />
                      </InputAdornment>
                    }
                    onKeyUp={(e) => {
                      if (e.code === consts.ENTER_KEY_CODE) {
                        setSearchByUsername(!searchByUsername);
                      }
                    }}
                  />
                </FormControl>

                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    marginLeft: 8,
                    borderColor: "#FFFFFF",
                    color: "#FFFFFF",
                  }}
                  onClick={() => setSearchByUsername(!searchByUsername)}
                >
                  Buscar
                </Button>
              </Box>
            </>
          )
        )}
      </Box>
    </Box>





 * 
 * 
 * 
 */
